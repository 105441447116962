import React from 'react'
import Navbar2 from '../NavBar/Navbar2';
import PowerBanner from '../../Appcomponents/PowerBanner';
import SocialMedia from '../SocialMedia';
import Footer from '../../Appcomponents/Footer';
import Navbar from '../NavBar/Navbar1';
import "../../../Stylesheets/MainComponent/Official/Pathway.scss";

const Certified = () => {
  return (
    <>
      <div className='container-fluid  PATHWAY-PART1' >
        <Navbar />
        <div className='container-fluid PATHWAY_inner_wrapper ' id="">
          <div className="navbar2-container-PATH" >
            <Navbar2 />

          </div>
          <div className=' TABOFF my-4 text-justify content-PATHWAY1'>
            
            <div className="title shimmer">
              <h1 className='HeadingOFF my-4'> CERTIFIED COACHES</h1>
              </div>
             
              <p className='paragraphOFF'>
                Squash has been played in India since the pre-war days. To a large extent it was introduced by the British forces that built squash courts in military cantonments and private clubs as an outlet for their sporting interests. In the early days squash had the following of a smattering few who were either from the forces, members of private clubs or children returning from studies abroad. It certainly fitted the name it was given at that time – a bourgeois sport. An organised for the promotion of the game was non-existent.
              </p>
            </div>

        
        </div>
        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </>
  )
}

export default Certified
