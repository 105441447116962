import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/NavBar1.scss";
import searchicon from "../../../assets/Images/search-icon.png";
import bat from "../../../assets/Images/bat.png";
// import searchicon from "../../../assets/Images/search-icon.png";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";

// upcoming
import {
  getUpcomingMatchAction,
  getUpcomingMatchSuccessAction,
  getNewsSuccessAction,
} from "../../../Redux/Slice";

// jun-search
import {
  getTopRankSuccessAction,
  getNewsTabSuccessAction,
  postNewsTabsSuccessAction,
  getCalenderFilterSuccessAction,
} from "../../../Redux/Slice";

// affiliation
import {
  getAffiliationAction,
  getAffiliationSuccessAction,
  getAffiliationSearchAction,
  getSquashTimeSuccessAction,
  getSquashTimeSearchAction,
  getLatestNewsSearchAction,
} from "../../../Redux/Slice";

// TEAM INDIA
import {
  getALLPlayersSuccess,
  getAEPlayersSuccess,
  getWEPlayersSuccess,
} from "../../../Redux/Slice";

// import PDF from "../../../assets/National_Doubles_Championships .pdf"

const Navbar = ({ onSearch }) => {
  const dispatch = useDispatch();
  const [searchBar, setSearchBar] = useState("");

  // upcoming search
  const [searchUpcomingData, setSearchUpcomingData] = useState([]);

  const UpcomingData = useSelector(
    (state) => state.UpcomingMatchList.searchUpcomingData
  );
  console.log(UpcomingData, "upco");

  // junior search
  const [searchJuniorData, setSearchJuniorData] = useState([]);

  const juniorRankData = useSelector(
    (state) => state.TopRankList.searchRankTopRankDataj
  );
  console.log(juniorRankData, "1jun");

  // master search
  const [searchMasterData, setSearchMasterData] = useState([]);

  // procoach search
  const [searchProData, setSearchProData] = useState([]);

  // const masterRankData = useSelector(
  //   (state) => state.TopRankList
  // );
  // console.log(masterRankData, "mmaa");

  // latest news
  const [latNews, setLatNews] = useState([]);

  const latestNewsData = useSelector(
    (state) => state.LatestNewsList.searchLatNewsData
  );
  console.log(latestNewsData, "latlat22");

  // affiliation
  const [searchgovt, setSearchgovt] = useState({});

  const govtData = useSelector(
    (state) => state.AffiliationList.searchAffiliationData
  );
  console.log(govtData, "q1q1");

  // news
  const [searchnews, setSearchnews] = useState([]);

  const news = useSelector((state) => state.NewsTabList.searchNewsData);
  console.log(news, "nene");

  // post news
  const [searchpostnews, setSearchpostnews] = useState([]);

  const postnews = useSelector(
    (state) => state.PostNewsTabsList.searchPostNewsTabsData
  );
  console.log(postnews, "postnews");

  // Calender
  const [calender, setCalender] = useState([]);

  const calenderdata = useSelector(
    (state) => state.CalenderFilterList.CalenderFilterSearchData
  );
  console.log(calenderdata, "calcal");

  // squash time
  const [squTime, setSqaTime] = useState([]);

  const SquTimeData = useSelector(
    (state) => state.SquashTimeList.searchSquashTimeData
  );
  // console.log(SquTimeData, "sssttt");

  // Team India
  const [teamindiaALL, setTeamindiaALL] = useState([]);
  const [teamindiaaAE, setTeamindiaAE] = useState([]);
  const [teamindiaWE, setTeamindiaWE] = useState([]);

  const TeamALLData = useSelector((state) => state.TeamIndia.ALLSEARCH);
  console.log(TeamALLData, "ALL T");

  const TeamAEData = useSelector((state) => state.TeamIndia.AESEARCH);
  console.log(TeamAEData, "AE T");

  const TeamWEData = useSelector((state) => state.TeamIndia.WESEARCH);
  console.log(TeamWEData, "WE T");

  // search function
  const handleSearchInput = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchBar(inputValue);

    // upcoming
    if (
      inputValue !== "" &&
      UpcomingData !== undefined &&
      UpcomingData.tourdata
    ) {
      const data0 = {
        node: UpcomingData.tourdata.filter((ele) => {
          return ele.TournamentName.toLowerCase().includes(inputValue);
        }),
      };
      setSearchUpcomingData(data0.node);
      dispatch(getUpcomingMatchSuccessAction({ tourdata: data0.node }));
    } else {
      dispatch(getUpcomingMatchSuccessAction(UpcomingData));
    }

    // master
    if (
      inputValue !== "" &&
      juniorRankData &&
      juniorRankData.master &&
      juniorRankData.fulldata &&
      juniorRankData.pro_rank
    ) {
      const data = {
        node: juniorRankData.master.filter((ele) => {
          return ele.PlayerName.toLowerCase().includes(inputValue);
        }),
      };

      const data1 = {
        node: juniorRankData.fulldata.filter((ele) => {
          return (
            ele.boys.PlayerName.toLowerCase().includes(inputValue) ||
            ele.girls.PlayerName.toLowerCase().includes(inputValue)
          );
        }),
      };

      const data2 = {
        node: juniorRankData.pro_rank.filter((ele) => {
          return ele.PlayerName.toLowerCase().includes(inputValue);
        }),
      };

      console.log("datanodem", data.node);
      setSearchJuniorData(data1.node);
      setSearchMasterData(data.node);
      setSearchProData(data2.node);
      dispatch(
        getTopRankSuccessAction({
          fulldata: data1.node,
          master: data.node,
          pro_rank: data2.node,
        })
      );
    } else {
      dispatch(getTopRankSuccessAction(juniorRankData));
    }

    // latest news
    // if (
    //   inputValue !== "" &&
    //   latestNewsData !== undefined &&
    //   latestNewsData.data
    // ) {
    //   const latnew = {
    //     node: latestNewsData.data.filter((ele) => {
    //       return ele.Title.toLowerCase().includes(inputValue);
    //     }),
    //   };
    //   setLatNews(latnew.node);
    //   // console.log(latnew.node, kmkmkm)
    //   dispatch(getNewsSuccessAction({ data: latnew.node }));
    // } else {
    //   dispatch(getNewsSuccessAction(latestNewsData));
    // }

    // affiliation
    if (
      inputValue !== "" &&
      govtData &&
      govtData.data &&
      govtData.data.length > 0
    ) {
      const dataaff = {
        node: govtData.data[0].Government.filter((ele) => {
          return ele.title.toLowerCase().includes(inputValue);
        }),
      };
      const asfval = {
        node: govtData.data[0].asf.filter((ele) => {
          return ele.title.toLowerCase().includes(inputValue);
        }),
      };
      const wsfval = {
        node: govtData.data[0].wsf.filter((ele) => {
          return ele.title.toLowerCase().includes(inputValue);
        }),
      };
      console.log("datanodeaf", dataaff.node);
      setSearchgovt(dataaff.node);
      dispatch(
        getAffiliationSuccessAction({
          data: [
            { Government: dataaff.node, asf: asfval.node, wsf: wsfval.node },
          ],
        })
      );
    } else {
      dispatch(getAffiliationSuccessAction(govtData));
    }

    // news
    if (inputValue !== "" && news != undefined && news.data) {
      console.log("newnew", news);

      const datanews = {
        node: news.data.filter((ele) => {
          console.log(ele, "22222");
          return ele.Title.toLowerCase().includes(inputValue);
        }),
      };
      console.log("datanodenews", datanews.node);
      setSearchnews(datanews.node);
      dispatch(getNewsTabSuccessAction({ data: datanews.node }));
    } else {
      dispatch(getNewsTabSuccessAction(news));
    }

    // postnews
    if (inputValue !== "" && postnews != undefined && postnews.data) {
      // debugger
      console.log("postnews", postnews);

      const postdatanews = {
        node: postnews.data.filter((ele) => {
          console.log(ele, "22222");
          return ele.Title.toLowerCase().includes(inputValue);
        }),
      };
      console.log("datanodepostnews", postdatanews.node);
      setSearchpostnews(postdatanews.node);
      dispatch(postNewsTabsSuccessAction({ data: postdatanews.node }));
    } else {
      // debugger
      dispatch(postNewsTabsSuccessAction(postnews));
      console.log(postnews, "111222");
    }

    // calender
    if (inputValue !== "") {
      console.log("calenderdata", calenderdata);

      const calendertour = {
        node: calenderdata.filter((ele) => {
          console.log(ele, "33333");
          return ele.TournamentName.toLowerCase().includes(inputValue);
        }),
      };
      console.log("datanodepostnews", calendertour.node);
      setCalender(calendertour.node);
      dispatch(getCalenderFilterSuccessAction(calendertour.node));
    } else {
      dispatch(getCalenderFilterSuccessAction(calenderdata));
    }

    // squashtime
    if (inputValue !== "" && SquTimeData != undefined && SquTimeData.data) {
      console.log("cscs", SquTimeData);
      const sqadata = {
        node: SquTimeData.data.filter((ele) => {
          console.log(ele, "55555");
          return ele.Title.toLowerCase().includes(inputValue);
        }),
      };
      console.log("sqsqsq", sqadata.node);
      setSqaTime(sqadata.node);
      dispatch(getSquashTimeSuccessAction({ data: sqadata.node }));
    } else {
      dispatch(getSquashTimeSuccessAction(SquTimeData));
    }

    // Team India
    if (
      inputValue !== ""
      // && TeamALLData != undefined
      // && TeamALLData.ALL
    ) {
      // debugger;
      console.log("ALLLL", TeamALLData);
      const tindiaall = {
        node: TeamALLData.filter((ele) => {
          console.log(ele, "66666");
          return ele.Name.toLowerCase().includes(inputValue);
        }),
      };
      console.log("tia", tindiaall.node);
      setTeamindiaALL(tindiaall.node);
      dispatch(getALLPlayersSuccess(tindiaall.node));
    } else {
      // debugger;
      dispatch(getALLPlayersSuccess(TeamALLData));
    }

    if (
      inputValue !== ""
      // && TeamAEData != undefined
      // && TeamAEData.AE
    ) {
      // debugger;
      console.log("As ev", TeamAEData);
      const tindiasev = {
        node: TeamAEData.filter((ele) => {
          console.log(ele, "66666");
          return ele.Name.toLowerCase().includes(inputValue);
        }),
      };
      console.log("tie", tindiasev.node);
      setTeamindiaAE(tindiasev.node);
      dispatch(getAEPlayersSuccess(tindiasev.node));
    } else {
      // debugger;
      dispatch(getAEPlayersSuccess(TeamAEData));
    }

    if (
      inputValue !== ""
      // && TeamWEData != undefined
      // && TeamWEData.AE
    ) {
      // debugger;
      console.log("As wv", TeamWEData);
      const tindiaswv = {
        node: TeamWEData.filter((ele) => {
          console.log(ele, "66666");
          return ele.Name.toLowerCase().includes(inputValue);
        }),
      };
      console.log("tiw", tindiaswv.node);
      setTeamindiaWE(tindiaswv.node);
      dispatch(getWEPlayersSuccess(tindiaswv.node));
    } else {
      // debugger;
      dispatch(getWEPlayersSuccess(TeamWEData));
    }
  };

  // squash time
  function SquashTimeNavbar() {
    window.location.href = "/events/squashtime";
  }

  return (
    <div className="navrbar1_main-wrapper">
      <div className="inner-container">
      <a 
      // href={PDF} 
      // target="_blank" 
      className="National_Doubles pl-3">
        {/* HCL National Doubles Championships 2024 */}
        </a>
        <div className="nav-options">

          <div className="search-container">
            {/* <img src={searchicon}></img> */}
            <SearchIcon />
            <input
              className="search-field"
              type="text"
              placeholder="Search"
              name="Search"
              autoComplete="off"
              value={searchBar.searchfield}
              onChange={handleSearchInput}
            />
          </div>

          <div className="time-login-wrap">
            <div className="squash-time-wrap">
              <img src={bat}></img>
              {/* <button className="squash-time">SQUASH TIME</button> */}

              <button
                type="button"
                class="btn squash-time"
                // data-toggle="modal"
                // data-target="#exampleModalCenter"
                onClick={SquashTimeNavbar}
              >
                SQUASH TIME
              </button>
              {/* popup1 */}
              {/* <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">Site under maintenance</div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="official-login-wrap">
              {/* <img src={bat}></img> */}
              {/* <PersonIcon /> */}
              {/* <button className="official-login">OFFICIAL LOGIN </button> */}

              {/* <button
                type="button"
                class="btn official-login"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                OFFICIAL LOGIN
              </button> */}
              {/* <button
                              type="button"
                              class="btn official-login"
                              onClick={(e) => {window.open("https://docs.google.com/forms/d/e/1FAIpQLSeKphvwc1Ku9JDmwIAo-Y1Ye8i-acdit3wV7_iuzwQrwq2Lwg/viewform", "_blank")}}
              
              >
              Registration form for Doubles Championship

              </button> */}
              {/* <a href={PDF} target="_blank" className="National_Doubles">HCL National Doubles Championships 2024</a> */}
              {/* popup1 */}
              <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      {/* <h5 class="modal-title" id="exampleModalLongTitle">
                      Modal title
                    </h5> */}
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">Site under maintenance</div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
