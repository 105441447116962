import React, { useState, useEffect } from "react";
import Navbar1 from "../../MainComponets/NavBar/Navbar1";
import Banner from "../../Appcomponents/Banner";
import MiniBanner from "../MiniBanner";
import UpcomingMatches from "../../MainComponets/UpcomingMatch/UpcomingMatches";
import Ranking from "../../MainComponets/Ranking/JuniorRanking";
import MasterRanking from "../../MainComponets/Ranking/MasterRanking";
import PowerBanner from "../../Appcomponents/PowerBanner";
import LatestResults from "../../MainComponets/LatestResults";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../Footer";
import LatestNews from "../../MainComponets/LatestNews";
import "../../../Stylesheets/AppComponents/HomePage.scss";
import scroll from "../../../assets/Images/scrollup.png";

const Homepage = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="hompage_main_wrapper">
      <div className="homepage_inner_wrap">
        {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
            <img src={scroll} className="scrollup"></img>
          </button>
        )}
        <Navbar1 />

        <Banner />

        <MiniBanner />

        <UpcomingMatches />

        <Ranking />

        <MasterRanking />

        {/* <LatestResults /> */}

        <LatestNews />

        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
