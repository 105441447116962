export const sagaActions = {
  FETCH_BANNER_DATA: "BannerList",
  FETCH_UPCOMING_MATCH_DATA: "UpcomingMatchsList",
  FETCH_ID_DATA: "IdData",
  FETCH_TOP_RANK_DATA: "TopRankList",
  FETCH_LATEST_RESULTS_DATA: "LatestResultsList",
  FETCH_LATEST_NEWS_DATA: "LatestNewsList",
  FETCH_LATEST_VIDEOS_DATA: "LatestVideosList",
  
  // FETCH_MASTERPRO_TOPRANK_DATA: "MasterProTopRankList",
  FETCH_FILTERSTATE_DATA: "FilterStateList",
  FETCH_AFFILIATION_DATA: "AffiliationList",
  FETCH_REGISTER_PLAYER_DATA: "RegisterPlayerList",
  FETCH_EVENTS_DATA: "EventsList",
  FETCH_CALENDER_YEAR_DATA: "CalenderYearList",
  FETCH_CALENDER_FILTER_DATA: "CalenderFilterList",
  FETCH_CALENDER_TOUR_DETAILS_DATA: "CalenderTourDetailsList",
  // FETCH_CAL_TOUR_ENTRIES_DATA: "CalTourEntriesList",
  FETCH_CAL_TOUR_ENTRIES_PAGE_DATA: "CalTourEntriesPAGEList",
  FETCH_NEWS_TAB_DATA: "NewsTabList",
  FETCH_NEWS_TABPUTS_DATA: "NewsTabPutsList",
  FETCH_POST_NEWS_TABS_DATA: "PostNewsTabsList",

  LOADER_SUCCESS:"Loader",

  FETCH_SQUASHTIME: "SquashTimeList",

  //  ------Seacrh------------------

  FETCH_SEARCH_API_RANKING: "SearchDATA",

  FETCH_TEAMINDIA_PLAYERS: "PlayersList",
  FETCH_RANKIGN_TOPERS: "RankingJunior",
  FETCH_RANKIGN_TOPERS_SENIOR: "SeniorRankList",
  FETCH_RANKING_TOPERS_MASTER: "MasterRankList",
  FETCH_RANKING_TOPERS_PRCOACH: "ProCoachlist",

  FETCH_INDIASQUASHTV_VIDEO: "indiaSquahLIST",
  FETCH_ELECTION_PDF: "ElectionPdfList",
  FETCH_AGM_PDF: "AgmPdfList",
  FETCH_ROS_PDF: "RosPdfList",
  FETCH_BALSHEET_PDF: "BalSheetPdfList",

  FETCH_SELECTION_PDFS_PARTS: "SelectionPDF",
  FETCH_SOCIAL_MEDIA_DATA: "SocialMediaList",



};
