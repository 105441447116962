import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar1 from "./Components/MainComponets/NavBar/Navbar1";
import Navbar2 from "./Components/MainComponets/NavBar/Navbar2";
// Home
import Homepage from "./Components/Appcomponents/DashBoard/Homepage";

// Federation
import IndiaSquash from "./Components/MainComponets/Federations/IndiaSquash";
import StateUnits from "./Components/MainComponets/Federations/StateUnits";
import Affiliation from "./Components/MainComponets/Federations/Affiliation";
import Elections from "./Components/MainComponets/Federations/Elections";
import Agm from "./Components/MainComponets/Federations/Agm";
import OfficeBeares from "./Components/MainComponets/Federations/OfficeBeares";
import ContactUs from "./Components/MainComponets/Federations/ContactUs";
import Exemption from "./Components/MainComponets/Federations/Exemption";

// Events
import Calender from "./Components/MainComponets/Events/Calender";
import SrfiGuidelines from "./Components/MainComponets/Events/SrfiGuidelines";
import SquashRule from "./Components/MainComponets/Events/SquashRule";
import SquashTime from "./Components/MainComponets/Events/SquashTime";
import ImportantInfo from "./Components/MainComponets/Events/ImportantInfo";
import News from "./Components/MainComponets/Events/News";

//Players
import PlaySquash from "./Components/MainComponets/Players/PlaySquash";
import ISpin from "./Components/MainComponets/Players/ISpin";
import HCL from "./Components/MainComponets/Players/HCL";
import AgeFraud from "./Components/MainComponets/Players/AgeFraud";
import TeamIndia from "./Components/MainComponets/Players/TeamIndia";
import Ranking from "./Components/MainComponets/Players/Ranking";
import SelectionCriteria from "./Components/MainComponets/Players/SelectionCriteria";

//Offical
import Pathway from "./Components/MainComponets/Official/Pathway";
import RefereePanel from "./Components/MainComponets/Official/RefereePanel";
import Certified from "./Components/MainComponets/Official/Certified";
import Coaching from "./Components/MainComponets/Official/Coaching";
import Referee from "./Components/MainComponets/Official/Referee";

// IndiaSqashTv
import Videos from "./Components/MainComponets/IndiaSquashTv/Videos";
import ConductEvent from "./Components/MainComponets/Events/ConductEvent";
import MajorGames from "./Components/MainComponets/Events/MajorGames";

import { useSelector } from "react-redux/es/hooks/useSelector";
import Loader from "./Components/Loader";
import PriacyPolicy from "./Components/Appcomponents/PriacyPolicy";
import BalanceSheet from "./Components/MainComponets/Federations/BalanceSheet";
import RegistereSociety from "./Components/MainComponets/Federations/RegistereSociety";

import { useDispatch } from "react-redux";
import {
  getFederationActins,
  getNavbarsActions,
  getCalenderTourDetailsEmptyAction,
  tournamentid,
  getNewsTabEmptyAction,
  newsid,
  latestnewsid,

  pagelength
} from "./Redux/Slice";

function App() {
  const dispatch = useDispatch();

  const [Loader1, setLoader] = useState(false);
  const LoaderData = useSelector((state) => state.Loader);

  useEffect(() => {
    console.log(LoaderData.isLoading);
    setLoader(LoaderData.isLoading);
  }, [LoaderData.isLoading]);

  // navbar active reset
  useEffect(() => {
    if (!window.location.href.includes("/events/calender")) {
      dispatch(tournamentid(""));
      dispatch(getCalenderTourDetailsEmptyAction());
    }
  }, []);

  // navbar active reset
  useEffect(() => {
    if (!window.location.href.includes("/events/news")) {
      dispatch(getNewsTabEmptyAction());
      dispatch(newsid());
      dispatch(latestnewsid(""));
    }  
  }, [])

  // pagination reset
  useEffect(() => {
    if (window.location.href.includes("/events/calender")) {
      dispatch(pagelength([1,2,3,4,5]));
    }
  }, []);

  // news refresh
  useEffect(() => {
if (window.location.href.includes("/events/news")) {
  dispatch(latestnewsid(""));
}
  },[])


  // console.log(process.env.NODE_ENV,"lskdlksdlkas")
  if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
  }

  return (
    <div className="App">
      {Loader1 && <Loader></Loader>}
      {/* <Navbar1 /> */}
      {/* <Navbar2 /> */}

      {/* <Router> */}
      <Routes>
        <Route path="/" element={<Homepage />}></Route>

        <Route path="/federation/indiasquash" element={<IndiaSquash />}></Route>
        <Route path="/federation/affiliation" element={<Affiliation />}></Route>
        <Route path="/federation/stateunits" element={<StateUnits />}></Route>
        <Route path="/federation/elections" element={<Elections />}></Route>
        <Route path="/federation/agm" element={<Agm />}></Route>
        <Route
          path="/federation/officebearers"
          element={<OfficeBeares />}
        ></Route>
        <Route path="/federation/contactus" element={<ContactUs />}></Route>
        <Route path="/federation/exemption" element={<Exemption />}></Route>
        <Route
          path="/federation/registeresociety"
          element={<RegistereSociety />}
        ></Route>
        <Route
          path="/federation/balancesheet"
          element={<BalanceSheet />}
        ></Route>

        <Route path="/events/calender" element={<Calender />}></Route>
        <Route
          path="/events/srfiguidelines"
          element={<SrfiGuidelines />}
        ></Route>
        <Route path="/events/squashrule" element={<SquashRule />}></Route>
        <Route path="/events/news" element={<News />}></Route>
        <Route path="/events/squashtime" element={<SquashTime />}></Route>
        <Route path="/events/importantinfo" element={<ImportantInfo />}></Route>
        <Route path="/events/conductevent" element={<ConductEvent />}></Route>
        <Route path="/events/majorgames" element={<MajorGames />}></Route>

        <Route path="/players/playsquash" element={<PlaySquash />}></Route>
        <Route path="/players/ispin" element={<ISpin />}></Route>
        <Route path="/players/hcl" element={<HCL />}></Route>
        <Route path="/players/agefraud" element={<AgeFraud />}></Route>
        <Route path="/players/teamindia" element={<TeamIndia />}></Route>
        <Route path="/players/ranking" element={<Ranking />}></Route>
        <Route
          path="/players/selectioncriteria"
          element={<SelectionCriteria />}
        ></Route>

        <Route path="/official/pathway" element={<Pathway />}></Route>
        <Route path="/official/refereepanel" element={<RefereePanel />}></Route>
        <Route
          path="/official/certifiedcoaches"
          element={<Certified />}
        ></Route>
        <Route path="/official/refereeworkshop" element={<Referee />}></Route>
        <Route path="/official/coachingcourse" element={<Coaching />}></Route>

        <Route path="/indiasqushatv/videos" element={<Videos />}></Route>

        <Route path="/privacypolicy" element={<PriacyPolicy />}></Route>
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
