import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/UpcomingMatches.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getUpcomingMatchAction,
  getUpcomingMatchSuccessAction,
  getUpcomingMatchErrorAction,
  tournamentid,
} from "../../../Redux/Slice";
import btnborder from "../../../assets/Images/btn-design.png";

import calender from "../../../assets/Images/calender-icon.png";
import location from "../../../assets/Images/location-icon.png";
import person from "../../../assets/Images/person-icon.png";

import { useNavigate } from "react-router-dom";
import srfinew from "../../../assets/Images/srfinew.jpg";

const UpcomingMatches = () => {
  const navigate = useNavigate();

  const [allData, setAllData] = useState([]);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    // console.log({ dynamicWidth: window.innerWidth }, "lllllllllll");
    // console.log({ dynamicHeight: window.innerHeight }, "heightheight");

    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const dispatch = useDispatch();

  const projectlistfulldata = useSelector((state) => state.UpcomingMatchList);
  console.log(projectlistfulldata, "qwe");

  useEffect(() => {
    dispatch(getUpcomingMatchAction());
  }, []);

  useEffect(() => {
    // setAllData(projectlistfulldata.UpcomingMatchData.tourdata);
    if (
      projectlistfulldata &&
      projectlistfulldata.UpcomingMatchData &&
      projectlistfulldata.UpcomingMatchData.tourdata
    ) {
      const firstFourItems =
        projectlistfulldata.UpcomingMatchData.tourdata.slice(0, 4);
      setAllData(firstFourItems);
    }
    console.log(projectlistfulldata.UpcomingMatchData.tourdata, "cococo");
  }, [projectlistfulldata]);

  const getPlayerCategoryLabel = (categories) => {
    const categoryLabels = {
      BU: "Junior",
      GU: "Junior",
      MEN: "Senior",
      WOMEN: "Senior",
      MO: "Master",
      PROCOACH: "Procoach",
    };

    const usedLabels = new Set();
    const mappedCategories = categories.map((category) => {
      const prefix = category.match(/[A-Z]+/);
      const label = categoryLabels[prefix] || "";

      if (!usedLabels.has(label)) {
        usedLabels.add(label);
        return label;
      } else {
        return "";
      }
    });

    return mappedCategories.filter((label) => label !== "").join(", ");
  };

  // date format
  // Helper function to format the date as "ddmmyyyy"
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  var s3url = "https://demowhats.s3.ap-south-1.amazonaws.com/";
  var s3url2 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  const [latestMatcClick, setLatestMatcClick] = useState(null);

  const handleEventClick = (id) => {
    const selectedMatch = allData.find((match) => match.TournamentID === id);

    setLatestMatcClick(selectedMatch);
    dispatch(tournamentid(id));

    console.log(selectedMatch, "upid", id);
    navigate(`/events/calender`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="upcoming-match-mainwrap">
      <div className="upcoming-inner">
        <div className="latest-result-head">
          <button className="coming-match-heading">UPCOMING MATCHES</button>
          <img src={btnborder} className="btn-border"></img>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 card-outer">
              {allData &&
                allData.map((tournament, index) => (
                  <div
                    className="card"
                    key={tournament.TournamentID}
                    onClick={() => handleEventClick(tournament.TournamentID)}
                  >
                    <div className="card-body text-center">
                      <div className="img-cont">
                        <div className="logo">
                          <img
                            className="avatar"
                            src={s3url2 + tournament.Logo}
                            alt={tournament.TournamentName}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = srfinew;
                            }}
                          />
                        </div>
                      </div>
                      <div className="startlogo-div">
                        <img
                          className="starlogo"
                          src={s3url + tournament.StarLogo}
                          alt="star-logo"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = srfinew;
                          }}
                        />
                      </div>

                      <div className="tournament-details">
                        <div className="tournament-title">
                          <h6 className="card-title">
                            {tournament.TournamentName}
                          </h6>
                        </div>

                        <div className="tournament-date">
                          <img src={calender} className="calender-img"></img>
                          <span className="card-subtitle1 mb-2 text-muted">
                            {/* {tournament.TournamentStartDate} */}
                            {formatDate(tournament.TournamentStartDate)}
                          </span>
                        </div>

                        <div className="tournament-venue">
                          <img src={location} className="location-img"></img>
                          <span className="card-subtitle2 mb-2 text-muted">
                            {tournament.Venue}
                          </span>
                        </div>

                        <div className="tournament-category">
                          <img src={person} className="category-img"></img>
                          <span className="card-subtitle2 mb-2 text-muted">
                            {getPlayerCategoryLabel(tournament.PlayersCategory)}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="register-wrap">
                      {/* {tournament.EntryEndDate ? (
                        <>
                          Register by
                          <span className="register-date">
                            {formatDate(tournament.EntryEndDate)}
                          </span>
                        </>
                      ) : // Render nothing (or you can provide a message or alternative content)
                      null} */}

                      {tournament.EntryEndDate &&
                      new Date(tournament.EntryEndDate) > new Date() ? (
                        <>
                          Register by
                          <span className="register-date">
                            {formatDate(tournament.EntryEndDate)}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingMatches;
