import React, { useEffect, useState } from "react";
import "../../../Stylesheets/MainComponent/IndiaSquashTv/Videos.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import {
  getSquashAction,
  getSquashSuccessAction,
  getSquashErrorAction,
} from "../../../Redux/Slice";
import scroll from "../../../assets/Images/scrollup.png";

import { useDispatch, useSelector } from "react-redux";
const Videos = () => {

  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  
 
  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch = useDispatch();

  const [statr, setstatr] = useState([]);

  const video = useSelector((state) => state.indiaSquahLIST.SquashVideo)

 
  useEffect(() => {
   dispatch(getSquashAction());
   setstatr(video.indiaSquahLIST);
  }, []);
  

  function getVideoIdFromURL(url) {
    // Extract the video ID from the URL using a regular expression
    const match = url.match(/v=([a-zA-Z0-9_-]+)/);
    if (match && match[1]) {
      return match[1];
    } else {
      // Handle the case where the URL is invalid or doesn't contain a video ID
      return ""; // You can return a default value or handle the error as needed
    }
  }

  return (
    <div className="videos-main-wrapper">
      {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
            <img src={scroll} className="scrollup"></img>
          </button>
        )}
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers my-4 p-4 videos-inner">
          <div class="title shimmer">
            <h4>
              <b>VIDEOS</b>
            </h4>
          </div>
          <div className="videopart">
            {video != undefined && video.map((post, index) => {
              const videoId = getVideoIdFromURL(post.VideURL);
              if (videoId) {
                return (
                  <div className="carousel-itemss" key={index}>
                    <iframe
                      title={`YouTube Video ${index + 1}`}
                      width="100%"
                      height="100%"
                      className="iframeVD"
                      src={`https://www.youtube.com/embed/${videoId}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                );
              } else {
                // Handle invalid URLs gracefully
                console.error('Invalid YouTube URL:', post.VideURL);
                return null;
              }
            })}

          </div>

        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default Videos;
