import React, { useState, useEffect } from "react";
import "../../Stylesheets/MainComponent/LatestNews.scss";
import { useSelector, useDispatch } from "react-redux";
import btnborder from "../../assets/Images/btn-design.png";
import {
  getNewsAction,
  getNewsSuccessAction,
  getNewsErrorAction,
  latestnewsid,
} from "../../Redux/Slice";

import {
  getVideosAction,
  getVideosSuccessAction,
  getVideosErrorAction,
} from "../../Redux/Slice";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

import srfinew from "../../assets/Images/srfinew.jpg";

const LatestNews = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [latestVideos, setLatestVideos] = useState([]);
  const [latestNews, setLatestNews] = useState([]);

  const [latestNewsClick, setLatestNewsClick] = useState(null);

  const handleCardClick = (id) => {
    // Find the selected latest news item by Id
    const selectedNews = latestNews.find((news) => news.Id === id);

    // Update the state variable with the selected news item
    setLatestNewsClick(selectedNews);
    dispatch(latestnewsid(id));

    console.log(selectedNews, "neid", id);
    navigate(`/events/news`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // latest news
  const latestnewsfulldata = useSelector((state) => state.LatestNewsList);
  console.log(latestnewsfulldata, "news:");

  useEffect(() => {
    dispatch(getNewsAction());
  }, []);

  useEffect(() => {
    setLatestNews(latestnewsfulldata.LatestNewsData.data);
    console.log(latestnewsfulldata.LatestNewsData.data, "latest news:");
  }, [latestnewsfulldata]);

  
  // latest videos
  const latestvideosfulldata = useSelector((state) => state.LatestVideosList);
  console.log(latestvideosfulldata, "vivi");

  useEffect(() => {
    dispatch(getVideosAction());
  }, []);

  useEffect(() => {
    setLatestVideos(latestvideosfulldata.LatestVideosData.data);
    console.log(latestvideosfulldata.LatestVideosData.data, "latest videos:");
  }, [latestvideosfulldata]);

  // useEffect(() => {
  //   if (latestvideosfulldata && latestvideosfulldata.data) {
  //     const sortedVideos = [...latestvideosfulldata.data].sort((a, b) => b.Sno - a.Sno);
  //     const last3Videos = sortedVideos.slice(0, 3); // Select the last 3 videos
  
  //     setLatestVideos(last3Videos);
  //     console.log(last3Videos, "latest videos:");
  //   }
  // }, [latestvideosfulldata]);



  const responsiveSettings = [
    {
      breakpoint: 992, // Tablets and smaller
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576, // Mobile devices
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: responsiveSettings,
  };

  function getVideoIdFromURL(url) {
    // Extract the video ID from the URL using a regular expression
    const match = url.match(/v=([a-zA-Z0-9_-]+)/);
    if (match && match[1]) {
      return match[1];
    } else {
      // Handle the case where the URL is invalid or doesn't contain a video ID
      return ""; // You can return a default value or handle the error as needed
    }
  }

  return (
    <div className="latestnews-mainwrapper">
      <div className="container-inner-wrap">
        <div className="latest-result-head">
          <button className="ranking-btn">LATEST NEWS</button>
          <img src={btnborder} className="btn-border"></img>
        </div>
        <div className="news-data-wrapper">
          <Slider {...settings}>
            {latestNews &&
              latestNews.map((post) => (
                <div className="carousel-item" key={post.Id}>
                  <a
                    rel="noopener noreferrer"
                    onClick={() => handleCardClick(post.Id)} // Pass the Id to the handler
                  >
                    <img
                      className="news-img"
                      src={
                        "https://api.indiasquash.com/media/" + post.ThumbImagePath
                      }
                      alt="bg-news"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = srfinew;
                      }}
                    />
                  </a>
                  <div className="news-title">{post.Title}</div>
                  <div className="news-date">{post.UploadDate}</div>
                </div>
              ))}
          </Slider>
        </div>

        <div className="news-data-wrapper">
          <div className="latest-result-head">
            <button className="ranking-btn">LATEST VIDEO</button>
            <img src={btnborder} className="btn-border"></img>
          </div>

          {/* <Slider {...settings}>
  {latesVideo &&
    latesVideo.map((post, index) => (
      <div className="carousel-item" key={index}>
        <iframe
          width="460"
          height="315"
          src={`https://www.youtube.com/embed/${getVideoIdFromURL(post.VideURL)}`}
          frameborder="0"
          allowfullscreen
        ></iframe>
        <div className="news-title">{post.Title}</div>
        <div className="news-date">{post.UploadDate}</div>
      </div>
    ))}
</Slider> */}
          {/* <div className="carousel-item-video">
            <div className="video-container">
              {latesVideo &&
                latesVideo.map((post, index) => (
                  <div className="carousel-item" key={index}>
                    <iframe
                      width="460"
                      height="315"
                      src={`https://www.youtube.com/embed/${getVideoIdFromURL(
                        post.VideURL
                      )}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                    <div className="news-title">{post.Title}</div>
                    <div className="news-date">{post.UploadDate}</div>
                  </div>
                ))}
            </div>
          </div> */}

          {/* video carousals */}
          <div className="carousel-item-video">
            <div className="video-container">
              <Slider {...settings}>
                {latestVideos &&
                  latestVideos.slice(0, 3).map((post, index) => {
                    const videoId = getVideoIdFromURL(post.VideURL);
                    if (videoId) {
                      return (
                        <div className="carousel-itemss" key={index}>
                          <iframe
                            title={`YouTube Video ${index + 1}`}
                            width="85%"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                          <div className="news-title">{post.Title}</div>
                          <div className="news-date">{post.UploadDate}</div>
                        </div>
                      );
                    } else {
                      // Handle invalid URLs gracefully
                      console.error("Invalid YouTube URL:", post.VideURL);
                      return null;
                    }
                  })}
 
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
