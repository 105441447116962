// import { useReducer } from "react";
import { combineReducers } from "@reduxjs/toolkit";
import usersReducer from "./Slice";
// import  from './ProjectSlice';
import LoaderReducer from "./LoaderSlice";
import AlertReducer from "./AlertSlice";
import { useReducer } from "react";
import { LatestResults } from "../text/apidata";

const rootReducer = combineReducers({
  Loader: LoaderReducer,
  Alert: AlertReducer,
  BannerList: usersReducer.BannerReducer,
  UpcomingMatchList: usersReducer.UpcomingMatchReducer,
  IdData: usersReducer.IdReducer,
  TopRankList: usersReducer.TopRankReducer,
  LatestResultsList: usersReducer.LatestResult,
  LatestNewsList: usersReducer.LatestNews,
  LatestVideosList: usersReducer.LatestVideos,

  // MasterProTopRankList: usersReducer.MasterProTopRankReducer,
  FilterStateList: usersReducer.FilterStateReducer,
  AffiliationList: usersReducer.AffiliationReducer,
  RegisterPlayerList: usersReducer.RegisterPlayerReducer,
  EventsList: usersReducer.EventsSliceReducer,
  CalenderYearList: usersReducer.CalenderYearSliceReducer,
  CalenderFilterList: usersReducer.CalenderFilterSliceReducer,
  CalenderTourDetailsList: usersReducer.CalenderTourDetailsSliceReducer,
  // CalTourEntriesList: usersReducer.CalTourEntriesSliceReducer,
  CalTourEntriesPAGEList: usersReducer.CalTourEntriesPAGESliceReducer,

  NewsTabList: usersReducer.NewsTabSliceReducer,
  NewsTabPutsList: usersReducer.NewsTabPutsSliceReducer,
  PostNewsTabsList: usersReducer.PostNewsTabsSliceReducer,

  SquashTimeList: usersReducer.SquashTimeSliceReducer,

  TeamIndia: usersReducer.TeamIndiaReducer,
  Rankinglist: usersReducer.RankingReducer,
  SeniorRankList: usersReducer.RankingSeniorReducer,
  MasterRankList: usersReducer.RankingMasterReducer,
  ProcoachList: usersReducer.RankingProCoach,

  indiaSquahLIST: usersReducer.indiaSquashVD,

  ElectionPdfList: usersReducer.ElectionsPdf,
  AgmPdfList: usersReducer.AgmsPdf,
  RosPdfList: usersReducer.RossPdf,
  BalSheetPdfList: usersReducer.BalSheetsPdf,

  SearchDATA: usersReducer.Search_DATA,
  SelectionDATAs: usersReducer.SelecctionPDFS,

  Test_NAVS: usersReducer.NAVBAR_STATES,
  Inner_Federations: usersReducer.Inner_SubTitle,

  SocialMediaList: usersReducer.SocialMediaReducer,
});

export default rootReducer;
