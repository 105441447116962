import React from 'react';
import '../Stylesheets/Loader.scss';
import Load1 from "../assets/Gif/load1.gif";

const Loader = () => {
  return (
    <div className='loader_wrapper'>
        <div className='loader_inner_wrapper'>
        <img src={Load1}></img>
        </div>
    </div>
  )
}

export default Loader;