import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/Federations/Agm.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import {
  getAgmPdfAction,
} from "../../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";


const Agm = () => {
  const dispatch = useDispatch();

  const [agmData, setAgmData] = useState([]);

  const agmpdffulldata = useSelector((state) => state.AgmPdfList);

  // console.log(agmpdffulldata, "agmpdf")

  useEffect(() => {
    dispatch(getAgmPdfAction());
   }, []);

   useEffect(() => {
    if (agmpdffulldata) {
      setAgmData(agmpdffulldata.AgmPdf); // Check if agmpdffulldata is defined and access 'AgmPdf'
      console.log(agmpdffulldata, "aaaggg");
    }
  }, [agmpdffulldata]);



  const openPdf = (url) => {
    // console.log("url:", url); // Check the value of url
    window.open(url);
  };

  // function one() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Srfi%20AGM/SRFI%20AGM%202019/SRFI%20AGM%202019%20Minutes.pdf"
  //   );
  // }
  // function two() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Srfi%20AGM/SRFI%20AGM%202020/SRFI%20AGM%202020%20Minutes.pdf"
  //   );
  // }
  // function three() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Srfi%20AGM/SRFI%20AGM%202021/SRFI%20AGM%202021%20Minutes.pdf"
  //   );
  // }
  // function four() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Srfi%20AGM/SRFI%20AGM%202022/SRFI%20AGM%202022%20Minutes.pdf"
  //   );
  // }

  return (
    <div className="agm-main-wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers agm-inner">
          <div class="title shimmer">
            <h4>
              <b>AGM</b>
            </h4>
          </div>

          <div className="agm-btns-wrapper">
            <div className="accordion" id="accordionExample">
              {agmData &&agmData != undefined && agmData.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <button
                        className="inner-btn-amg"
                        onClick={() =>
                          openPdf(
                            "https://demowhats.s3.ap-south-1.amazonaws.com/" +
                              item.filefield
                          )
                        } // Use item.filefield
                      >
                        {item.title}.pdf
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default Agm;
