import React from "react";
import "../../../Stylesheets/MainComponent/Federations/Exemption.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

const Exemption = () => {
  return (
    <div className="exemption-main-wrapper">
             <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div class="containers navmargintop">
          <div class="title shimmer">
            <h4>
              <b>80G EXEMPTION</b>
            </h4>
          </div>

          <div className="main-contents">
            <b>Coming Soon...</b>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default Exemption;
