import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/MasterRanking.scss";
import { useSelector, useDispatch } from "react-redux";
import rightarrow from "../../../assets/Images/rightarrow.png";

// import {
//   getJuniorRankAction,
//   getJuniorRankSuccessAction,
//   getJuniorRankErrorAction,
// } from "../../../Redux/Slice";

// import {
//   getGU19RankAction,
//   getGU19RankSuccessAction,
//   getGU19RankErrorAction,
// } from "../../../Redux/Slice";

import {
  getMasterProTopRankAction,
  getMasterProTopRankSuccessAction,
  getMasterProTopRankErrorAction,
} from "../../../Redux/Slice";
import srfinew from "../../../assets/Images/srfinew.jpg";

import ProCoach from "./ProCoach";

const MasterRanking = () => {
  const dispatch = useDispatch();

  // MASTER PRO COACH
  const [masterProCoach, setMasterProCoach] = useState([]);
  const [masterProCoach2, setMasterProCoach2] = useState([]);

  // const masterprocoachfulldata = useSelector(
  //   (state) => state.MasterProTopRankList
  // );
  // console.log(masterprocoachfulldata.MasterRankTopRankData.master, "mas pro");

  // // MASTER & PRO COACH
  // useEffect(() => {
  //   dispatch(getMasterProTopRankAction());
  // }, []);

  const toprankfulldata = useSelector((state) => state.TopRankList);
  console.log(toprankfulldata, "top rank MAS PRO");

  useEffect(() => {
    setMasterProCoach(toprankfulldata.TopRankData.master);
    console.log(toprankfulldata.TopRankData.master, "MASTER RANK");
  }, [toprankfulldata]);

  useEffect(() => {
    setMasterProCoach2(toprankfulldata.TopRankData.pro_rank);
    console.log(toprankfulldata.TopRankData.pro_rank, "PRO RANK");
  }, [toprankfulldata]);

  function ViewAllRank() {
    window.location.href = "/players/ranking";
  }

  var s3url3 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  return (
    <div>
      <div className="ranking-main-container-master">
        <div className="ranking-inner-wrapper-master">
          <button className="ranking-btn">MASTER & PROCOACH</button>
          <div className="container-fluid cont-wrap">
            <div className="row">
              <div className="col-md-6 master">
                <div className="master-container">
                  {masterProCoach &&
                    masterProCoach.slice(0, 5).map((maspro, index) => (
                      <div class="card-cont" key={index}>
                        <div className="profile-details">
                          <img
                            src={s3url3 + maspro.PlayerImage}
                            class="card-img-top"
                            alt="profile"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =srfinew
                            }}
                          />

                          <div className="name-venue">
                            <span className="player-name">
                              {maspro.PlayerName}
                            </span>
                            <span className="player-location">
                              {maspro.State}
                            </span>
                          </div>
                        </div>

                        <div className="stats-details">
                          <div className="cat-poit">
                            <span className="category">{maspro.Category}</span>
                            <span className="points">
                              {maspro.Points}
                              &nbsp;<span>PTS</span>
                            </span>
                          </div>

                          <button className="rank-cont">
                            <span className="rank">
                              1<sup>st</sup>
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-md-6 master2">
                <div className="master-container">
                  {masterProCoach &&
                    masterProCoach.slice(5).map((maspro, index) => (
                      <div class="card-cont" key={index}>
                        <div className="profile-details">
                          <img
                            src={s3url3 + maspro.PlayerImage}
                            class="card-img-top"
                            alt="profile"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =srfinew
                            }}
                          />

                          <div className="name-venue">
                            <span className="player-name">
                              {" "}
                              {maspro.PlayerName}
                            </span>
                            <span className="player-location">
                              {" "}
                              {maspro.State}
                            </span>
                          </div>
                        </div>

                        <div className="stats-details">
                          <div className="cat-poit">
                            <span className="category">{maspro.Category}</span>
                            <span className="points">
                              {maspro.Points}
                              &nbsp;<span>PTS</span>
                            </span>
                          </div>

                          <button className="rank-cont">
                            <span className="rank">
                              1<sup>st</sup>
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}

                  {masterProCoach2 &&
                    masterProCoach2.map((pro, index) => (
                      <div class="card-cont">
                        <div className="profile-details">
                          <img
                            src={s3url3 + pro.PlayerImage}
                            className="card-img-top"
                            alt="profile"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =srfinew
                            }}
                          />
                          <div className="name-venue">
                            <span className="player-name">
                              {pro.PlayerName}
                            </span>
                            <span className="player-location">{pro.State}</span>
                          </div>
                        </div>
                        <div className="stats-details">
                          <div className="cat-poit">
                            <span className="category">{pro.Category}</span>
                            <span className="points">
                              {pro.Points}
                              &nbsp;<span>PTS</span>
                            </span>
                          </div>
                          <button className="rank-cont">
                            <span className="rank">
                              1<sup>st</sup>
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 box-two">
          <div className="viewall-ranking-inner">
            <button className="viewall-btn" onClick={ViewAllRank}>
              <span className="rank-text">
                VIEW ALL RANKING
              </span>
              <button className="rank-cont">
                <img src={rightarrow} className="arrow-img"></img>
              </button>
            </button>
          </div>
        </div>

        {/* <ProCoach /> */}
      </div>
    </div>
  );
};

export default MasterRanking;
