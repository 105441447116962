import React from 'react'
import Navbar2 from '../NavBar/Navbar2';
import PowerBanner from '../../Appcomponents/PowerBanner';
import SocialMedia from '../SocialMedia';
import Footer from '../../Appcomponents/Footer';
import Navbar from '../NavBar/Navbar1';
import "../../../Stylesheets/MainComponent/Official/Pathway.scss";
const Referee = () => {
    return (
        <>
            <div className='container-fluid PATHWAY-PART1' >
                <Navbar />
                <div className='container-fluid  PATHWAY_inner_wrapper' >
                    <div className="  navbar2-container-PATH" >
                        <Navbar2 />

                    </div>

                    <div className=' TABOFF my-4  tabparts text-justify  content-PATHWAY1'>
                        
                        
                                
                                    <div className="title shimmer">
                                        <h4 className="my-4 HeadingOFF">
                                            <b>REFEREE WORKSHOP</b>
                                        </h4>
                                        <h2 className='text-center'>Coming Soon...</h2>
                                    </div>

                          
                        
                    </div>

                </div>
                <PowerBanner />

                <SocialMedia />

                <Footer />
            </div>
        </>
    )
}

export default Referee
