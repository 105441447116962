import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/JuniorRanking.scss";
import { useSelector, useDispatch } from "react-redux";
// import {
//   getJuniorRankAction,
//   getJuniorRankSuccessAction,
//   getJuniorRankErrorAction,
// } from "../../../Redux/Slice";
// import {
//   getGU19RankAction,
//   getGU19RankSuccessAction,
//   getGU19RankErrorAction,
// } from "../../../Redux/Slice";

import SeniorRanking from "./SeniorRanking";

import {
  getTopRankAction,
  getTopRankSuccessAction,
  getTopRankErrorAction,
} from "../../../Redux/Slice";

import srfinew from "../../../assets/Images/srfinew.jpg";

import btnborder from "../../../assets/Images/btn-design.png";

const Ranking = () => {
  // const [JuniorRankData, setJuniorRankData] = useState([]);
  // const [gu19Ranking, setGu19Ranking] = useState([]);

  const dispatch = useDispatch();

  const [boysFirstRank, setBoysFirstRank] = useState([]);
  const [girlsFirstRank, setGirlsFirstRank] = useState([]);

  // const juniorrankfulldata = useSelector((state) => state.JuniorRankList);
  // // console.log(juniorrankfulldata.JuniorRankData.output, "junior")

  // const gu19rankfulldata = useSelector((state) => state.GU19RankList);
  // console.log(gu19rankfulldata.GU19RankData, "gu19");

  //   BU19
  // useEffect(() => {
  //   dispatch(getJuniorRankAction());
  // }, []);

  // useEffect(() => {
  //   if (juniorrankfulldata && juniorrankfulldata.JuniorRankData.output) {
  //     const firstFiveData = juniorrankfulldata.JuniorRankData.output.slice(
  //       0,
  //       5
  //     );
  //     setJuniorRankData(firstFiveData);
  //     console.log(firstFiveData, "jura");
  //   }
  // }, [juniorrankfulldata]);

  //   GU19
  // useEffect(() => {
  //   dispatch(getGU19RankAction());
  // }, []);

  // useEffect(() => {
  //   console.log(gu19rankfulldata.GU19RankData, "GIRL 19");
  //   if (gu19rankfulldata && gu19rankfulldata.GU19RankData.output) {
  //     const firstFiveData = gu19rankfulldata.GU19RankData.output.slice(0, 5);
  //     setGu19Ranking(firstFiveData);
  //     console.log(firstFiveData, "GIRL 19");
  //   }
  // }, [gu19rankfulldata]);

  // junior 1st rank
  const toprankfulldata = useSelector((state) => state.TopRankList);
  console.log(toprankfulldata, "junior 1st");

  useEffect(() => {
    dispatch(getTopRankAction());
  }, []);

  useEffect(() => {
    if (toprankfulldata && toprankfulldata.TopRankData.fulldata) {
      const boysData = toprankfulldata.TopRankData.fulldata;
      setBoysFirstRank(boysData);
      console.log(boysData, "1st boys");

      const girlsData = toprankfulldata.TopRankData.fulldata;
      setGirlsFirstRank(girlsData);
      console.log(girlsData, "1st girls");
    }
  }, [toprankfulldata]);

  var s3url3 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  return (
    <div className="ranking-main-container">
      <div className="latest-result-head">
        <button className="ranking-btn">RANKING</button>
        <img src={btnborder} className="btn-border"></img>
      </div>

      <div className="ranking-inner-wrapper">
        <button className="junior-btn">JUNIOR</button>
        <div className="container-fluid cont-wrap">
          <div className="row">
            <div className="col-md-6 boys">
              <div className="btn-wrap">
                <button className="boys-btn">BOYS</button>
              </div>
              <div className="boys-container">
                {boysFirstRank &&
                  boysFirstRank.map((jrrank, index) => {
                    const boysData = jrrank.boys; // Get the boys data if available
                    const category = boysData ? boysData.Category : null; // Access the boys category
                    // console.log(category, "ju 1st");

                    // Check if the category starts with "BU" before rendering
                    if (category && category.startsWith("BU")) {
                      return (
                        <div class="card-cont" key={index}>
                          <div className="profile-details">
                            <img
                              src={s3url3 + boysData.PlayerImage}
                              class="card-img-top"
                              alt="profile"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =srfinew
                              }}
                            />

                            <div className="name-venue">
                              <span className="player-name">
                                {boysData.PlayerName}
                              </span>
                              <span className="player-location">
                                {boysData.State}
                              </span>
                            </div>
                          </div>

                          <div className="stats-details">
                            <div className="cat-poit">
                              <span className="category">
                                {boysData.Category}
                              </span>
                              <span className="points">
                                {boysData.Points}
                                &nbsp;<span>PTS</span>
                              </span>
                            </div>

                            <button className="rank-cont">
                              <span className="rank">
                                1<sup>st</sup>
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    }

                    return null; // Skip rendering for other categories
                  })}
              </div>
            </div>

            <div className="col-md-6 girls">
              <div className="btn-wrap">
                <button className="girls-btn">GIRLS</button>
              </div>
              <div className="girls-container">
                {girlsFirstRank &&
                  girlsFirstRank.map((jrrank, index) => {
                    const girlsData = jrrank.girls; // Get the girls data if available
                    const category = girlsData ? girlsData.Category : null; // Access the girls category
                    console.log(category, "ju 1st");

                    // Check if the category starts with "GU" before rendering
                    if (category && category.startsWith("GU")) {
                      return (
                        <div class="card-cont" key={index}>
                          <div className="profile-details">
                            <img
                              src={s3url3 + girlsData.PlayerImage}
                              class="card-img-top"
                              alt="profile"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =srfinew
                              }}
                            />

                            <div className="name-venue">
                              <span className="player-name">
                                {girlsData.PlayerName}
                              </span>
                              <span className="player-location">
                                {girlsData.State}
                              </span>
                            </div>
                          </div>

                          <div className="stats-details">
                            <div className="cat-poit">
                              <span className="category">
                                {girlsData.Category}
                              </span>
                              <span className="points">
                                {girlsData.Points}
                                &nbsp;<span>PTS</span>
                              </span>
                            </div>

                            <button className="rank-cont">
                              <span className="rank">
                                1<sup>st</sup>
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    }

                    return null; // Skip rendering for other categories
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SeniorRanking />
    </div>
  );
};

export default Ranking;
