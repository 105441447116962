import React from 'react'
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import "../../../Stylesheets/MainComponent/Players/HCL.scss";
const HCL = () => {
  return (
    <>
      <div className="container-fluid  HCL-PART1" >
        <Navbar />
        <div className="container-fluid  HCL_inner_wrapper">
          <div className=" navbar2-container-HCL" >
            <Navbar2 />
          </div>
          <div className=' text-justify content-HCL '>
            
             
                <h3 className="HeadingTAB my-4">
                  SQUASH PODIUM PROGRAM
                </h3>
              

                {/* <div className='para-part'> */}
                  <p className='paragraphHCL'>Squash has been played in India since the pre-war days. To a large extent it was introduced by the British forces that built squash courts in military cantonments and private clubs as an outlet for their sporting interests. In the early days squash had the following of a smattering few who were either from the forces, members of private clubs or children returning from studies abroad. It certainly fitted the name it was given at that time – a bourgeois sport. An organised activity for the promotion of the game was non-existent. Over time, however, things changed. The Squash Rackets Federation of India (SRFI) was formed to oversee the promotion and development of the sport in India. Needless to mention results did not rush in with a gush as a lack of systematic structure was posing unending problems. Non-existence of public facilities, lack of qualified manpower and public awareness of the game was pathetic. As time went on we saw minor improvement in the situation. 5 years ago the SRFI forged ahead with some dynamic plans. Squash promotion, development and performance at all levels in India have escalated more in the last 10 years than in the last 50.</p>
                  <br />
                  <p className='paragraphHCL'>The SRFI headquarters is currently in Chennai. It has more than 20 State Associations and affiliated units. All these units are actively involved in the promotion and development of the game. As is usually the case with many sports, the metros have dominated in terms of activities and creditable performances. Mumbai, Delhi, Kolkata and Chennai are the current front-runners. Nevertheless it is the aspiration of the association that every one gets a fair share of the pie.</p>
                  <br />
                  <p className='paragraphHCL'>Over the last few years the Squash Rackets Federation of India has formalised a long term development plan to transform India into one of the leading countries on the world circuit. A lot of effort has gone into nurturing and developing our juniors and we are delighted with our performances over the past decade. The last few years have been the coming of age of Indian squash, with our players winning laurels at the international arena.</p>
                </div>
              {/* </div> */}
            
          
        </div>
        <PowerBanner />

        <SocialMedia />

        <Footer />

      </div>
    </>
  )
}

export default HCL
