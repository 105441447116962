import React, { useState, useEffect } from "react";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

import { useSelector, useDispatch } from "react-redux";
import "../../../Stylesheets/MainComponent/Events/News.scss";
import scroll from "../../../assets/Images/scrollup.png";
import srfinew from "../../../assets/Images/srfinew.jpg";

import {
  getNewsTabAction,
  getNewsTabSuccessAction,
  getNewsTabErrorAction,
  newsid,
  latestnewsid,
  getNewsTabEmptyAction,
  
} from "../../../Redux/Slice";

// import {
//   getNewsTabPutsAction,
//   getNewsTabPutsSuccessAction,
//   getNewsTabPutsErrorAction,
// } from "../../../Redux/Slice";

// post
import {
  postNewsTabsAction,
  postNewsTabsSuccessAction,
  postNewsTabsErrorAction,
  type,
} from "../../../Redux/Slice";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const News = () => {
  

  const dispatch = useDispatch();
  // let typeValue;

  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [activeTab, setActiveTab] = useState("tab1");

  const [showDetail, setShowDetail] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null); // Store the selected card data

  // const [allNewsPuts0, setAllNewsPuts0] = useState([]);

  // all
  const [allNews0, setAllNews0] = useState([]);
  const [selectedNews0, setSelectedNews0] = useState([]);

  // srfi image
  const [srfiImage, setSrfiImage] = useState([]);
  const [selectedNews1, setSelectedNews1] = useState([]);
  // world news
  const [worldNews, setWorldNews] = useState([]);
  const [selectedNews2, setSelectedNews2] = useState([]);
  // result
  const [results, setResults] = useState([]);
  const [selectedNews3, setSelectedNews3] = useState([]);

  const handleCardClick = (cardData, news, index) => {
    if (activeTab === "tab1") {
      const selectedNews0 = allNews0[index];
      // console.log(selectedNews0, "zero");
      setSelectedNews0(selectedNews0);
    }
    if (activeTab === "tab2") {
      const selectedNews1 = srfiImage[index];
      // console.log(selectedNews1, "one");
      setSelectedNews1(selectedNews1);
    }
    if (activeTab === "tab3") {
      const selectedNews2 = worldNews[index];
      // console.log(selectedNews2, "two");
      setSelectedNews2(selectedNews2);
    }
    if (activeTab === "tab4") {
      const selectedNews3 = results[index];
      // console.log(selectedNews3, "three");
      setSelectedNews3(selectedNews3);
    }
    // console.log(news, "qaz");

    dispatch(newsid(news));
    setSelectedCard(cardData);

    // dispatch(getNewsTabAction(news));
    
    // dispatch(getNewsTabPutsAction(news));
    // dispatch(postNewsTabsAction(news));

    setShowDetail(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const newscardid = useSelector((state) => state.IdData.newsid);
  // console.log("n1", newscardid);

  // const allNews0 = useSelector((state) => state.AllNews.allNews0);
  const latestnewsid1 = useSelector((state) => state.IdData.latestnewsid);
  // console.log("news id", latestnewsid1);

  // news
  const allnewsfulldata = useSelector((state) => state.NewsTabList);
  // console.log(allnewsfulldata, "alne");

  useEffect(() => {
    dispatch(getNewsTabAction(newscardid));
  }, []);

  // useEffect(() => {
  //   if (allnewsfulldata.NewsTabData.data != undefined && allnewsfulldata.NewsTabData.data){
  //     setAllNews0(allnewsfulldata.NewsTabData.data);
  //   }
  //   // console.log(allnewsfulldata.NewsTabData.data, "all news");

  //   allnewsfulldata.NewsTabData.data != undefined &&
  //     allnewsfulldata.NewsTabData.data.map((ele, index) => {
  //       if (latestnewsid1 != "" && ele.Id == latestnewsid1) {
  //         // console.log(ele.Id, latestnewsid1);
  //         // console.log(ele, index, "llll");
  //         setShowDetail(true);
  //         setSelectedNews0(ele);
  //       }
  //     });
  // }, [allnewsfulldata]);

  useEffect(() => {
    if (allnewsfulldata.NewsTabData && allnewsfulldata.NewsTabData.data) {
      setAllNews0(allnewsfulldata.NewsTabData.data);
  
      allnewsfulldata.NewsTabData.data.map((ele, index) => {
        if (latestnewsid1 !== "" && ele.Id === latestnewsid1) {
          setShowDetail(true);
          setSelectedNews0(ele);
        }
      });
    }
  }, [allnewsfulldata]);
  


  // news puts
  // const allnewsputsfulldata = useSelector((state) => state.NewsTabPutsList);
  // console.log(allnewsputsfulldata, "alnepu");

  // useEffect(() => {
  //   dispatch(getNewsTabPutsAction(newscardid));
  // }, []);

  // useEffect(() => {
  //   if (Array.isArray(allnewsputsfulldata.NewsTabPutsData.data)) {
  //     setAllNewsPuts0(allnewsputsfulldata.NewsTabPutsData.data);
  //   }
  //   // console.log(allnewsputsfulldata.NewsTabPutsData.data, "all news puts");
  // }, [allnewsputsfulldata]);

  // POST API
  const handleTabClick = (tabname, title, description, thumbImagePath) => {
    setActiveTab(tabname);
    // debugger
    // console.log(tabname, "12345");

    // Clear selected news when changing tabs
    setSelectedNews0(null);
    setSelectedNews1(null);
    setSelectedNews2(null);
    setSelectedNews3(null);

    dispatch(latestnewsid(""));

    // Determine the type based on the clicked tab
    let typeValue = 0; // Initialize typeValue

    if (tabname === "tab2") {
      typeValue = 1;
      // setSrfiImage([]);
      const formData = new FormData();
      const types = 1;

      formData.append("title", title);
      formData.append("description", description);
      formData.append("image", thumbImagePath);
      // Append the type value to the formData object
      formData.append("type", typeValue);

      // Dispatch the postNewsTabsAction with the correct type parameter and formData
      dispatch(postNewsTabsAction({ type: typeValue, formData, types }));
    } else if (tabname === "tab3") {
      typeValue = 2;
      // setSrfiImage([]);
      const formData = new FormData();
      const types = 2;

      formData.append("title", title);
      formData.append("description", description);
      formData.append("image", thumbImagePath);

      // Append the type value to the formData object
      formData.append("type", typeValue); // Append the type as a single value

      // Dispatch the postNewsTabsAction with the correct type parameter and formData
      dispatch(postNewsTabsAction({ type: typeValue, formData, types }));
    } else if (tabname === "tab4") {
      typeValue = 3;
      // setSrfiImage([]);
      const formData = new FormData();
      const types = 3;

      formData.append("title", title);
      formData.append("description", description);
      formData.append("image", thumbImagePath);

      // Append the type value to the formData object
      formData.append("type", typeValue); // Append the type as a single value

      // Dispatch the postNewsTabsAction with the correct type parameter and formData
      dispatch(postNewsTabsAction({ type: typeValue, formData, types }));
    } else {
      // Set a default value or handle other tabs as needed
      dispatch(getNewsTabAction(newscardid));
    }
    // Dispatch the type action with the determined type value
    dispatch(type(typeValue));
    // Create your formData object with actual form data fields
  };

  const postnewstabsfulldata = useSelector((state) => state.PostNewsTabsList);
  // console.log("pone", postnewstabsfulldata.PostNewsTabsData.data);


  useEffect(() => {
    if (
      postnewstabsfulldata.PostNewsTabsData &&
      postnewstabsfulldata.PostNewsTabsData.data != undefined
    ) {
      setSrfiImage(postnewstabsfulldata.PostNewsTabsData.data);
      // console.log(postnewstabsfulldata.PostNewsTabsData.data, "12qw");
    }
  }, [postnewstabsfulldata]);

  useEffect(() => {
    if (
      postnewstabsfulldata.PostNewsTabsData &&
      postnewstabsfulldata.PostNewsTabsData.data != undefined
    ) {
      setWorldNews(postnewstabsfulldata.PostNewsTabsData.data);
      // console.log(postnewstabsfulldata.PostNewsTabsData.data, "12qw");
    }
  }, [postnewstabsfulldata]);

  useEffect(() => {
    if (
      postnewstabsfulldata.PostNewsTabsData &&
      postnewstabsfulldata.PostNewsTabsData.data != undefined
    ) {
      setResults(postnewstabsfulldata.PostNewsTabsData.data);
      // console.log(postnewstabsfulldata.PostNewsTabsData.data, "re ne");
    }
  }, [postnewstabsfulldata]);

  const handleBackClick = () => {
    setShowDetail(false);
    dispatch(getNewsTabEmptyAction());
    dispatch(newsid());
    dispatch(latestnewsid(""));
  };

  var s3url2 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  return (
    <div className="news-main-wrapper">
      {isVisible && (
        <button id="scrollToTopButton" onClick={scrollToTop}>
          <img src={scroll} className="scrollup"></img>
        </button>
      )}
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers news-inner">
          <div class="title shimmer">
            <h4>
              <b>NEWS</b>
            </h4>
          </div>

          <div className="news-documnets-container">
            <div className="inner-tabs">
              <div className="subnavbar">
                <div
                  className={`tab ${
                    activeTab === "tab1" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={() => handleTabClick("tab1")}
                  >
                    All
                  </button>
                </div>
                <div
                  className={`tab ${
                    activeTab === "tab2" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={() => handleTabClick("tab2")}
                  >
                    Srfi Image
                  </button>
                </div>
                <div
                  className={`tab ${
                    activeTab === "tab3" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={() => handleTabClick("tab3")}
                  >
                    World News
                  </button>
                </div>
                <div
                  className={`tab ${
                    activeTab === "tab4" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab4" ? "active" : ""}
                    onClick={() => handleTabClick("tab4")}
                  >
                    Result
                  </button>
                </div>
              </div>

              <div className="tab-content">
                {/* tab one */}
                {activeTab === "tab1" && (
                  <div class="rowww govt-wrap">
                    <div className="news-cards">
                      <div className="container-fluid">
                        {showDetail && selectedNews0 ? (
                          <div className="newsputs-immer-wrapper">
                            <button
                              onClick={handleBackClick}
                              className="back-btn"
                            >
                              <ArrowBackIosIcon />
                              Back
                            </button>
                            {/* {allNewsPuts0 &&
                              allNewsPuts0.map((items, index) => (
                                <div key={index} className="newsputs-main">
                                  <div className="newsputs-inner">
                                    <div className="container-fluid">
                                      <div className="row custom-row">
                                        <div className="col-md-6 contents1">
                                          <p className="news-title">
                                            {items.Title}
                                          </p>

                                          <img
                                            src={s3url2 + items.ThumbImagePath}
                                            alt=""
                                            className="news-img"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src =
                                                "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                            }}
                                          ></img>
                                        </div>

                                        <div className="col-md-12 contents">
                                          <div className="news-desc">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: `${items.Description}`,
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))} */}
                            <div className="newsputs-main">
                              <div className="newsputs-inner">
                                <div className="container-fluid">
                                  <div className="row custom-row">
                                    <div className="col-md-6 contents1">
                                      {/* <p className="news-title">
                                          {selectedNews0.Title}
                                        </p> */}
                                      <img
                                        src={
                                          s3url2 + selectedNews0.ThumbImagePath
                                        }
                                        alt=""
                                        className="news-img"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =srfinew
                                        }}
                                      ></img>
                                    </div>

                                    <div className="col-md-12 contents">
                                      <p className="news-title">
                                        {selectedNews0.Title}
                                      </p>
                                      <span
                                        className="news-desc"
                                        dangerouslySetInnerHTML={{
                                          __html: `${selectedNews0.Description}`,
                                        }}
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="row">
                              {allNews0 &&
                                allNews0.map((items, index) => {
                                  return (
                                    <>
                                      <div
                                        className="col-sm-6 col-md-6 col-lg-4 card-outer"
                                        key={index}
                                      >
                                        <div
                                          className="card nnnn"
                                          // onClick={(e) => {
                                          //   handleCardClick(e, items.Id);
                                          // }}
                                          onClick={(e) => {
                                            handleCardClick(
                                              items,
                                              items.news,
                                              index
                                            );
                                          }}
                                        >
                                          <div className="card-body">
                                            <img
                                              src={
                                                s3url2 + items.ThumbImagePath
                                              }
                                              alt="pdf-front"
                                              className="pdf-image"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =srfinew
                                              }}
                                            />

                                            <div class="card-body">
                                              <h6 class="card-text">
                                                {items.Title}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* tab two */}
                {activeTab === "tab2" && (
                  <div class="rowww govt-wrap">
                    <div class="rowww govt-wrap">
                      <div className="news-cards">
                        <div className="container-fluid">
                          {showDetail && selectedNews1 ? (
                            <div className="newsputs-immer-wrapper">
                              <button
                                onClick={handleBackClick}
                                className="back-btn"
                              >
                                <ArrowBackIosIcon />
                                Back
                              </button>
                              {/* {allNewsPuts0 &&
                                allNewsPuts0.map((items, index) => (
                                  <div key={index} className="newsputs-main">

                                    <div className="newsputs-inner">
                                      <div className="container-fluid">
                                        <div className="row custom-row">
                                          <div className="col-md-6 contents1">
                                            <p className="news-title">
                                              {items.Title}
                                            </p>

                                            <img
                                              src={
                                                s3url2 + items.ThumbImagePath
                                              }
                                              alt=""
                                              className="news-img"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                              }}
                                            ></img>
                                          </div>

                                          <div className="col-md-12 contents">
                                            <span
                                              className="news-desc"
                                              dangerouslySetInnerHTML={{
                                                __html: `${items.Description}`,
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))} */}
                              <div className="newsputs-main">
                                <div className="newsputs-inner">
                                  <div className="container-fluid">
                                    <div className="row custom-row">
                                      <div className="col-md-6 contents1">
                                        {/* <p className="news-title">
                                          {selectedNews1.Title}
                                        </p> */}
                                        <img
                                          src={
                                            s3url2 +
                                            selectedNews1.ThumbImagePath
                                          }
                                          alt=""
                                          className="news-img"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =srfinew
                                          }}
                                        ></img>
                                      </div>

                                      <div className="col-md-12 contents">
                                        <p className="news-title">
                                          {selectedNews1.Title}
                                        </p>
                                        <span
                                          className="news-desc"
                                          dangerouslySetInnerHTML={{
                                            __html: `${selectedNews1.Description}`,
                                          }}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="row">
                                {srfiImage &&
                                  srfiImage.map((items, index) => {
                                    return (
                                      <>
                                        <div
                                          className="col-md-4 card-outer"
                                          key={index}
                                        >
                                          <div
                                            className="card"
                                            onClick={(e) => {
                                              handleCardClick(
                                                items,
                                                items.news,
                                                index
                                              );
                                            }}
                                          >
                                            <div className="card-body">
                                              <img
                                                src={
                                                  s3url2 + items.ThumbImagePath
                                                }
                                                alt="pdf-front"
                                                className="pdf-image"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                                }}
                                              />

                                              <div class="card-body">
                                                <h6 class="card-text">
                                                  {items.Title}
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* tab three */}
                {activeTab === "tab3" && (
                  <div class="rowww govt-wrap">
                    <div class="rowww govt-wrap">
                      <div className="news-cards">
                        <div className="container-fluid">
                          {showDetail && selectedNews2 ? (
                            <div className="newsputs-immer-wrapper">
                              <button
                                onClick={handleBackClick}
                                className="back-btn"
                              >
                                <ArrowBackIosIcon />
                                Back
                              </button>
                              {/* {allNewsPuts0 &&
                                allNewsPuts0.map((items, index) => (
                                  <div key={index} className="newsputs-main">

                                    <div className="newsputs-inner">
                                      <div className="container-fluid">
                                        <div className="row custom-row">
                                          <div className="col-md-6 contents1">
                                            <p className="news-title">
                                              {items.Title}
                                            </p>

                                            <img
                                              src={
                                                s3url2 + items.ThumbImagePath
                                              }
                                              alt=""
                                              className="news-img"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                              }}
                                            ></img>
                                          </div>

                                          <div className="col-md-12 contents">
                                            <span
                                              className="news-desc"
                                              dangerouslySetInnerHTML={{
                                                __html: `${items.Description}`,
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))} */}
                              <div className="newsputs-main">
                                <div className="newsputs-inner">
                                  <div className="container-fluid">
                                    <div className="row custom-row">
                                      <div className="col-md-6 contents1">
                                        <p className="news-title">
                                          {selectedNews2.Title}
                                        </p>

                                        <img
                                          src={
                                            s3url2 +
                                            selectedNews2.ThumbImagePath
                                          }
                                          alt=""
                                          className="news-img"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =srfinew
                                          }}
                                        ></img>
                                      </div>

                                      <div className="col-md-12 contents">
                                        <span
                                          className="news-desc"
                                          dangerouslySetInnerHTML={{
                                            __html: `${selectedNews2.Description}`,
                                          }}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="row">
                                {worldNews &&
                                  worldNews.map((items, index) => {
                                    return (
                                      <>
                                        <div
                                          className="col-md-4 card-outer"
                                          key={index}
                                        >
                                          <div
                                            className="card"
                                            onClick={(e) => {
                                              handleCardClick(
                                                items,
                                                items.news,
                                                index
                                              );
                                            }}
                                          >
                                            <div className="card-body">
                                              <img
                                                src={
                                                  s3url2 + items.ThumbImagePath
                                                }
                                                alt="pdf-front"
                                                className="pdf-image"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                                }}
                                              />

                                              <div class="card-body">
                                                <h6 class="card-text">
                                                  {items.Title}
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* tab four */}
                {activeTab === "tab4" && (
                  <div class="rowww govt-wrap">
                    <div class="rowww govt-wrap">
                      <div className="news-cards">
                        <div className="container-fluid">
                          {showDetail && selectedNews3 ? (
                            <div className="newsputs-immer-wrapper">
                              <button
                                onClick={handleBackClick}
                                className="back-btn"
                              >
                                <ArrowBackIosIcon />
                                Back
                              </button>
                              {/* {allNewsPuts0 &&
                                allNewsPuts0.map((items, index) => (
                                  <div key={index} className="newsputs-main">

                                    <div className="newsputs-inner">
                                      <div className="container-fluid">
                                        <div className="row custom-row">
                                          <div className="col-md-6 contents1">
                                            <p className="news-title">
                                              {items.Title}
                                            </p>

                                            <img
                                              src={
                                                s3url2 + items.ThumbImagePath
                                              }
                                              alt=""
                                              className="news-img"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                              }}
                                            ></img>
                                          </div>

                                          <div className="col-md-12 contents">
                                            <span
                                              className="news-desc"
                                              dangerouslySetInnerHTML={{
                                                __html: `${items.Description}`,
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))} */}
                              <div className="newsputs-main">
                                <div className="newsputs-inner">
                                  <div className="container-fluid">
                                    <div className="row custom-row">
                                      <div className="col-md-6 contents1">
                                        <p className="news-title">
                                          {selectedNews3.Title}
                                        </p>

                                        <img
                                          src={
                                            s3url2 +
                                            selectedNews3.ThumbImagePath
                                          }
                                          alt=""
                                          className="news-img"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =srfinew
                                          }}
                                        ></img>
                                      </div>

                                      <div className="col-md-12 contents">
                                        <span
                                          className="news-desc"
                                          dangerouslySetInnerHTML={{
                                            __html: `${selectedNews3.Description}`,
                                          }}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="row">
                                {results &&
                                  results.map((items, index) => {
                                    return (
                                      <>
                                        <div
                                          className="col-md-4 card-outer"
                                          key={index}
                                        >
                                          <div
                                            className="card"
                                            onClick={(e) => {
                                              handleCardClick(
                                                items,
                                                items.news,
                                                index
                                              );
                                            }}
                                          >
                                            <div className="card-body">
                                              <img
                                                src={
                                                  s3url2 + items.ThumbImagePath
                                                }
                                                alt="pdf-front"
                                                className="pdf-image"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                                }}
                                              />

                                              <div class="card-body">
                                                <h6 class="card-text">
                                                  {items.Title}
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default News;
