import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { usePagination } from "react-table";
import { useGlobalFilter } from "react-table";
import $, { data } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import "../../../Stylesheets/MainComponent/Players/Table.scss";
import {
  getRankingJuniorAction,
  getSeniorRankAction,
  getmasterRankAction,
  getProcoachRankAction,
  getSearchAction,
  setPagin,
  setPaginPRO,
  setTotalPageMASTER,
  setSENIORPAGE,
} from "../../../Redux/Slice";
import SearchIcon from "@mui/icons-material/Search";

const Table = (props) => {
  const {
    columns,
    data,
    pagesize,
    pageindex,
    selects,
    Years,
    Months,
    setTYpe,
    Budata,
    SeniorMW,
    setData,
    type,
    Masters,
  } = props;
  console.log(pageindex, "lll");
  const [currentpage, setCurrentpages] = useState(1);
  const [visiblepages, setVisiblePages] = useState([1, 2, 3, 4, 5]);
  const [topage, setoPages] = useState(pageindex);

  const [search, setSearch] = useState();
  const [isActive, setisActive] = useState(false);
  const [typeSEARCH, setTYpes] = useState("search");

  // ------------------Search------------------

  const handleSearch = (e) => {
    const searchFiled = e.target.value;
    setSearch(e.target.value);
    setoPages(1);

    setTimeout(() => {
      // debugger;
      handlePageChange1(1);
    }, 1000);
    console.log(type);

    // console.log(search, "seardch");
    // setGlobalFilter(search);

    ///JUNIOR..........................
    if (type === "JUNIOR") {
      if (e.target.value != "" && e.target.value != undefined) {
        console.log(e.target.value, topage, "jjj");
        // debugger;
        dispatch(
          getRankingJuniorAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            pagings: 1,
            type: typeSEARCH,
            search: e.target.value.trim(),
          })
        );
      } else {
        // debugger;
        dispatch(
          getRankingJuniorAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            pagings: 1,
            // pagings: topage,
            type: "JUNIOR",
          })
        );
      }
    }

    ///SENIOR....................
    if (type === "SENIOR") {
      if (e.target.value != "" && e.target.value != undefined) {
        console.log(e.target.value, topage, "sss");
        // debugger;
        dispatch(
          getSeniorRankAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            // pagings: topage,
            pagings: 1,
            type: typeSEARCH,
            search: e.target.value.trim(),
          })
        );
      } else {
        // debugger;
        dispatch(
          getSeniorRankAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            // pagings: topage,
            pagings: 1,
            type: "SENIOR",
          })
        );
      }
    }

    ///MASTER.........................
    if (type === "MASTER") {
      if (e.target.value != "" && e.target.value != undefined) {
        console.log(e.target.value, topage, "mmm");
        // debugger;
        dispatch(
          getmasterRankAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            // pagings: topage,
            pagings: 1,
            type: typeSEARCH,
            search: e.target.value.trim(),
          })
        );
      } else {
        // debugger;
        dispatch(
          getmasterRankAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            // pagings: topage,
            pagings: 1,
            type: "MASTER",
          })
        );
      }
    }

    ///PROCOACH.................
    if (type === "PROCOACH") {
      if (e.target.value != "" && e.target.value != undefined) {
        console.log(e.target.value, topage, "ppp");
        // debugger;
        dispatch(
          getProcoachRankAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            // pagings: topage,
            pagings: 1,
            type: typeSEARCH,
            search: e.target.value.trim(),
          })
        );
      } else {
        dispatch(
          getProcoachRankAction({
            Years: Years,
            Months: Months,
            Budata: Budata,
            // pagings: topage,
            pagings: 1,
            type: "PROCOACH",
          })
        );
      }
    }
  };

  // ---------------------Dispatchs-----------------
  const dispatch = useDispatch();

  // --------------Paginations-----------------
  // --------------Previous--------------------
  const handlePrevious = (e) => {
    const currentpage = visiblepages[0];
    if (currentpage === 1) {
      setCurrentpages(1);
      console.log(currentpage, newPageNumber, "sss");
    }
    const newPageNumber = currentpage - 1;
    // if(newPageNumber <1){
    //   newPageNumber = 1;
    // }
    // if(newPageNumber >0 ){
    // setVisiblePages([...visiblepages.slice(1,8)]);

    handlePageChange(newPageNumber);
    // console.log(newPageNumber, "newww");
    // }
  };

  // --------------Next--------------------
  const handleNextPage = () => {
    const currentpage = visiblepages[0];
    const newPageNumber = currentpage + 1;
    handlePageChange(newPageNumber);
    // console.log(newPageNumber, "newww");
  };

  const handlePageChange1 = (pageNumber) => {
    // alert("222");
    setCurrentpages(pageNumber);

    const nowvisiable = [];
    for (let i = pageNumber; i <= pageNumber + 4; i++) {
      if (i <= pagesize) {
        nowvisiable.push(i);
      }
    }
    console.log(nowvisiable, "huih", pagesize);
    setVisiblePages(nowvisiable);

    setoPages(pageNumber);
    setisActive(!isActive);

    console.log(isActive, "active vv");
    console.log(pageNumber, " pppp");
  };

  //  -----------------------dispatch, pageNumber--------------------------
  const handlePageChange = (pageNumber) => {
    // alert("222");
    setCurrentpages(pageNumber);

    const nowvisiable = [];
    for (let i = pageNumber; i <= pageNumber + 4; i++) {
      if (i <= pagesize) {
        nowvisiable.push(i);
      }
    }
    console.log(nowvisiable, "huih", pagesize);
    setVisiblePages(nowvisiable);

    setoPages(pageNumber);
    setisActive(!isActive);

    console.log(isActive, "active vv");
    console.log(pageNumber, " pppp");

    setTimeout(() => {
      if (selects === "1") {
        // debugger
        // console.log(Years,Months,Budata,"ssss");
        // dispatch(
        //   getRankingJuniorAction({
        //     Years: Years,
        //     Months: Months,
        //     Budata: Budata,
        //     pagings: pageNumber,
        //     type: "JUNIOR",
        //   })
        // );
        if (search != undefined && search != "") {
          dispatch(
            getRankingJuniorAction({
              Years: Years,
              Months: Months,
              Budata: Budata,
              pagings: pageNumber,
              type: typeSEARCH,
              search: search.trim(),
            })
          );
        } else {
          dispatch(
            getRankingJuniorAction({
              Years: Years,
              Months: Months,
              Budata: Budata,
              pagings: pageNumber,
              // pagings: 1,
              type: "JUNIOR",
            })
          );
        }
      }
      if (selects === "2") {
        // dispatch(
        //   getSeniorRankAction({
        //     Years: Years,
        //     Months: Months,
        //     SeniorMW: SeniorMW,
        //     pagings: pageNumber,
        //     type: "SENIOR",
        //   })
        // );
        if (search != undefined && search != "") {
          dispatch(
            getSeniorRankAction({
              Years: Years,
              Months: Months,
              SeniorMW: SeniorMW,
              pagings: pageNumber,
              type: typeSEARCH,
              search: search.trim(),
            })
          );
        } else {
          dispatch(
            getSeniorRankAction({
              Years: Years,
              Months: Months,
              SeniorMW: SeniorMW,
              pagings: pageNumber,
              // pagings: 1,
              type: "SENIOR",
            })
          );
        }
      }
      if (selects === "3") {
        // dispatch(
        //   getmasterRankAction({
        //     Years: Years,
        //     Months: Months,
        //     Masters: Masters,
        //     pagings: pageNumber,
        //     type: "MASTER",
        //   })
        // );
        if (search != undefined && search != "") {
          dispatch(
            getmasterRankAction({
              Years: Years,
              Months: Months,
              Masters: Masters,
              pagings: pageNumber,
              type: typeSEARCH,
              search: search.trim(),
            })
          );
        } else {
          dispatch(
            getmasterRankAction({
              Years: Years,
              Months: Months,
              Masters: Masters,
              pagings: pageNumber,
              // pagings: 1,
              type: "MASTER",
            })
          );
        }
      }
      if (selects === "4") {
        // dispatch(
        //   getProcoachRankAction({
        //     Years: Years,
        //     Months: Months,
        //     pagings: pageNumber,
        //     type: "PROCOACH",
        //   })
        // );
        if (search != undefined && search != "") {
          dispatch(
            getProcoachRankAction({
              Years: Years,
              Months: Months,
              pagings: pageNumber,
              type: typeSEARCH,
              search: search.trim(),
            })
          );
        } else {
          dispatch(
            getProcoachRankAction({
              Years: Years,
              Months: Months,
              pagings: pageNumber,
              // pagings: 1,
              type: "PROCOACH",
            })
          );
        }
      }
    }, 10);
  };

  const SetpaginationLinks = () => {
    // debugger
    const paginationLinks = [];
    for (let i = 0; i < visiblepages.length; i++) {
      if (visiblepages[i] <= pagesize) {
        paginationLinks.push(
          <li
            key={visiblepages[i]}
            className={`page-item activesty ${
              currentpage === visiblepages[i] ? "active" : ""
            }`}
          >
            <span
              className="page-link active  selectedpge "
              onClick={() => handlePageChange(visiblepages[i])}
            >
              {visiblepages[i]}
            </span>
          </li>
        );
      } else {
        // paginationLinks.push(
        //   <li key={visiblepages[i]} className='page-item'>
        //   </li>
        // )
      }
    }
    return paginationLinks;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 1, pageSize: pagesize },
    },

    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <div className="searchs ">
        <div className="SEA-RANK">
          {/* <SearchIcon /> */}
          {/* <i class="fa-solid fa-magnifying-glass searchIcon"></i> */}
          <input
            class=" searchInput"
            placeholder="Search.."
            onChange={handleSearch}
          />
        </div>
      </div>

      {data != undefined && data.length > 0 ? (
        <div className="table-responsive reactTable my-4">
          <table className="table table1" {...getTableProps()}>
            <thead className="Thead_content">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="text-center TableHead"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-responsive reactTable my-4">
          <table className="table table1" {...getTableProps()}>
            <thead className="Thead_content">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="text-center TableHead"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
          <div className="no-data-wrap">
            <span className="no-data">No data.</span>
          </div>
        </div>
      )}

      <div className="PageSCROL ">
        <div className="panginations  ">
          <div className="showingindex ">
            <span className="SHOWINDEX1">
              Showing {currentpage} to {pageSize} Pages
            </span>
          </div>

          <div className="paginpart">
            <nav aria-label="Page navigation example" className="NAV_PART">
              <ul class="pagination MAIN_PAGES justify-content-end">
                <li
                  className={`page-item NP_P  Previous ${
                    currentpage === 1 ? "disabled" : ""
                  }`}
                >
                  <a
                    class={`page-link pages ${
                      currentpage === 1 ? "disabled-link" : ""
                    }`}
                    href="#"
                    tabindex="-1"
                    onClick={handlePrevious}
                  >
                    Previous
                  </a>
                </li>
                {SetpaginationLinks()}
                <li
                  className={`page-item NP_P  next ${
                    currentpage === pagesize || currentpage === pagesize + 1
                      ? "disabled"
                      : ""
                  }`}
                >
                  <span
                    class={`page-link pages ${
                      currentpage == pagesize || currentpage === pagesize + 1
                        ? "disabled-link"
                        : ""
                    }`}
                    onClick={handleNextPage}
                  >
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
