import React from "react";
import Navbar from "../MainComponets/NavBar/Navbar1";
import Navbar2 from "../MainComponets/NavBar/Navbar2";
import Footer from "../Appcomponents/Footer";
import PowerBanner from "../Appcomponents/PowerBanner";
import SocialMedia from "../MainComponets/SocialMedia";
import "../../Stylesheets/AppComponents/PrivacyPolicy.scss";

const PriacyPolicy = () => {
  return (
    <div className="privacy-policy-main-wrapper">
      <Navbar />

      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers policy-inner">
          <div class="title shimmer">
            <h4>
              <b>TERMS &amp; CONDITION</b>
            </h4>
          </div>

          <p class="pt-4 fontsizecontent">
            This website is owned and operated by the Squash Rackets Federation
            of India ("SRFI"). Please read these terms and conditions carefully,
            as by accessing this site you are agreeing to abide by all of the
            conditions set out below. The SRFI reserves the right to modify,
            alter or update these terms and conditions at any time and you agree
            to be bound by such modifications, alterations and updates. If you
            do not agree to these terms and conditions, please discontinue using
            this website immediately.
            <a
              target="_blank"
              href="https://demowhats.s3.ap-south-1.amazonaws.com/media/privacy/TERMS OF SERVICE SRFI.pdf"
            >
              More
            </a>
          </p>

          <h5 class="pt-4 subhead">
            <b>Website Content</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            All materials, including illustrations, photographs, products,
            images, artwork, designs, text, graphics, logos, button icons,
            images, audio and video clips and software (collectively, "Content")
            appearing on this website are owned or controlled by the SRFI and/or
            its subsidiaries, affiliates and content suppliers and are protected
            by worldwide design, trademark, trade dress, copyright and other
            intellectual property laws. The Content of this website, and the
            website as a whole, are intended solely for personal use by the
            users of this website. You may not copy Content from this website,
            or otherwise modify, obscure or delete any copyright or other
            propriety notices on this website. No right, title or interest in
            any materials or software, if applicable, on this website is
            transferred to you from your use of this website. You may not
            reproduce, publish, transmit, distribute, display, modify, create
            derivative works from, sell or participate in any sale of, or
            exploit in any way, in whole or in part, any of the Content, this
            website, or any related software without the prior written
            authorization of the SRFI.
          </p>

          <h5 class="pt-4 subhead">
            <b>Contributed Content</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            This website may contain views/ideas/opinions expressed by
            independent authors/contributors/posters on various topics. Such
            views/ideas/opinions are solely those of the
            authors/contributors/posters and not endorsed in any manner by the
            SRFI. The SRFI shall not be responsible for or liable in any manner
            whatsoever for the views/ideas/opinions expressed by such
            authors/contributors/posters in any section of this website.
            Further, the accuracy of such statements has not been verified by
            the SRFI and the SRFI provides no warranties of the same. If you
            seek to rely on any representation of information contained on this
            website, any such reliance shall be at your own risk.
          </p>

          <h5 class="pt-4 subhead">
            <b>Ownership of Intellectual Property</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            All trademarks, service marks and trade names on this website, are
            proprietary to the SRFI unless otherwise stated. No use of these may
            be made for any purpose whatsoever without the prior written
            authorization of the SRFI.
          </p>

          <h5 class="pt-4 subhead">
            <b>Warranty and Liability Disclaimer</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            This website and the Content herein is provided by the SRFI in good
            faith on an "as is", "as available" basis and the SRFI does not
            guarantee the accuracy, timeliness, completeness, performance or
            fitness for a particular purpose of this website. Further, the SRFI
            makes no representations or warranties of any kind, express or
            implied, as to the operation of this website, the information, the
            Content, materials or products included herein or hereon. Except to
            the extent as provided by the applicable law, the SRFI disclaims all
            responsibility (whether direct, indirect, consequential or
            otherwise) with respect to the accuracy or otherwise of the
            information, Content, materials or products arising from the use of
            this website.
          </p>

          <h5 class="pt-4 subhead">
            <b>Accuracy of Information</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            The information presented on this website has been compiled by the
            SRFI from various sources including from external sources. No
            representation is made or warranty given as to the completeness or
            accuracy of such information. This website may contain typographical
            errors, incomplete or out of date information. The SRFI reserves the
            right to make changes to the Content and information on this
            website, or to the services described therein, or update such
            information at any time without notice, but the SRFI makes no
            commitment to correct or update this information.
          </p>

          <h5 class="pt-4 subhead">
            <b>Indemnification</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            You agree to defend, indemnify and hold the SRFI harmless from and
            against any and all claims, damages, costs and expenses, including
            attorney's fees, arising from or related to your use of this
            website, your access to, or inability to access this website, or
            from your reliance upon any information contained on this website.
          </p>

          <h5 class="pt-4 subhead">
            <b>Termination</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            SRFI may immediately, without notice, deny you access to this
            website if, in SRFI's opinion, you fail to comply with any term or
            provision of the terms and conditions set forth herein.
          </p>

          <h5 class="pt-4 subhead">
            <b>Infringement of Copyright</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            In good faith, if you have reason to believe that any work
            copyrighted by you has been reproduced, embedded, or linked without
            any authorization on this website in a manner that constitutes an
            infringement of your copyright under Indian law, please compile the
            following information and email it to&nbsp;office@indiasquash.com
          </p>

          <p class="pt-4 fontsizecontent">
            We may contact you using the Personal Information you have given us:
          </p>

          <ul class="contantli">
            <li class="pt-2 contantul">
              A clear identification of the copyrighted work allegedly
              infringed;
            </li>
            <li class="pt-2 contantul">
              An clear identification of the allegedly infringing material on
              the website (with specific URL reference);
            </li>
            <li class="pt-2 contantul">
              Your contact details: name, address, e-mail address and phone
              number;
            </li>
            <li class="pt-2 contantul">
              A statement that you believe, in good faith, that the use of the
              copyrighted material allegedly infringed on the website is not
              authorized by you or your agent or the law;
            </li>
            <li class="pt-2 contantul">
              A statement that the information provided in the notice is
              accurate, and under penalty of perjury, that the signatory is
              authorized to act on behalf of the owner of an exclusive copyright
              right that is allegedly infringed;
            </li>
            <li class="pt-2 contantul">
              Your signature or a signature of your authorized agent.
            </li>
          </ul>

          <h5 class="pt-4 subhead">
            <b>Applicable Law</b>
          </h5>
          <p class="pt-4 pb-4 fontsizecontent">
            This website, including the Content and information contained
            herein, shall be governed by the laws of the Republic of India and
            the courts of Chennai, India shall retain exclusive jurisdiction to
            entertain any proceedings in relation to any disputes arising out of
            the same. As such, the laws of India shall govern the disclaimer,
            legal notices, privacy policy, order form and any transaction
            completed using this website.
          </p>

          <h5 class="pt-4 subhead-main">
            <b className="">PAYMENT REFUND POLICY</b>
          </h5>

          <h5 class="pt-4 small-subhead">
            <b>For I-SPIN Pro fee</b>
          </h5>

          <p class="pt-4 fontsizecontent">No refund for I-SPIN Pro fees.</p>
          <p class="pt-1 fontsizecontent">
            I-SPIN Pro fee is not transferrable.
          </p>

          <h5 class="pt-4 small-subhead">
            <b>For Event Registration Fee</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            The registration fee is not transferable.
          </p>
          <p class="pt-1 fontsizecontent">
            100% Refund only for withdrawals before the event registration
            closing date/time.
          </p>
          <p class="pt-1 fontsizecontent">
            No Refund for withdrawals after the event registration closing
            date/time.
          </p>
          <p class="pt-1 fontsizecontent">
            100% Refund if the event is cancelled or postponed.
          </p>

          <h5 class="pt-4 small-subhead">
            <b>For Refund terms</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            {" "}
            Refunds will be in Indian rupees only.
          </p>
          <p class="pt-1 fontsizecontent">
            A refund will be processed and credited back to the original
            credit/debit card or bank account.
          </p>
          <p class="pt-1 fontsizecontent">
            Refund will be processed within 7 to 14 days.
          </p>

          <h5 class="pt-4 subhead-main">
            <b className="">DATA PRIVACY POLICY</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            {" "}
            This privacy policy applies to current and former personals who have
            registered for I-SPIN Basic and Pro account.
            <a
              target="_blank"
              href="https://demowhats.s3.ap-south-1.amazonaws.com/media/privacy/Data_privacy_policy_new.pdf"
            >
              More
            </a>
          </p>

          <h5 class="pt-4 subhead-main">
            <b className="">OTHER TERMS</b>
          </h5>
          <p class="pt-4 fontsizecontent">
            Your use of this site is subject to the Terms of Use. If you choose
            to use this site, your visit and any dispute over privacy and data
            collection is subject to this Privacy Policy.
          </p>

          <h5 class="pt-4 subhead-main">
            <b className="">HOW TO CONTACT US</b>
          </h5>

          <p class="pt-4 fontsizecontent">
            {" "}
            The Squash Rackets Federation of India
          </p>
          <p class="fontsizecontent">Khivraj Complex II,</p>
          <p class="fontsizecontent">2nd Floor, No. 480 Anna Salai,</p>

          <p class="fontsizecontent">Chennai - 600035,</p>
          <p class=" fontsizecontent">India.</p>
          <p class=" fontsizecontent">+91 44 2434 0252</p>
          <p class=" fontsizecontent">+91 44 2434 0253</p>
          <p class=" fontsizecontent">squashsecretariate@gmail.com</p>
          <p class="pt-1 fontsizecontent">
            If you have any questions, comments or concerns about this Privacy
            Policy or the information practices of this site, please contact us
            as follows:
          </p>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default PriacyPolicy;
