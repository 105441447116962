import React from "react";
import "../../../Stylesheets/MainComponent/Events/SrfiGuidelines.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

const SrfiGuidelines = () => {
  function Download() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/Master+Guidelines.pdf"
    );
  }

  return (
    <div className="guideline-main-wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers guideline-inner">
          <div class="title shimmer">
            <h4>
              <b>SRFI GUIDELINES</b>
            </h4>
          </div>

          <div className="guidelines-container1">
            <p className="main-text">
              The mission of the federation is to create greater public
              awareness of Squash and increase the number of players playing the
              game. In order to do this, the federation has set out guidelines
              which covers all aspects of the game. Anything you need to know
              about Indian squash can be found in the SRFI Guidelines.
            </p>
          </div>

          <div className="guidelines-container2">
            {/* <h3 className="keyrules">INDEX OF KEY RULES</h3>
            <ul class=" pl-3 FontSzInallMenus">
              <li class="pt-2 contantul">
                Violation of code of conduct – Page #9
              </li>
              <li class="pt-2 contantul">
                Player eligibility to take part in an event – Page #13
              </li>
              <li class="pt-2 contantul">
                Event registration and closing time – Page #13
              </li>
              <li class="pt-2 contantul">
                National Rankings points calculation procedure - Page #17
              </li>
              <li class="pt-2 contantul">
                Event withdrawal penalty – Page #18{" "}
              </li>
              <li class="pt-2 contantul">
                Wild card for PSA events held in India – Page #33
              </li>
              <li class="pt-2 contantul">Player guidelines – Page # 36 </li>
            </ul> */}

            <button className="guide-btn" onClick={Download}>
              Download/View SRFI Guidelines
            </button>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default SrfiGuidelines;
