import React, { useState, useEffect } from "react";
import "../../Stylesheets/AppComponents/Banner.scss";
import Navbar2 from "../MainComponets/NavBar/Navbar2";
import Carousel from "react-bootstrap/Carousel";
import { useSelector, useDispatch } from "react-redux";

// import CarouselBanner1 from "../../Images/DOB banner.jpg";
// import CarouselBanner2 from "../../Images/Corona-Banner-02.jpg";
// import CarouselBanner3 from "../../Images/PSA_landscape _banner_new.jpg";
import { MainBannerData } from "../../Components/JsonData/MainBannerData";
import srfinew from "../../assets/Images/srfinew.jpg";

import {
  getBannerAction,
  getBannerSuccessAction,
  getBannerErrorAction,
} from "../../Redux/Slice";

const Banner = () => {
  const dispatch = useDispatch();

  const [banner, setBanner] = useState([]);

  // main banner data
  const bannerfulldata = useSelector((state) => state.BannerList.BannerData);
  console.log(bannerfulldata, "bann");

  useEffect(() => {
    dispatch(getBannerAction());
  }, []);

  useEffect(() => {
    setBanner(bannerfulldata.data);
    console.log(bannerfulldata.data, "ban123");
  }, [bannerfulldata]);

  function getVideoIdFromURL(url) {
    const match = url.match(/v=([a-zA-Z0-9_-]+)/);
    if (match && match[1]) {
      return match[1];
    } else {
      return ""; // Handle invalid URLs gracefully
    }
  }

  var s3url2 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  return (
    <div className="main-banner-wrap">
      <div className="banner-container">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="home-carousel">
          <Carousel>
            {banner &&
              banner.map((item) => (
                <Carousel.Item key={item.Sno}>
                  <div className="banner-content">
                    {/* <img src={item.image} className="banner-image"></img> */}
                    {item.filter1 === "image" ? (
                      <img
                        src={s3url2 + item.VideURL}
                        alt={`Image ${item.Sno}`}
                        className="banner-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = srfinew;
                        }}
                      />
                    ) : item.filter1 === "video" ? (
                      <iframe
                        title={`Video ${item.Sno}`}
                        width="100%"
                        height="400"
                        src={`https://www.youtube.com/embed/${getVideoIdFromURL(
                          item.VideURL
                        )}`}
                        frameBorder="0"
                        allowFullScreen
                        className="banner-video"
                      ></iframe>
                    ) : null}
                  </div>
                </Carousel.Item>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Banner;
