import React from "react";
import "../../../Stylesheets/MainComponent/Events/SquashRule.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

const SquashRule = () => {
  function single() {
    console.log("kokokokoko");
    window.open(
      "https://www.worldsquash.org/wp-content/uploads/2018/11/190101_Rules-of-Singles-Squash-2019-V1.pdf"
    );
  }

  function double() {
    window.open(
      "https://www.worldsquash.org/wp-content/uploads/2014/11/150101_Doubles-Rules-Final.pdf"
    );
  }

  return (
    <div className="squashrule-main-wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers squashrule-inner">
          <div class="title shimmer">
            <h4>
              <b>SQUASH RULES</b>
            </h4>
          </div>

          <div className="suashrule-container">
            <p>
              The SRFI follows the rules and regulations governing squash set by
              the World Squash Federation. These include, game play on court,
              scoring systems and conduct expected from both players and
              referees
            </p>
            <p>
              For your full understanding and implementation of the rules of the
              game, please download these rules as PDFs
            </p>

            <div className="keyrules-content">
              <ul className="ruleslist">
                <li className="pt-2">
                  <button className="Download-btn" onClick={single}>
                    WSF Single rules of squash
                  </button>
                </li>
                <li className="pt-2">
                  <button className="Download-btn" onClick={double}>
                    WSF Doubles rules of squash
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default SquashRule;
