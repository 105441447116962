import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/Events/ImportantInfo.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import scroll from "../../../assets/Images/scrollup.png";


const ImportantInfo = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // calender

  function c1() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government Recognition Certificate 2010/1.jpg"
    );
  }

  function c2() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government Recognition Certificate 2011/1.jpg"
    );
  }

  function c3() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government Recognition Certificate 2012/Government Recognition Certificate 2012-page-001.jpg"
    );
  }

  function c4() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/ASF%20Recognition%202020/ASF%20Recognition%202022.pdf"
    );
  }

  function c5() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/WSF%20Recognition%202020/WSF%20Recognition%202022.pdf"
    );
  }

  function c6() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government recognition certificate 2008/Govt_order-1.jpg"
    );
  }

  function c7() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government recognition certificate 2009/Govt_ordernew-1.jpg"
    );
  }

  // function one() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Srfi%20AGM/SRFI%20AGM%202019/SRFI%20AGM%202019%20Minutes.pdf"
  //   );
  // }
  function certificate_of_registration_socities() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate_of_registration_socities/Registration%20Certificate%20SRFI.jpeg"
    );
  }

  function Minutes_Squash() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Annual%20Calendar%20for%20Training%20and%20Competition/ACTC%202019-20%20Minutes-Squash.pdf"
    );
  }

  function wada_2020_english_prohibited_list_0() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Anti%20Doping%20Information%20&%20details/wada_2020_english_prohibited_list_0.pdf"
    );
  }

  function WSF_Anti_Doping_Rules_V3_0() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Anti%20Doping%20Information%20&%20details/WSF-Anti-Doping-Rules-V3.0.PDF"
    );
  }

  function Advertisement() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Advertisement%20for%20Coach/Advertisement%20for%20foreign%20coach.pdf"
    );
  }

  // sc hello

  function sc_2014_2020() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Selection%20Criteria/SelectionCriteria/Selection%20Criteria%202014%20to%202018.pdf"
    );
  }
  function sc_2018() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Selection%20Criteria/SelectionCriteria/Selection%20Criteria%202018.pdf"
    );
  }
  function sc_2019() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Selection%20Criteria/SelectionCriteria/Selection%20Criteria%202019.pdf"
    );
  }
  //   6a
  function sc_2023() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Selection%20Criteria/Selection2023/Selection%20Criteria%202023.pdf"
    );
  }
  //   6b
  function asia_games() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/Selection%20Criteria/SelectionAG2023/Selection%20Criteria%20AG%202023.pdf"
    );
  }

  //   others
  function o1() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/64September%20Edition.pdf"
    );
  }
  function o2() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Asian%20Games%202018%20%E2%80%93%20Selection%20Trials.pdf"
    );
  }
  function o3() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Bonus%20points%20for%20College%20Admissions.pdf"
    );
  }
  function o4() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Electoral%20College%20List%20for%202019%20Election.pdf"
    );
  }

  function o5() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Funds%20Generated%202014-15.pdf"
    );
  }
  function o6() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Government%20Recognition%20Certificate%202010.pdf"
    );
  }
  function o7() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Government%20Recognition%20Certificate%202011.pdf"
    );
  }
  function o8() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Grant%20Details%202014-15.pdf"
    );
  }
  function o9() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Incentives%20for%20Recruitment%20of%20Sportspersons.pdf"
    );
  }
  function o10() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Memorandum%20&%20Articles%20of%20Association.pdf"
    );
  }

  function o11() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Memorandum%20of%20Association%20and%20By-laws%20Aritcles%20of%20Association.pdf"
    );
  }

  function o12() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Ministry%20of%20Youth%20Affairs%20and%20Sports.pdf"
    );
  }

  function o13() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/National%20Code%20against%20Age%20Fraud%20In%20Sports.pdf"
    );
  }

  function o14() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Process-for-participation-in-International-tournaments-training.pdf"
    );
  }

  function o15() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Recognition.pdf"
    );
  }

  function o16() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Regulations%20relating%20to%20Sanctioned%20and%20Unsanctioned%20events.pdf"
    );
  }

  function o17() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Selection%20Criteria%202019-20.pdf"
    );
  }

  function o18() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/Selection%20Criteria%202019-%2020%20for%2020th%20Asian%20Team%20Championships,%20Malaysia.pdf"
    );
  }

  function o19() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/SRFI%20Code%20of%20Conduct.pdf"
    );
  }

  function o20() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/SRFI%20Identity%20Cards.pdf"
    );
  }

  function o21() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/outside%20pdf/WSF%20World%20Junior%20Championship%202018%20-%20Report.PDF"
    );
  }

  function o22() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/Advertisement+-+Sports+Analyst.pdf"
    );
  }

  //   function printPDF() {
  //     // Get the iframe element
  //     var pdfViewer = document.getElementById("pdfViewer");

  //     // Check if the iframe element exists
  //     if (pdfViewer) {
  //       // Get the iframe's content window
  //       var pdfWindow = pdfViewer.contentWindow;

  //       // Check if the content window is available
  //       if (pdfWindow) {
  //         // Trigger the print dialog for the PDF
  //         pdfWindow.print();
  //       } else {
  //         alert("Failed to open print dialog. Please try again.");
  //       }
  //     } else {
  //       alert("PDF viewer not found. Please make sure the PDF is embedded.");
  //     }
  //   }

  //   function showImage() {
  //     var previewImage = document.getElementById('previewImage');
  //     previewImage.src = "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government Recognition Certificate 2010/1.jpg"
  //     var imageContainer = document.getElementById('imageContainer');
  //     imageContainer.style.display = 'block';
  // }

  // Function to Print Image
  // function printImage() {
  //     var previewImage = document.getElementById('previewImage');
  //     var printWindow = window.open('', '', 'width=600,height=600');
  //     printWindow.document.open();
  //     printWindow.document.write('<html><head><title>Print Image</title></head><body>');
  //     printWindow.document.write('<img src="' + previewImage.src + '" alt="Printed Image" width="100%">');
  //     printWindow.document.write('</body></html>');
  //     printWindow.document.close();
  //     printWindow.print();
  //     printWindow.close();
  // }

  return (
    <div className="importantinfo-main_wrapper">
      {isVisible && (
        <button id="scrollToTopButton" onClick={scrollToTop}>
          <img src={scroll} className="scrollup"></img>
        </button>
      )}
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers importantinfo-inner">
          <div class="title shimmer">
            <h4>
              <b>IMPORTANT INFO</b>
            </h4>
          </div>

          <div className="agm-btns-wrapper">
            <div class="accordion" id="accordionExample">
              {/* 1 */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Certificate
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <button class="inner-btn-amg" onClick={c1}>
                      Government Recognition Certificate 2010
                    </button>

                    <button class="inner-btn-amg" onClick={c2}>
                      Government Recognition Certificate 2011
                    </button>

                    <button class="inner-btn-amg" onClick={c3}>
                      Government Recognition Certificate 2012
                    </button>

                    <button class="inner-btn-amg" onClick={c4}>
                      ASF Recognition 2022
                    </button>
                    <button class="inner-btn-amg" onClick={c5}>
                      WSF Recognition 2022
                    </button>

                    <button class="inner-btn-amg" onClick={c6}>
                      Government Recognition Certificate 2008
                    </button>

                    <button class="inner-btn-amg" onClick={c7}>
                      Government Recognition Certificate 2009
                    </button>

                    {/* <!-- Container for Image Preview --> */}
                    {/* <div id="imageContainer" style={{ display: "none" }}>
                    <img
                      id="previewImage"
                      src="https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/files/certificate/Government Recognition Certificate 2010/1.jpg"
                      alt="Image Preview"
                      style={{ width: "400" }}
                    />
                    <br />
                    <button onclick={printImage()}>Print Image</button>
                  </div> */}
                  </div>
                </div>
              </div>

              {/* 2 */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Certificate of Registration of Societies
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <button
                      className="inner-btn-amg"
                      onClick={certificate_of_registration_socities}
                    >
                      Registration Certificate SRFI.jpeg
                    </button>
                  </div>
                </div>
              </div>

              {/* 3 */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Annual Calendar for Training and Competition
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <button className="inner-btn-amg" onClick={Minutes_Squash}>
                      ACTC 2019-20 Minutes-Squash.pdf
                    </button>
                  </div>
                </div>
              </div>

              {/* 4 */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Anti Doping Information & details
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <button
                      className="inner-btn-amg"
                      onClick={wada_2020_english_prohibited_list_0}
                    >
                      Wada 2020-English-Prohibited-List 0.pdf
                    </button>
                    <button
                      className="inner-btn-amg"
                      onClick={WSF_Anti_Doping_Rules_V3_0}
                    >
                      WSF-Anti-Doping-Rules-V3.0.PDF
                    </button>
                  </div>
                </div>
              </div>

              {/* 5 */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Advertisement for Coach
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <button className="inner-btn-amg" onClick={Advertisement}>
                      Advertisement for foreign coach.pdf
                    </button>
                  </div>
                </div>
              </div>

              {/* 6 */}
              {/* <div class="accordion" id="mainAccordion"> */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Selection Criteria
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    {/* <!-- 6a --> */}
                    <div class="accordion" id="nestedAccordion">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSixA">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixA"
                            aria-expanded="false"
                            aria-controls="collapseSixA"
                            onclick="event.stopPropagation();"
                          >
                            Selection Criteria to represent India at major
                            championships
                          </button>
                        </h2>
                        <div
                          id="collapseSixA"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingSixA"
                          data-bs-parent="#nestedAccordion"
                        >
                          <div class="accordion-body">
                            <button className="inner-btn-amg" onClick={sc_2023}>
                              Selection Criteria 2023.pdf
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 6b */}
                    <div class="accordion" id="nestedAccordion2">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSixB">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixB"
                            aria-expanded="false"
                            aria-controls="collapseSixB"
                            onclick="event.stopPropagation();"
                          >
                            Selection Criteria for Asian Games
                          </button>
                        </h2>
                        <div
                          id="collapseSixB"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingSixB"
                          data-bs-parent="#nestedAccordion"
                        >
                          <div class="accordion-body">
                            <button
                              className="inner-btn-amg"
                              onClick={asia_games}
                            >
                              Selection Criteria AG 2023.pdf
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- normal pdfs --> */}
                    <button className="inner-btn-amg" onClick={sc_2014_2020}>
                      Selection Criteria 2014 to 2018.pdf
                    </button>
                    <button className="inner-btn-amg" onClick={sc_2014_2020}>
                      Selection Criteria 2018.pdf
                    </button>
                    <button className="inner-btn-amg" onClick={sc_2014_2020}>
                      Selection Criteria 2019.pdf
                    </button>
                  </div>
                </div>
              </div>
              {/* </div> */}

              {/* othres */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Others
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <button className="inner-btn-amg" onClick={o1}>
                      64September Edition.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o2}>
                      Asian Games 2018 – Selection Trials.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o3}>
                      Bonus points for College Admissions.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o4}>
                      Electoral College List for 2019 Election.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o5}>
                      Funds Generated 2014-15.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o6}>
                      Government Recognition Certificate 2010.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o7}>
                      Government Recognition Certificate 2011.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o8}>
                      Grant Details 2014-15.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o9}>
                      Incentives for Recruitment of Sportspersons.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o10}>
                      Memorandum &amp; Articles of Association.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o11}>
                      Memorandum of Association and By-laws Aritcles of
                      Association.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o12}>
                      Ministry of Youth Affairs and Sports.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o13}>
                      National Code against Age Fraud In Sports.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o14}>
                      Process-for-participation-in-International-tournaments-training.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o15}>
                      Recognition.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o16}>
                      Regulations relating to Sanctioned and Unsanctioned
                      events.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o17}>
                      Selection Criteria 2019-20.pdf
                    </button>

                    <button className="inner-btn-amg" onClick={o18}>
                      Selection Criteria 2019- 20 for 20th Asian Team
                      Championships, Malaysia.pdf{" "}
                    </button>

                    <button className="inner-btn-amg" onClick={o19}>
                      Code of Conduct.pdf{" "}
                    </button>

                    <button className="inner-btn-amg" onClick={o20}>
                      Identity Cards.pdf{" "}
                    </button>

                    <button className="inner-btn-amg" onClick={o21}>
                      World Junior Championship 2018 - Report.PDF{" "}
                    </button>
                    <button className="inner-btn-amg" onClick={o22}>
                      Advertisement - Sports Analyst.pdf
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default ImportantInfo;
