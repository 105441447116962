import React, { useEffect, useState } from "react";
import "../../../Stylesheets/MainComponent/Federations/BalanceSheets.scss";

import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

import { getRosPdfAction } from "../../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";

const RegistereSociety = () => {
  const dispatch = useDispatch();

  const [reg, setreg] = useState([]);

  const rospdffulldata = useSelector((state) => state.RosPdfList.RosPdf);
  // console.log(rospdffulldata, "agmpdf");

  useEffect(() => {
    dispatch(getRosPdfAction());
  }, []);

  useEffect(() => {
    if (rospdffulldata) {
      setreg(rospdffulldata); // Check if agmpdffulldata is defined and access 'RosPdf'
      // console.log(rospdffulldata, "rororo");
    }
  }, [rospdffulldata]);

  const openPdf = (url) => {
    // console.log("url:", url); // Check the value of url
    window.open(url);
  };
  return (
    <div className="balance-main-wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers balance-inner">
          <div class="title shimmer">
            <h4>
              <b>SRFI AGM</b>
            </h4>
          </div>

          <div className="balance-btns-wrapper">
            <div className="accordion" id="accordionExample">
              {reg.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <button
                        className="inner-btn-amg"
                        onClick={() =>
                          openPdf(
                            "https://demowhats.s3.ap-south-1.amazonaws.com/" +
                              item.filefield
                          )
                        } // Use item.filefield
                      >
                        {item.title}.pdf
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default RegistereSociety;
