import React, { useEffect, useMemo, useState } from "react";
import "../../../Stylesheets/MainComponent/Events/Calender.scss";
import Navbar1 from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SrfiGuidelines from "./SrfiGuidelines";
import SquashRule from "./SquashRule";
import SquashTime from "./SquashTime";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import calender from "../../../assets/Images/calender-icon.png";
import bat from "../../../assets/Images/squashbat.png";
import location from "../../../assets/Images/location-icon.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import prologo from "../../../assets/Images/prologo.jpeg";
import startlogo from "../../../assets/Images/starlogo.png";
import { MDBDataTable } from "mdbreact";
import scroll from "../../../assets/Images/scrollup.png";
import {
  getCalenderYearAction,
  getCalenderYearSuccessAction,
  getCalenderYearErrorAction,
} from "../../../Redux/Slice";
import {
  getCalenderFilterAction,
  getCalenderFilterSuccessAction,
  getCalenderFilterErrorAction,
} from "../../../Redux/Slice";
import {
  getCalenderTourDetailsAction,
  getCalenderTourDetailsSuccessAction,
  getCalenderTourDetailsErrorAction,
  getCalenderTourDetailsEmptyAction,
  tournamentid,
} from "../../../Redux/Slice";
import { pagelength } from "../../../Redux/Slice";
// import {
//   getCalTourEntriesAction,
//   getCalTourEntriesSuccessAction,
//   getCalTourEntriesErrorAction,
// } from "../../../Redux/Slice";

import srfinew from "../../../assets/Images/srfinew.jpg";

import {
  getCalTourEntriesPAGEAction,
  getCalTourEntriesPAGESuccessAction,
  getCalTourEntriesPAGEErrorAction,
} from "../../../Redux/Slice";

// import { updatetourtype } from "../../../Redux/Slice";
import ImportantInfo from "./ImportantInfo";
import News from "./News";

import Table from "../Events/Table";

const Calender = ({}) => {
  const dispatch = useDispatch();
  // const dispatch1 = useDispatch();

  // PAGINATION
  // PAGE
  const [targetd, settargetd] = useState("");
  const [currentd, setcurrentd] = useState(new Date());

  let [pagings, setPaging] = useState(1);
  let [paginations, setpaginations] = useState(0);
  let [RankingtableData, setRankingtabaleData] = useState([]);

  console.log(RankingtableData, "rrr");
  const [filterCalYear, setFilterCalYear] = useState([]);
  const [calenderFilterAll, setCalenderFilterAll] = useState([]);

  const [showDetail, setShowDetail] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null); // Store the selected card data

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabClick = (tabname) => {
    setActiveTab(tabname);
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // tourdetails
  const [calTourDetails, setCalTourDetails] = useState([]);
  const [calInfoDetails, setCalInfoDetails] = useState([]);

  console.log(calInfoDetails, "qqq1");

  const [entriesPlayer, setEntriesPlayer] = useState([]);

  const handleCardClick = (cardData, tournament) => {
    // debugger
    console.log(tournament, "wsx");
    // dispatch(getCalenderTourDetailsAction(tournament));
    setSelectedCard(cardData);

    dispatch(tournamentid(tournament));

    setShowDetail(true);
    setview(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const tourcardid = useSelector((state) => state.IdData.tournamentid);
  console.log("t1", tourcardid);

  const handleBackClick = () => {
    setShowDetail(false);
    dispatch(getCalenderTourDetailsEmptyAction());
    setview(false);
    dispatch(tournamentid(""));
  };

  // dynamic year change
  // const currentDate1 = new Date();

  // Check if the current month is December (month index 11)
  // if (currentDate1.getMonth() === 11) {
  //   // If it's December, increment the year by 1
  //   currentDate1.setFullYear(currentDate1.getFullYear() + 1);
  // }

  // Get the year of the next year
  // const nextYear = currentDate1.getFullYear();
  // console.log("Next year:", nextYear);

  const currentYear = new Date().getFullYear();

  // FILTER
  const [calType, setCalType] = useState("AL");
  const [calYear, setCalYear] = useState(currentYear);
  const [calCategory, setCalCategory] = useState("AL");
  const [calStar, setCalStar] = useState("AL");
  const [view, setview] = useState(false);

  // entries dd filter
  const [EntriesCategory, setEntriesCategory] = useState("AL");

  const handleCategorySelect2 = (value) => {
    console.log("enen");
    setEntriesCategory(value);
    dispatch(pagelength([1, 2, 3, 4, 5]));
  };

  const handleTypeSelect = (value) => {
    setCalType(value);
  };

  const handleYearSelect = (value) => {
    setCalYear(value);
    console.log(value, "uuu");
  };

  const handleCategorySelect = (value) => {
    setCalCategory(value);
  };

  const handleStarSelect = (value) => {
    setCalStar(value);
  };

  const filtercalyearfulldata = useSelector((state) => state.CalenderYearList);
  // console.log(filtercalyearfulldata, "cal year");

  useEffect(() => {
    // console.log(filtercalyearfulldata.CalenderYearData, "YEAR123");
    setFilterCalYear(filtercalyearfulldata.CalenderYearData);
  }, [filtercalyearfulldata]);

  const calenderfilterfulldata = useSelector(
    (state) => state.CalenderFilterList
  );
  console.log(calenderfilterfulldata, "cal full");

  useEffect(() => {
    dispatch(
      getCalenderFilterAction({
        Type: calType,
        Year: calYear,
        Category: calCategory,
        Star: calStar,
      })
    );
  }, [calType, calYear, calCategory, calStar]);

  useEffect(() => {
    dispatch(getCalenderYearAction());
  },[])

  
  useEffect(() => {
    // console.log(calenderfilterfulldata.CalenderFilterData, "cal full 123");
    setCalenderFilterAll(calenderfilterfulldata.CalenderFilterData);
  }, [calenderfilterfulldata]);

  const caltourdetailsfulldata = useSelector(
    (state) => state.CalenderTourDetailsList
  );
  console.log(caltourdetailsfulldata, "caltour");

  useEffect(() => {
    dispatch(getCalenderTourDetailsAction(tourcardid));
  }, [tourcardid]);

  useEffect(() => {
    if (tourcardid != "") {
      setview(true);
    } else {
      setview(false);
    }
  }, [tourcardid]);
  useEffect(() => {
    // console.log(
    //   caltourdetailsfulldata.CalenderTourDetailsData.tour,
    //   "cal tour details"
    // );
    // console.log(caltourdetailsfulldata.CalenderTourDetailsData,"ooo")
    if (
      caltourdetailsfulldata.CalenderTourDetailsData &&
      caltourdetailsfulldata.CalenderTourDetailsData.tour != undefined
    ) {
      // debugger
      setShowDetail(true);
      setCalTourDetails(caltourdetailsfulldata.CalenderTourDetailsData.tour);
      if (caltourdetailsfulldata.CalenderTourDetailsData.tour[0].EntryEndDate) {
        const currentDate = new Date();
        const targetDateStr = String(
          caltourdetailsfulldata.CalenderTourDetailsData.tour[0].EntryEndDate
        );
        const [dd, mm, yyyy] = targetDateStr.split("-");
        const targetDate = new Date(`${yyyy}-${mm}-${dd}`);

        console.log(targetDate, "123456", targetDateStr);
        settargetd(targetDate);

        if (currentDate > targetDate) {
          console.log("The current date is later than 09-11-2023");
        } else if (currentDate < targetDate) {
          console.log("The current date is earlier than 09-11-2023");
        } else {
          console.log("The current date is the same as 09-11-2023");
        }
      }
    } else {
      // debugger
      // setShowDetail(false);
    }
  }, [caltourdetailsfulldata]);

  useEffect(() => {
    setCalInfoDetails(caltourdetailsfulldata.CalenderTourDetailsData);
    console.log(caltourdetailsfulldata.CalenderTourDetailsData, "calinfo");
  }, [caltourdetailsfulldata]);

  // table
  // const caltourentriesfulldata = useSelector(
  //   (state) => state.CalTourEntriesList
  // );
  // console.log(caltourentriesfulldata, "ent");

  // useEffect(() => {
  // dispatch(getCalTourEntriesAction(tourcardid, EntriesCategory));
  // }, [EntriesCategory]);

  // useEffect(() => {
  //   setEntriesPlayer(caltourentriesfulldata.CalTourEntriesData.data);
  //   console.log(caltourentriesfulldata.CalTourEntriesData.data, "cal ent");
  // }, [caltourentriesfulldata]);

  // entries table PAGE
  const caltourentriesPAGEfulldata = useSelector(
    (state) => state.CalTourEntriesPAGEList
  );

  // useEffect(() => {
  //   dispatch(
  //     getCalTourEntriesPAGEAction({ tourid: tourcardid, type: "AL", page: 1 })
  //   );
  //   console.log(tourcardid, "bnm", EntriesCategory);
  // }, [tourcardid]);

  useEffect(() => {
    console.log(caltourentriesPAGEfulldata, "PAGE123");
    if (caltourentriesPAGEfulldata.CalTourEntriesPAGEData) {
      setRankingtabaleData(
        caltourentriesPAGEfulldata.CalTourEntriesPAGEData.data
      );
      setpaginations(
        caltourentriesPAGEfulldata.CalTourEntriesPAGEData.total_pages
      );
    }
  }, [caltourentriesPAGEfulldata]);

  const handlePageChange = (newPage) => {
    // debugger
    // Update the page number
    setpaginations(newPage);

    console.log(newPage, "ppppp");

    // Dispatch the action with updated values
    dispatch(
      getCalTourEntriesPAGEAction({
        tourid: tourcardid,
        type: EntriesCategory,
        page: newPage,
      })
    );
  };

  useEffect(() => {
    // Fetch data based on the updated "type" and "pagings"
    if (tourcardid != undefined && tourcardid != "") {
      dispatch(
        getCalTourEntriesPAGEAction({
          tourid: tourcardid,
          type: EntriesCategory,
          page: pagings,
        })
      );
    }
  }, [tourcardid, EntriesCategory, pagings]);




  $(document).ready(function () {
    $(".select").click(function () {
      var is_open = $(this).hasClass("open");
      if (is_open) {
        $(this).removeClass("open");
      } else {
        $(this).addClass("open");
      }
    });

    $(".select li").click(function () {
      var selected_value = $(this).html();
      var first_li = $(".select li:first-child").html();

      $(".select li:first-child").html(selected_value);
      $(this).html(first_li);
    });

    $(document).mouseup(function (ttype) {
      var target = ttype.target;
      var select = $(".select");

      if (!select.is(target) && select.has(target).length === 0) {
        select.removeClass("open");
      }
    });
  });

  var s3url = "https://demowhats.s3.ap-south-1.amazonaws.com/";
  var s3url2 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  const eventsByMonth = {};
  calenderFilterAll &&
    calenderFilterAll.length > 0 &&
    calenderFilterAll.forEach((ttype) => {
      const monthYear = `${ttype.Month} ${new Date(
        ttype.TournamentEndDate
      ).getFullYear()}`;
      if (!eventsByMonth[monthYear]) {
        eventsByMonth[monthYear] = [];
      }
      eventsByMonth[monthYear].push(ttype);
    });

  // function getTournamentStatus(startDate, endDate) {
  //   const currentDate = new Date();
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);

  //   if (currentDate >= start && currentDate <= end) {
  //     return "Open";
  //   } else if (currentDate > end) {
  //     return "Closed";
  //   } else {
  //     return "Not Open";
  //   }
  // }

  // react table
  const columns = React.useMemo(
    () => [
      {
        Header: "SEED",
        accessor: "seed",
      },
      {
        Header: "PLAYER DETAILS",
        accessor: "PlayerName",
      },
      {
        Header: "RANK",
        accessor: "rank",
      },
    ],
    []
  );

  // Helper function to format the date as "ddmmyyyy"
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  return (
    <div className="calender-main-wrapper">
      {isVisible && (
        <button id="scrollToTopButton" onClick={scrollToTop}>
          <img src={scroll} className="scrollup"></img>
        </button>
      )}
      <Navbar1 />
      <div className="containers calender-inner">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="subnavbar-wrapper">
          <div id="exTab3" class="container-fluid navtabs-wrapper">
            {/* <ul class="nav nav-pills">
              <li class="active subnavbar">
                <a href="#1b" data-toggle="tab" className="subnav-list">
                  Calender
                </a>
              </li>
              <li>
                <a href="#2b" data-toggle="tab" className="subnav-list">
                  SRFI Guidelines
                </a>
              </li>
              <li>
                <a href="#3b" data-toggle="tab" className="subnav-list">
                  Squash Rules
                </a>
              </li>
              <li>
                <a href="#4b" data-toggle="tab" className="subnav-list">
                  Conduct an event
                </a>
              </li>
              <li>
                <a href="#5b" data-toggle="tab" className="subnav-list">
                  Major Games
                </a>
              </li>
              <li>
                <a href="#6b" data-toggle="tab" className="subnav-list">
                  News
                </a>
              </li>
              <li>
                <a href="#7b" data-toggle="tab" className="subnav-list">
                  SquashTime
                </a>
              </li>
              <li>
                <a href="#8b" data-toggle="tab" className="subnav-list">
                  Important Info
                </a>
              </li>
            </ul> */}

            <div class="tab-content clearfix">
              <div class="tab-pane active" id="1b">
                <div class="containers navmargintop text-justify">
                  <div class="title shimmer">
                    <h4>
                      <b>CALENDAR</b>
                    </h4>
                  </div>

                  <div className="calender-main-container">
                    {view && showDetail ? (
                      // Render detail view using selectedCard data
                      <div className="card-more-details-wrapper">
                        {/* Display selectedCard details */}
                        <button onClick={handleBackClick} className="back-btn">
                          <ArrowBackIosIcon />
                          Back
                        </button>

                        {calTourDetails &&
                          calTourDetails != null &&
                          calTourDetails != undefined &&
                          calTourDetails.length > 0 &&
                          calTourDetails.map((items, index) => (
                            <div key={index}>
                              <div className={`inner-card-fulldetails`}>
                                <div className="card card2">
                                  <div className="card-body box-one">
                                    <div className="calender-details">
                                      <div className="calender-date">
                                        <img
                                          src={calender}
                                          className="date-img"
                                          alt=""
                                        ></img>
                                        <span className="nomp tourdate">
                                          {items.TournamentStartDate}
                                        </span>{" "}
                                        -{" "}
                                        <span className="nomp tourdate">
                                          {items.TournamentEndDate}
                                        </span>
                                      </div>
                                      <div className="calender-tour">
                                        <img
                                          src={bat}
                                          className="bat-img"
                                          alt=""
                                        ></img>
                                        <span className="tourname">
                                          {items.TournamentName}
                                        </span>
                                      </div>
                                      <div className="calender-venue">
                                        <img
                                          src={location}
                                          className="location-img"
                                          alt=""
                                        ></img>
                                        <span className="tourvenue">
                                          {items.Venue}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="img-cont">
                                      <div className="logo">
                                        <img
                                          src={s3url2 + items.Logo}
                                          alt=""
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = srfinew;
                                          }}
                                        ></img>
                                      </div>

                                      {/* <div className="star"> */}
                                      {/* <img
                                        src={s3url + items.StarLogo}
                                        alt=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                        }}
                                      ></img> */}
                                      {/* <span className="starstatus">
                                          {items.StarStatus}
                                        </span> */}
                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="info-entries-match-video">
                                <div className="subnavbar">
                                  <div
                                    className={`tab ${
                                      activeTab === "tab1"
                                        ? "active-tab-line"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      className={
                                        activeTab === "tab1" ? "active" : ""
                                      }
                                      onClick={() => handleTabClick("tab1")}
                                    >
                                      Info
                                    </button>
                                  </div>
                                  <div
                                    className={`tab ${
                                      activeTab === "tab2"
                                        ? "active-tab-line"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      className={
                                        activeTab === "tab2" ? "active" : ""
                                      }
                                      onClick={() => handleTabClick("tab2")}
                                    >
                                      Entries
                                    </button>
                                  </div>
                                  <div
                                    className={`tab ${
                                      activeTab === "tab3"
                                        ? "active-tab-line"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      className={
                                        activeTab === "tab3" ? "active" : ""
                                      }
                                      onClick={() => handleTabClick("tab3")}
                                    >
                                      Match
                                    </button>
                                  </div>
                                  <div
                                    className={`tab ${
                                      activeTab === "tab4"
                                        ? "active-tab-line"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      className={
                                        activeTab === "tab4" ? "active" : ""
                                      }
                                      onClick={() => handleTabClick("tab4")}
                                    >
                                      Video
                                    </button>
                                  </div>
                                </div>
                                {activeTab === "tab1" && (
                                  <div className="info-container">
                                    <div className="info-container-inner">
                                      {calInfoDetails &&
                                        calInfoDetails != null &&
                                        calInfoDetails != undefined &&
                                        Object.keys(calInfoDetails).length >
                                          0 && (
                                          <div className="card-wrapper">
                                            <div className="box-one">
                                              <div className="no">
                                                {calInfoDetails.Entries}
                                              </div>
                                              <div className="head">
                                                ENTRIES
                                              </div>
                                            </div>
                                            <div className="box-one">
                                              <div className="no">
                                                {calInfoDetails.Category_list}
                                              </div>
                                              <div className="head">
                                                CATEGORY
                                              </div>
                                            </div>
                                            <div className="box-one">
                                              <div className="no">
                                                {calInfoDetails.State}
                                              </div>
                                              <div className="head">STATES</div>
                                            </div>
                                          </div>
                                        )}
                                    </div>

                                    <table class="tabledata" id="containerall">
                                      <tbody>
                                        <tr class="trborderno">
                                          <td class="pad hed1">
                                            CHAMPIONSHIP CHAIRMAN/DIRECTOR
                                          </td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="chairman">
                                            {items.ChampionshipChairman}
                                          </td>
                                        </tr>

                                        <tr class="trborderno">
                                          <td class="pad hed1">
                                            SRFI CHAMPIONSHIP ADMINISTRATOR
                                          </td>
                                          <td class="colon">:</td>
                                          <td
                                            class="pad det"
                                            id="administrator"
                                          >
                                            {items.ChampionshipAdminstrator}
                                          </td>
                                        </tr>

                                        <tr class="trborderno">
                                          <td class="pad hed1">EVENT TYPE</td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="eventtype">
                                            International
                                          </td>
                                        </tr>

                                        <tr class="trborderno">
                                          <td class="pad hed1">ADDRESS</td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="address">
                                            {items.Address}
                                          </td>
                                        </tr>

                                        <tr class="trborderno">
                                          <td class="pad hed1">CATEGORY </td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="category">
                                            {items.category}
                                          </td>
                                        </tr>

                                        <tr class="trborderno">
                                          <td class="pad hed1">PRIZE MONEY </td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="prizemoney">
                                            {}
                                          </td>
                                        </tr>

                                        {caltourdetailsfulldata.CalenderTourDetailsData &&
                                        caltourdetailsfulldata
                                          .CalenderTourDetailsData.tour !=
                                          undefined &&
                                        currentd > targetd == false ? (
                                          <tr className="trborderno">
                                            <td className="pad hed1">
                                              ENTRY CLOSING
                                            </td>
                                            <td className="colon">:</td>
                                            <td
                                              className="pad det"
                                              id="entryclosing"
                                            >
                                              {
                                                caltourdetailsfulldata
                                                  ?.CalenderTourDetailsData
                                                  ?.tour[0]?.EntryEndDate
                                              }
                                            </td>
                                          </tr>
                                        ) : null}

                                        <tr class="trborderno">
                                          <td class="pad hed1">
                                            SEEDING MONTH
                                          </td>
                                          <td class="colon">:</td>
                                          <td
                                            class="pad det"
                                            id="seedingmonth"
                                          ></td>
                                        </tr>
                                        <tr class="trborderno">
                                          <td class="pad hed1">PLAY OFF</td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="playoff"></td>
                                        </tr>
                                        <tr class="trborderno">
                                          <td class="pad hed1">DRAW TYPE</td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="drawtype">
                                            {items.Draw_Type}
                                          </td>
                                        </tr>
                                        <tr class="trborderno">
                                          <td class="pad hed1">
                                            FEDERATION CONTACT
                                          </td>
                                          <td class="colon">:</td>
                                          <td class="pad det" id="fedcontact">
                                            office@indiasquash.com
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {activeTab === "tab2" && (
                                  <div className="entries-container">
                                    <select
                                      name="categdrop"
                                      id="catlist"
                                      className="tourcatselect cursorpointer"
                                      value={EntriesCategory}
                                      onChange={(e) =>
                                        handleCategorySelect2(
                                          e.currentTarget.value
                                        )
                                      }
                                    >
                                      <option value="AL">ALL</option>
                                      <option value="BU11">BU11</option>
                                      <option value="GU11">GU11</option>
                                      <option value="BU13">BU13</option>
                                      <option value="GU13">GU13</option>
                                      <option value="BU15">BU15</option>
                                      <option value="GU15">GU15</option>
                                      <option value="BU17">BU17</option>
                                      <option value="GU17">GU17</option>
                                      <option value="BU19">BU19</option>
                                      <option value="GU19">GU19</option>
                                      <option value="MEN">MEN</option>
                                      <option value="WOMEN">WOMEN</option>
                                      <option value="MO35">MO35</option>
                                      <option value="MO45">MO45</option>
                                      <option value="MO55">MO55</option>
                                      <option value="MO65">MO65</option>
                                    </select>

                                    {RankingtableData != undefined &&
                                      caltourentriesPAGEfulldata &&
                                      caltourentriesPAGEfulldata.CalTourEntriesPAGEData && (
                                        <Table
                                          columns={columns}
                                          pagesize={paginations}
                                          pageindex={pagings}
                                          setPaging={setPaging}
                                          data={RankingtableData}
                                          onPageChange={handlePageChange}
                                          tourcardid={tourcardid}
                                          EntriesCategory={EntriesCategory}
                                        />
                                      )}
                                  </div>
                                )}
                                {activeTab === "tab3" && (
                                  <div className="info-container">
                                    <div className="coming-soon">
                                      Coming Soon...
                                    </div>
                                  </div>
                                )}
                                {activeTab === "tab4" && (
                                  <div className="info-container">
                                    <div className="coming-soon">
                                      Coming Soon...
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      !view && (
                        // Render card view
                        <div>
                          {/* Render your card components and handleCardClick for each card */}
                          <div className="filter-wrapper">
                            <div className="filter-dropdown">
                              <div className="tourcatogory">
                                <select
                                  name="tourdetails_length"
                                  aria-controls="tourdetails"
                                  id="ttype"
                                  // onChange={(e) => ttype(e.currentTarget.value)}
                                  onChange={(e) =>
                                    handleTypeSelect(e.currentTarget.value)
                                  }
                                  value={calType} // Set the selected value from the state
                                >
                                  <option value="AL">All</option>
                                  <option value="PSA">PSA</option>
                                  <option value="NationalCircuit">
                                    National Circuit
                                  </option>
                                  <option value="StateClosed">
                                    State Closed
                                  </option>
                                  <option value="Asian">Asian</option>
                                  <option value="World">World</option>
                                  <option value="International">
                                    International
                                  </option>
                                  <option value="NonRanking">
                                    Non-Ranking
                                  </option>
                                  <option value="SelectionTrials">
                                    Selection Trials
                                  </option>
                                  <option value="Course">Course</option>
                                  <option value="Camp">Camp</option>
                                </select>
                              </div>

                              <div className="calyear">
                                <select
                                  value={calYear}
                                  // onChange={(e) => handleStateSelect(e.target.value)}
                                  onChange={(e) =>
                                    handleYearSelect(e.currentTarget.value)
                                  }
                                >
                                  {filterCalYear.map((year, index) => (
                                    <option key={index} value={year.toString()}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="category">
                                <select
                                  value={calCategory}
                                  // onChange={(e) => ttype(e.currentTarget.value)}
                                  onChange={(e) =>
                                    handleCategorySelect(e.currentTarget.value)
                                  }
                                >
                                  <option value="AL">All</option>
                                  <option value="junior">Junior</option>
                                  <option value="senior">Senior</option>
                                  <option value="master">Master</option>
                                </select>
                              </div>

                              <div className="star">
                                <select
                                  value={calStar}
                                  // onChange={(e) => ttype(e.currentTarget.value)}
                                  onChange={(e) =>
                                    handleStarSelect(e.currentTarget.value)
                                  }
                                >
                                  <option value="AL">All Star</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                  <option value="4">Four</option>
                                  <option value="5">Five</option>
                                  <option value="6">Six</option>
                                  <option value="7">Seven</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="calender-cards">
                            <div className="container-fluid">
                              {Object.keys(eventsByMonth).length > 0 ? (
                                Object.keys(eventsByMonth).map(
                                  (monthYear, index) => (
                                    <div key={index}>
                                      <h2 className="monthandyear">
                                        {monthYear}
                                      </h2>
                                      <div className="row">
                                        {/* {calenderFilterAll &&
                                  calenderFilterAll.map((items, index) => ( */}
                                        {eventsByMonth[monthYear].map(
                                          (items, itemIndex) => (
                                            <div className="col-md-12 col-lg-6 card-outer">
                                              <div
                                                className="card"
                                                onClick={(e) => {
                                                  handleCardClick(
                                                    e,
                                                    items.TournamentID
                                                  );
                                                }}
                                              >
                                                <div className="card-body box-one">
                                                  <div className="calender-details">
                                                    <div className="calender-date">
                                                      <img
                                                        src={calender}
                                                        className="date-img"
                                                        alt=""
                                                      ></img>
                                                      <span className="nomp tourdate">
                                                        {
                                                          items.TournamentStartDate.split(
                                                            "-"
                                                          )[2]
                                                        }
                                                      </span>
                                                      -
                                                      <span className="nomp tourdate">
                                                        {
                                                          items.TournamentEndDate.split(
                                                            "-"
                                                          )[2]
                                                        }
                                                      </span>
                                                      {/* -{" "} */}
                                                      <span className="nomp tourdate">
                                                        {items.Month}
                                                      </span>
                                                    </div>
                                                    <div className="calender-tour">
                                                      <img
                                                        src={bat}
                                                        className="bat-img"
                                                        alt=""
                                                      ></img>
                                                      <span className="tourname">
                                                        {items.TournamentName
                                                          .length > 25
                                                          ? items.TournamentName.slice(
                                                              0,
                                                              25
                                                            ) + "..."
                                                          : items.TournamentName}
                                                      </span>
                                                    </div>
                                                    <div className="calender-venue">
                                                      <img
                                                        src={location}
                                                        className="location-img"
                                                        alt=""
                                                      ></img>
                                                      <span className="tourvenue">
                                                        {items.Venue.length > 25
                                                          ? items.Venue.slice(
                                                              0,
                                                              25
                                                            ) + "..."
                                                          : items.Venue}
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <div className="img-cont">
                                                    <div className="logo">
                                                      <img
                                                        src={
                                                          s3url2 + items.Logo
                                                        }
                                                        alt=""
                                                        onError={({
                                                          currentTarget,
                                                        }) => {
                                                          currentTarget.onerror =
                                                            null; // prevents looping
                                                          currentTarget.src =
                                                            "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                                        }}
                                                      ></img>
                                                    </div>

                                                    <div className="star">
                                                      <img
                                                        src={
                                                          s3url + items.StarLogo
                                                        }
                                                        alt=""
                                                        onError={({
                                                          currentTarget,
                                                        }) => {
                                                          currentTarget.onerror =
                                                            null; // prevents looping
                                                          currentTarget.src =
                                                            "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                                                        }}
                                                      ></img>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="register-wrap">
                                                  {items.EntryEndDate &&
                                                  new Date(items.EntryEndDate) >
                                                    new Date() ? (
                                                    <>
                                                      Register by
                                                      <span className="register-date">
                                                        {formatDate(
                                                          items.EntryEndDate
                                                        )}
                                                      </span>
                                                    </>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <p className="cal-no-data">No Data</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="2b">
                {/* <SrfiGuidelines /> */}
              </div>
              <div class="tab-pane" id="3b">
                {/* <SquashRule /> */}
              </div>
              <div class="tab-pane" id="4b">
                {/* <button className="coming-soon">Coming Soon...</button> */}
              </div>
              <div class="tab-pane" id="5b">
                {/* <button className="coming-soon">Coming Soon...</button> */}
              </div>
              <div class="tab-pane" id="6b">
                {/* <News /> */}
              </div>
              <div class="tab-pane" id="7b">
                {/* <SquashTime /> */}
              </div>
              <div class="tab-pane" id="8b">
                {/* <ImportantInfo /> */}
              </div>
            </div>
          </div>
        </div>
        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </div>
  );
};

export default Calender;
