import React from "react";
import Navbar2 from "../NavBar/Navbar2";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import Footer from "../../Appcomponents/Footer";
import Navbar from "../NavBar/Navbar1";
import "../../../Stylesheets/MainComponent/Official/Referee.scss";

const RefereePanel = () => {
  return (
    <>

      <div className='container-fluid PATHWAY-PART1'>
        <Navbar />
        <div className='container-fluid PATHWAY_inner_wrapper ' >
          <div className=" navbar2-container-PATH" >
            <Navbar2 />

          </div>



          <div className=' TABOFF my-4 text-justify content-PATHWAY1'>


           
            <div className="title shimmer">
              <h1 className="HeadingOFF RegereeHead my-4"> REFEREE PANEL</h1>
              </div>
              <br />
              <p className="paragraphOFF RefereePARA">
                As more and more players are interested in taking up squash, the
                SRFI believes in not only having a reservoir of good coaches,
                but also quality referees for the betterment of the sport. Along
                with other regional and WSF certified referees, the SRFI
                Director of Referees, assess the referees officiating matches
                during the junior and seniors National Championships on their
                decisions and observational skills, match control ability,
                confidence and communication skills. Workshops are also
                conducted during the event, giving valuable inputs on the
                current line of refereeing, player movement and match management
                to hone their existing skills.
              </p>
            </div>
          </div>
       
        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </>
  );
};

export default RefereePanel;
