import React from "react";
import "../../Stylesheets/AppComponents/Footer2.css";
import facebook2 from "../../assets/Images/Facebook2.png";
import instagram2 from "../../assets/Images/Instagram2.png";
// import srfi2 from "../../assets/Images/SRFI-Web-Logo.png";
import srfi2 from "../../assets/Images/SRFI-WEB-LOGO.png";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  return (
    <div className="footer_wrapper">
      <div className="container-fluid mar-pad">
        <div class="footer">
          <div class="container">
            {/* <div class="row">
              <div class="col-lg-3 col-sm-3 col-xs-12">
                <div class="single_footer">
                  <h4>QUICK LINKS</h4>

                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Tournament</a>
                    </li>
                    <li>
                      <a href="#">Players</a>
                    </li>
                    <li>
                      <a href="#">Gallery</a>
                    </li>
                    <li>
                      <a href="#">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <div class="single_footer single_footer_address">
                  <ul>
                    <li>
                      <a href="#">Upcoming Matches</a>
                    </li>
                    <li>
                      <a href="#">Ranking</a>
                    </li>
                    <li>
                      <a href="#">Latest Result</a>
                    </li>
                    <li>
                      <a href="#">Latest News</a>
                    </li>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single_footer">
                  <h4>FOLLOW US</h4>
                  <div className="social-wrap">
                    <button className="social-face">
                      <img className="foot-img" src={facebook2}></img>
                    </button>
                    <button className="social-insta">
                      <img className="foot-img"src={instagram2}></img>
                    </button>
                  </div>

                  <div className="srfi-logo-footer">
                    <img className="srfi2" src={srfi2}></img>
                   
                  </div>
                </div>
                <div className="srfi-text-div">
                <p className="srfi-text">
                      AFFILATED TO WORLD SQUASH FEDERATION, ASIAN SQUASH
                      FEDERATION RECOGIZED BY GOVERNMENT OF INDIA, MINISTRY OF
                      YOUTH AND SPORTS AFFAIRS.
                    </p>
                    </div>
              </div>
            </div> */}
            {/* <div class="row"> */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="single_footer">
                <h4>FOLLOW US</h4>
                <div className="social-wrap">
                  <button className="social-face">
                    <a
                      href="https://www.facebook.com/indiasquash"
                      // href="https://www.facebook.com/photo.php?fbid=749614007170645&set=a.505544184910963&type=3&ref=embed_page"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="foot-img" src={facebook2}></img>
                    </a>
                  </button>
                  <button className="social-insta">
                    <a
                      href="https://www.instagram.com/indiansquash/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="foot-img" src={instagram2}></img>
                    </a>
                  </button>
                </div>

                <div className="srfi-logo-footer">
                  <img className="srfi2" src={srfi2}></img>
                </div>
              </div>
              <div className="srfi-text-div">
                <span className="srfi-text">
                  AFFILIATED TO WORLD SQUASH FEDERATION, ASIAN SQUASH FEDERATION
                  RECOGNIZED BY GOVERNMENT OF INDIA,
                  MINISTRY OF YOUTH AND SPORTS AFFAIRS.
                </span>
              </div>
            </div>
            {/* </div> */}
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-xs-12">
                <p class="copyright">
                  © 2023 indiasquash.com. All rights reserved.&nbsp;|
                  <a href="/Privacypolicy" className="pri-pol">
                    &nbsp; Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
