import React from "react";
import "../../../Stylesheets/MainComponent/Federations/ContactUs.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

const ContactUs = () => {
  return (
    <div className="contacus-main-wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers contact-inner">
          <div class="title shimmer">
            <h4>
              <b>CONTACT US</b>
            </h4>
          </div>

          <div className="contact-container">
            <div className="addr">
              <div className="addr-details">
                <h5>Registered Office</h5>
                <p className="contact-details">
                  Khivraj Complex II,
                  <br />
                  2nd Floor, No. 480 Anna Salai,
                  <br />
                  Chennai 600035, India.
                  <br />
                  +91 44 2434 0252
                  <br />
                  <br></br>
                  <span>www.indiasquash.com</span>
                  <br />
                  <a href="mailto:squashsecretariate@gmail.com">
                    squashsecretariate@gmail.com
                  </a>
                  <br />
                  <a href="mailto:office@indiasquash.com">
                    office@indiasquash.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

<SocialMedia />

<Footer />
    </div>
  );
};

export default ContactUs;
