import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { usePagination } from "react-table";
import { useGlobalFilter } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import "../../../Stylesheets/MainComponent/Players/Table.scss";
import {
  setPagin,
  setSENIORPAGE,
  getCalenderFilterAction,
  getCalTourEntriesPAGEAction,
} from "../../../Redux/Slice";

import { pagelength } from "../../../Redux/Slice";

const Table = (props) => {
  // ---------------------Dispatchs-----------------
  const dispatch = useDispatch();

  const { columns, data, pagesize, pageindex } = props;

  const [isActive, setisActive] = useState(false);

  const [currentpage, setCurrentpages] = useState(1);
  const [visiblepages, setVisiblePages] = useState([]);
  const [topage, setoPages] = useState(pageindex);

  const caltourentriesPAGEfulldata = useSelector(
    (state) => state.CalTourEntriesPAGEList
  );

  // const caltourentriesPAGEfulldata = useSelector(
  //   (state) => state.CalTourEntriesPAGEList
  // );

  const pagelen = useSelector((state) => state.IdData.pagelength);
  console.log("pa1", pagelen);

  useEffect(() => {
    setCurrentpages(caltourentriesPAGEfulldata.PageIndex);
  }, [caltourentriesPAGEfulldata]);

  //  ------Paginations-----------------
  // --------------Previous--------------------
  const handlePrevious = (e) => {
    if (currentpage === 1) {
      setCurrentpages(1);
      console.log(currentpage, "sss");
    }

    const newPageNumber = currentpage - 1;
    handlePageChange(newPageNumber);
  };

  // --------------Next--------------------
  const handleNextPage = () => {
    const newPageNumber = currentpage + 1;
    handlePageChange(newPageNumber);
  };

  useEffect(() => {
    setVisiblePages(pagelen);
  }, []);

  //  -----------------------dispatch, pageNumber--------------------------
  const handlePageChange = (pageNumber) => {
    setCurrentpages(pageNumber);

    const nowvisiable = [];

    for (let i = pageNumber; i <= pageNumber + 4; i++) {
      if (i <= pagesize) {
        nowvisiable.push(i);
        // console.log(nowvisiable, "papapa")
      }
    }

    setVisiblePages(nowvisiable);
    dispatch(pagelength(nowvisiable));

    setoPages(pageNumber);
    setisActive(!isActive);

    // console.log(isActive, "active vv");
    // console.log(pageNumber, " pppp");
    setTimeout(() => {
      dispatch(
        getCalTourEntriesPAGEAction({
          tourid: props.tourcardid,
          type: props.EntriesCategory,
          page: pageNumber,
        })
      );
    }, 10);
  };

  const SetpaginationLinks = () => {
    const paginationLinks = [];
    for (let i = 0; i < visiblepages.length; i++) {
      if (visiblepages[i] <= pagesize) {
        paginationLinks.push(
          <li
            key={visiblepages[i]}
            className={`page-item activesty ${
              currentpage === visiblepages[i] ? "active" : ""
            }`}
          >
            <span
              className="page-link active  selectedpge "
              onClick={() => handlePageChange(visiblepages[i])}
            >
              {visiblepages[i]}
            </span>
          </li>
        );
      } else {
        // paginationLinks.push(
        //   <li key={visiblepages[i]} className='page-item'>
        //   </li>
        // )
      }
    }
    return paginationLinks;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 1, pageSize: pagesize },
    },

    useGlobalFilter,
    usePagination
  );

  return (
    <>
      {/* <div className="searchs ">
        <div className="SEA-RANK">
          <i class="fa-solid fa-magnifying-glass searchIcon"></i>
          <input
            class=" searchInput"
            placeholder="Search.."
            onChange={handleSearch}
          />
        </div>
      </div> */}

      {/* {data != undefined && (
        <div className="table-responsive reactTable my-4">
          <table className="table table1" {...getTableProps()}>
            <thead className="Thead_content">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="text-center TableHead"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )} */}

      {data != undefined && data.length > 0 ? (
        <div className="table-responsive reactTable my-4">
          <table className="table table1" {...getTableProps()}>
            <thead className="Thead_content">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="text-center TableHead"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-responsive reactTable my-4">
          <table className="table table1" {...getTableProps()}>
            <thead className="Thead_content">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="text-center TableHead"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
          <div className="no-data-wrap">
            <span className="no-data">No data.</span>
          </div>
        </div>
      )}

      <div className="PageSCROL ">
        <div className="panginations  ">
          <div className="showingindex ">
            <span className="SHOWINDEX1">
              Showing {currentpage} to {pageSize} Pages
            </span>
          </div>

          <div className="paginpart">
            <nav aria-label="Page navigation example" className="NAV_PART">
              <ul class="pagination MAIN_PAGES justify-content-end">
                <li
                  className={`page-item NP_P  Previous ${
                    currentpage === 1 ? "disabled" : ""
                  }`}
                >
                  <a
                    class={`page-link pages ${
                      currentpage === 1 ? "disabled-link" : ""
                    }`}
                    href="#"
                    tabindex="-1"
                    onClick={handlePrevious}
                  >
                    Previous
                  </a>
                </li>
                {SetpaginationLinks()}
                <li
                  className={`page-item NP_P  next ${
                    currentpage === pagesize || currentpage === pagesize + 1
                      ? "disabled"
                      : ""
                  }`}
                >
                  <span
                    class={`page-link pages ${
                      currentpage === pagesize || currentpage === pagesize + 1
                        ? "disabled-link"
                        : ""
                    }`}
                    onClick={handleNextPage}
                  >
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
