import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/Federations/StateUnits.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import { useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { RegisteredPlayers } from "../../../text/apidata";
// import 'mdbreact/dist/css/mdb.css';
import srfinew from "../../../assets/Images/srfinew.jpg";
import scroll from "../../../assets/Images/scrollup.png";
import ScrollToTop from "react-scroll-to-top";

import "../../../App.css";

import {
  getFilterStateAction,
  getFilterStateSuccessAction,
  getFilterStateErrorAction,
  stateid,
} from "../../../Redux/Slice";

import {
  getRegisterPlayerAction,
  getRegisterPlayerSuccessAction,
  getRegisterPlayerErrorAction,
} from "../../../Redux/Slice";

import {
  getEventsAction,
  getEventsSuccessAction,
  getEventsErrorAction,
} from "../../../Redux/Slice";

import defimg from "../../../assets/Images/state-logo1.png";

const StateUnits = () => {
  const screenWidth = window.innerWidth;


  const dispatch = useDispatch();

  // filter state dropdown
  const [filterState, setFilterState] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const [registerPlayer, setRegisterPlayer] = useState([]);
  const [registerPlayer2, setRegisterPlayer2] = useState([]);

  const [eventsTable, setEventsTable] = useState([]);

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabClick = (tabname) => {
    setActiveTab(tabname);
  };

  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  // scrollup
  // const [isVisible, setIsVisible] = useState(false);
  // const [scrollingToTop, setScrollingToTop] = useState(false); // Track if scrolling to top
  
  // Handle scroll event and update button visibility
  // const handleScroll = () => {
  //   if (!scrollingToTop && window.scrollY > 200) {
  //     // Adjust this value as needed
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //   }
  // };

  // // Add scroll event listener when the component mounts
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // Function to scroll to the top
  // const scrollToTop = () => {
  //   setScrollingToTop(true); // Set scrolling flag
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  // Reset scrolling flag when scrolling is complete
  // useEffect(() => {
  //   if (scrollingToTop) {
  //     setTimeout(() => {
  //       setScrollingToTop(false);
  //     }, 500); // Adjust the time as needed
  //   }
  // }, [scrollingToTop]);




  // reset state details while refresh
  useEffect(() => {
    // Dispatch the stateid action with the default value when the page is refreshed
    const handlePageRefresh = () => {
      dispatch(stateid("2"));
    };

    // Attach the event listener for beforeunload
    window.addEventListener("beforeunload", handlePageRefresh);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handlePageRefresh);
    };
  }, [dispatch]);

  const handleStateSelect = (state) => {
    dispatch(stateid(state));
    setSelectedState(state);

    dispatch(getRegisterPlayerAction(state));
    dispatch(getEventsAction(state));
  };

  const filterstatefulldata = useSelector((state) => state.FilterStateList);
  // console.log(filterstatefulldata.FilterStateData, "fisa");

  const registerplayerfulldata = useSelector(
    (state) => state.RegisterPlayerList
  );
  // console.log(registerplayerfulldata.RegisterPlayerData, "repl");

  const eventsfulldata = useSelector((state) => state.EventsList);
  // console.log(eventsfulldata.EventsData, "eve");

  const stateidres = useSelector((state) => state.IdData.stateid);
  // console.log("12345::", stateidres);

  // dropdown
  useEffect(() => {
    dispatch(getFilterStateAction(stateidres));
  }, []);

  // state dropdown
  useEffect(() => {
    if (
      filterstatefulldata.FilterStateData != undefined &&
      filterstatefulldata.FilterStateData.length > 0
    ) {
      setFilterState(filterstatefulldata.FilterStateData);
    }
    // console.log(filterstatefulldata.FilterStateData, "select states");
  }, [filterstatefulldata]);

  // card and state info
  useEffect(() => {
    setRegisterPlayer2(registerplayerfulldata.RegisterPlayerData.data);
    // console.log(registerplayerfulldata.RegisterPlayerData.data, "info");
  }, [registerplayerfulldata, selectedState]);

  // register player table
  useEffect(() => {
    dispatch(getRegisterPlayerAction(stateidres));
  }, []);

  useEffect(() => {
    setRegisterPlayer(registerplayerfulldata.RegisterPlayerData);
    // console.log(registerplayerfulldata.RegisterPlayerData, "reg player");
  }, [registerplayerfulldata, selectedState]);

  // events table
  // useEffect(() => {
  // dispatch(getEventsAction(stateidres));
  // }, []);

  // useEffect(() => {
  //   setEventsTable(eventsfulldata.EventsData);
  //   console.log(eventsfulldata.EventsData, "events");
  // }, [eventsfulldata]);

  useEffect(() => {
    setEventsTable(registerplayerfulldata.RegisterPlayerData);
    console.log(registerplayerfulldata.RegisterPlayerData, "events");
  }, [registerplayerfulldata]);

  const columns = [
    {
      label: "NAME",
      field: "FullName",
      sort: "asc",
    },
    {
      label: "GENDER",
      field: "Gender",
      sort: "acs",
    },
    {
      label: "AGE",
      field: "Dob",
      sort: "asc",
    },
    {
      label: "I-SPIN",
      field: "PlayerID",
      sort: "asc",
    },
    // {
    //   label: "RESIDENCE",
    //   field: "Residence",
    //   sort: "asc",
    // },
  ];

  const rows =
    registerPlayer.Players != undefined &&
    registerPlayer.Players.map((item) => ({
      FullName: item.FullName,
      Gender: item.Gender,
      Dob: item.Dob,
      PlayerID: item.PlayerID,
      // Residence: item.Residence,
    }));

  const tableData = {
    columns,
    rows,
  };

  // Events Table
  const columns2 = [
    {
      label: "NAME",
      field: "TournamentName",
      sort: "asc",
    },
    {
      label: "DATE",
      field: "TournamentStartDate",
      sort: "acs",
    },
    {
      label: "ADDRESS",
      field: "Address",
      sort: "asc",
    },
    {
      label: "TYPE",
      field: "TournamentType",
      sort: "asc",
    },
  ];

  const rows2 =
    eventsTable.Torunaments != undefined &&
    eventsTable.Torunaments.map((item) => ({
      TournamentName: item.TournamentName,
      TournamentStartDate: item.TournamentStartDate,
      Address: item.Address,
      TournamentType: item.TournamentType,
    }));

  const tableData2 = {
    columns: columns2,
    rows: rows2,
  };

  var s3url2 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  return (
    <div className="indiasquash-main-wrapper">
      {/* {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
          <img src={scroll} alt="Scroll to Top" className="scrollup" />
          </button>
        )} */}
      <ScrollToTop
        smooth
        className="scroll-to-top"
        // className={`scroll-to-top ${screenWidth >= 300 && screenWidth <= 550 ? 'media-query-class' : ''}`}
        color="transparent" // Set the color to transparent to hide the default arrow
        style={{
          backgroundImage: `url(${scroll})`,
          backgroundSize: "contain",
          height: "50px",
          width: "50px",
          borderRadius: "50%",

          position: "fixed",
          bottom: "20px",
          right: "50px", // Default right position
      
        }}
      />

      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>
        <div className="container-fluid indiasquash-inner">
          <div className="rowss">
            <div className="indiasquash-content">
              <div className="heading">
                <button>STATE UNITS</button>
              </div>

              <div className="indiasquash">
                <div className="state-dd">
                  <label className="select-state">Select States</label>
                  <select
                    value={selectedState}
                    onChange={(e) => handleStateSelect(e.target.value)}
                  >
                    {filterState.map((state, index) => (
                      <option key={index} value={state.State_id}>
                        {state.State_Name}
                      </option>
                    ))}
                  </select>
                </div>

                {registerPlayer2 != undefined &&
                  registerPlayer2.map((info, index) => {
                    // Split the ContactNo string into an array based on '/'
                    const contactParts = info.ContactNo.split("/");

                    // Initialize variables to store President and Secretary details
                    let president = { name: "", mob: "", email: "" };
                    let secretary = { name: "", mob: "", email: "" };

                    // Loop through the contact parts to find President and Secretary details
                    contactParts.forEach((part) => {
                      if (part.includes("President")) {
                        president.name = part.trim();
                      } else if (part.includes("Secretary")) {
                        secretary.name = part.trim();
                      } else if (part.includes("Mob:")) {
                        president.mob = part.trim();
                        secretary.mob = part.trim();
                      } else if (part.includes("Email:")) {
                        president.email = part.trim();
                        secretary.email = part.trim();
                      }
                    });

                    return (
                      <div key={index}>
                        <div class="card">
                          <div class="row">
                            <div class="col-sm-5 img-wrap">
                              <img
                                class="d-block w-100"
                                src={s3url2 + info.Logo}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = srfinew;
                                }}
                                alt="Logo"
                              />
                            </div>
                            <div class="col-sm-7 content-wrap">
                              <div class="card-block">
                                <p className="stateunitsname">
                                  {info.StateUnitsName}
                                </p>
                                <p className="contactno">
                                  <p className="contactno">
                                    {president.name}
                                    <br></br>
                                    {president.mob}
                                    <br></br>
                                    {president.email}
                                  </p>
                                  {/* Display Secretary details */}
                                  <p className="contactno">
                                    {secretary.name}
                                    <br></br>
                                    {secretary.mob}
                                    <br></br>
                                    {secretary.email}
                                  </p>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="state-info-wrap">
                          <div className="state-info">State Info:</div>
                          <div className="stateadrress-wrap">
                            <span>{info.Address}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="register-player-mainwrapper">
                <div className="subnavbar">
                  <div
                    className={`tab ${
                      activeTab === "tab1" ? "active-tab-line" : ""
                    }`}
                  >
                    <button
                      className={activeTab === "tab1" ? "active" : ""}
                      onClick={() => handleTabClick("tab1")}
                    >
                      Registered Players
                    </button>
                  </div>
                  <div
                    className={`tab ${
                      activeTab === "tab2" ? "active-tab-line" : ""
                    }`}
                  >
                    <button
                      className={activeTab === "tab2" ? "active" : ""}
                      onClick={() => handleTabClick("tab2")}
                    >
                      Events
                    </button>
                  </div>
                </div>

                {activeTab === "tab1" && (
                  <div>
                    {registerPlayer.Players != undefined && (
                      <MDBDataTable
                        className="custom-datatable"
                        hover
                        striped
                        // bordered
                        responsive
                        noBottomColumns
                        searching={true}
                        sortable={true}
                        // entries={false}
                        info={true}
                        infoLabel={["Showing", "to", "of", "Entries"]}
                        data={tableData}
                        paging={true}
                        paginationLabel={["Previous", "Next"]}
                        noRecordsFoundLabel="No data found in the table."
                        // paginationClassName="custom-pagination" // Add a custom class for pagination container
                        paginationLabelClassName="custom-pagination-label" // Add a custom class for pagination labels
                        // paginationListClassName="custom-pagination-list" // Add a custom class for pagination list items
                      />
                    )}
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div>
                    {eventsTable.Torunaments != undefined && (
                      <MDBDataTable
                        className="custom-datatable"
                        hover
                        striped
                        bordered
                        responsive
                        noBottomColumns
                        searching={true}
                        sortable={true}
                        // entries={false}
                        info={true}
                        infoLabel={["Showing", "to", "of", "Pages"]}
                        data={tableData2}
                        paging={true}
                        paginationLabel={["Previous", "Next"]}
                        noRecordsFoundLabel="No data found in the table."
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default StateUnits;
