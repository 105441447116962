import React, { useState } from "react";
import "../../../Stylesheets/MainComponent/Federations/IndiaSquash.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import StateUnits from "./StateUnits";
import Affiliation from "./Affiliation";
import Elections from "./Elections";
import Agm from "./Agm";
import OfficeBeares from "./OfficeBeares";
import ContactUs from "./ContactUs";
import btnborder from "../../../assets/Images/btn-design.png";

const IndiaSquash = () => {
  
  function ConstitutionClick() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/constitution/SRFI_MOA_2022_For%20Print.pdf"
    );
  }

  function CodeofConductClick() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/Code_of_conduct/SRFI_Code%20_of_Conduct.pdf"
    );
  }

  function PlayerAZClick() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/playersA%20to%20Z/SRFI%20-%20REGISTERED%20PLAYERS.pdf"
    );
  }

  function AntiDopingClick() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/Antidoping/2022list_final_en.pdf"
    );
  }

  function CommittiesClick() {
    window.open(
      "https://demowhats.s3.ap-south-1.amazonaws.com/media/COMMITTEES/AjCIvSRFICommittees.pdf"
    );
  }

  return (
    <div className="indiasquash_main_wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div class="container-fluid navmargintop">
          <div class="title shimmer">
            <h4>
              <b>INDIASQUASH</b>
            </h4>
          </div>

          <p class="pt-4 fontsizecontent">
            Squash was introduced in India by the British over 2 centuries ago
            and played in military cantonments and private clubs as a
            recreational sport. As a result it lacked visibility. Over time, The
            Squash Rackets Federation of India (SRFI) was formed to oversee the
            promotion and development of the sport. But the much needed impetus
            came in the new millennium thanks to the vision of Mr N.
            Ramachandran, the then Secretary-General of SRFI and former
            President, World Squash Federation.{" "}
          </p>

          <p class="pt-4 fontsizecontent">
            Over the past 20 years, the SRFI with its headquarters in Chennai,
            along with its 24 affiliated state associations have forged ahead
            with dynamic plans to promote the sport, PAN India. The SRFI, headed
            by office bearers and committee members put in a lot of effort to
            bring the sport “on par” with other racket sports.{" "}
          </p>

          <p class="pt-4 fontsizecontent">
            Squash has gained popularity as a competitive sport ever since 2014,
            the golden year of squash where India bagged “gold” at both the
            Commonwealth Games and Asian Games.
          </p>

          <p class="pt-4 fontsizecontent">
            Over the past few years, The SRFI has formalised long term
            development plans to transform Indian squash into one of the leading
            squash countries on the world circuit. A lot of effort and focus is
            now directed towards nurturing and developing our juniors who are
            the future of Indian Squash.
          </p>
        </div>
      </div>

      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default IndiaSquash;
