import { color } from "@mui/system";
import React, { useState } from "react";
import "../../../Stylesheets/MainComponent/Players/Players.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import ISpin from "./ISpin";
import HCL from "./HCL";
import AgeFraud from "./AgeFraud";
import TeamWork from "./TeamIndia";
import Ranking from "./Ranking";
import SelectionCriteria from "./SelectionCriteria";
import btnborder from "../../../assets/Images/btn-design.png";
import btn from "../../../assets/Images/btn-design.png";
const PlaySquash = () => {
    const [Activepart, setAcivepart] = useState("playSquash");
    const handleACTClick = (tabI) => {
        setAcivepart(tabI);
    }
    // --------------Player-A to Z----------------------

    return (
        <div className="container-fluid PLAYERS-PART1" >
            <Navbar />
            <div className="container-fluid PLAYERS_inner_wrapper ">
                <div className="navbar2-container-PLAYERS" >
                    <Navbar2 />
                </div>

                <div className=' text-justify  content-PLAYERS  '>

                    

                        <h4 className="my-4 HeadingTAB">
                            <b>PLAY SQUASH</b>
                        </h4>
                  
                    <br />
                    <div className="para-partINPlay ">
                        <p className="paragraph ">
                            Rated as “the number one” healthiest sport by Forbes, squash
                            keeps one in good shape both mentally and physically. Squash
                            provides a full upper and lower body work out, strengthens
                            muscles and burns up to 800 calories per hour a ll at the same
                            time. It is also good for one’s mental well being as squash
                            requires a high degree of concentration and anticipation about
                            the opponent’s next move. As an indoor sport, squash is a
                            sport played all year round!
                        </p>

                        <h1 className="Head-Content my-4 ">HEALTH BENEFITS OF PLAYING SQUASH</h1>
                        <ul className="paragraph contentPoints">
                            <li>
                                In squash, a player can use a lot more energy than most other
                                sports. Squash helps to:
                            </li>
                            <br />
                            <li>
                                Improve cardiovascular health. A game of squash can see you
                                running, leaping and diving for the ball
                            </li>
                            <br />
                            <li>
                                Increase strength and fitness
                            </li>

                            <br />
                            <li>
                                Maintain a healthy weight
                            </li>

                            <br />
                            <li>
                                Increase flexibility and strength in the back
                            </li>

                            <br />
                            <li>
                                Promote good coordination, agility and flexibility
                            </li>

                            <br />
                            <li>
                                Build hand–eye coordination.
                            </li>

                        </ul>
                        <h1 className="Head-Content my-4">OTHER BENEFITS OF PLAYING SQUASH</h1>
                        <ul className="paragraph contentPoints" type="">
                            <li>
                                Improved general and psychological wellbeing
                            </li>

                            <br />
                            <li>
                                Greater self-confidence and self-esteem
                            </li>
                            <br />
                            <li>
                                Better social skills and an opportunity to make new friends
                            </li>
                            <br />
                            <li>
                                Improved concentration
                            </li>
                            <br />
                            <li>
                                Stress release
                            </li>

                            <br />
                            All-weather activity – as it’s an indoor sport, weather
                            conditions don’t prevent you from playing.
                            <br />
                            <br />
                            Indian celebrities like Sachin Tendulkar, Kapil Dev and
                            Ranveer Singh, Tapasee Pannu and many other celebs play and
                            follow squash. So are Stefan Edberg, Roger Federer, Eddie
                            Murphy and Hugh Jackman, some of the international celebrities
                            who play squash.
                        </ul>

                    </div>
                </div>



                {/* --------i-SPIN----TAB2-------------- */}

                {Activepart === 'ISpin' && (


                    <div className="tbs TAB-Content tab-content" id="2b">

                        <ISpin />

                    </div>
                )}


                {/* --------HCL SPP------------------ */}
                {/* <div className="tab-pane" id="4b">
                            <HCL />
                        </div> */}
                {Activepart === 'HclSpp' && (


                    <div className="tbs TAB-Content tab-content" id="3b">

                        <HCL />

                    </div>
                )}


                {/* -------- RANKING------------------ */}
                {/* <div className="tab-pane" id="5b">
                            <Ranking />
                        </div> */}
                {Activepart === 'Ranking' && (


                    <div className="tbs TAB-Content tab-content" id="4b">

                        <Ranking />

                    </div>
                )}


                {/* -------- SELECTION CRITERIA------------------ */}
                {/* <div className="tab-pane" id="6b">
                            <SelectionCriteria />
                        </div> */}
                {Activepart === 'SelectionCriteria' && (


                    <div className="tbs TAB-Content tab-content" id="5b">

                        <SelectionCriteria />

                    </div>
                )}


                {/* -------- TEAM_WORK------------------ */}
                {/* <div className="tab-pane" id="7b">
                            <TeamWork />
                        </div> */}
                {Activepart === 'TeamWork' && (


                    <div className="tbs TAB-Content tab-content" id="6b">

                        <TeamWork />

                    </div>
                )}


                {/* --------AGE FRAUD------------------ */}
                {/* <div className="tab-pane" id="9b">
                            <AgeFraud />
                        </div> */}
                {Activepart === 'AgeFraud' && (


                    <div className="tbs TAB-Content tab-content" id="7b">

                        <AgeFraud />

                    </div>
                )}

            </div>


            <PowerBanner />

            <SocialMedia />

            <Footer />
        </div>
    );
};

export default PlaySquash;