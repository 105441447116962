import React from 'react'
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import "../../../Stylesheets/MainComponent/Players/AgeFraud.scss";
const AgeFraud = () => {

  return (
    <>
      <div className="container-fluid AGE-PART1" >
        <Navbar />  
        <div className="container-fluid  AGE_inner_wrapper ">
          <div className=" navbar2-container-AGE ">
            <Navbar2 />
          </div>
          <div className=' text-justify content-AGE '>

           
                <h1 className="HeadingTAB  my-4 HeadAge">
                  AGE FRAUD
                </h1>
                <p className='paragraphAgeFraud'>As a recognized National Sports Federation, the SRFI has accepted the National Code Against Age Fraud put forth by the Government of India for the elimination of age fraud in sport. The SRFI will take appropriate measures to check age fraud in order to ensure fair play to uphold the basic spirit of the sport.</p>
                <br />
                <p className='paragraphAgeFraud'>Junior players who have been selected to represent India at the Asian and World Championships would have to submit their test results in the prescribed format (attached) after undergoing an age verification test at Government authorized centres (as per list attached). This is mandatory and is required prior to their participation in these events.</p>
          
            
          </div>
        </div>
        <PowerBanner />
        <SocialMedia />
        <Footer />
      </div>
    </>
  )
}

export default AgeFraud
