import React, { useState, useEffect } from "react";
import "../../Stylesheets/AppComponents/MiniBanner.scss";
import Carousel from "react-bootstrap/Carousel";
import { MiniBannerData } from "../../Components/JsonData/MiniBannerData";
import srfinew from "../../assets/Images/srfinew.jpg"


const MiniBanner = () => {
  return (
    <div className="mini-banner-wrapper">
      <div className="mini-inner-container">
        <div className="minibanner-carousel">
          <Carousel>
            {MiniBannerData.map((item) => (
              <Carousel.Item key={item.id}>
                <div className="banner-content">
                  <img
                    src={item.image}
                    alt={item.content}
                    className="banner-img"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src =srfinew
                    }}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default MiniBanner;
