import React, { useEffect, useState } from "react";
import "../../../Stylesheets/MainComponent/Players/TeamIndia.scss";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  getAEPlayersAction,
  getALLPlayersAction,
  getWEPlayersAction,
} from "../../../Redux/Slice";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import scroll from "../../../assets/Images/scrollup.png";
import srfinew from "../../../assets/Images/srfinew.jpg";

const TeamIndia = () => {
  let [playersData, setPlayersData] = useState([]);

  const TEAMALL = useSelector((state) => state.TeamIndia.ALL);
  console.log(TEAMALL, "TTTTT");

  const TEAM_WE = useSelector((state) => state.TeamIndia.WE);
  console.log(TEAM_WE, "WWWWW");

  const data = useSelector((state) => state.TeamIndia.AE);

  const dispatch = useDispatch();

  let [allPlayers, setAllplayers] = useState(false);
  useEffect(() => {
    dispatch(getAEPlayersAction());
    if (!allPlayers) {
      setTimeout(() => {
        dispatch(getALLPlayersAction());
      }, 100);
      setAllplayers(true);
    }
    dispatch(getWEPlayersAction());
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // ----------AE--API-----------------------
  let imgs =
    "https://www.forbesindia.com/media/images/2022/Oct/img_196017_sauravghoshalsquash.jpg";

  return (
    <>
      <div className="container-fluid TEAM-PART1">
        <Navbar />
        <div className="container-fluid  TEAM_inner_wrapper ">
          <div className=" navbar2-container-TEAM ">
            <Navbar2 />
          </div>
          <div className=" text-justify content-TEAM ">
            <h1 className="HeadingTAB  my-4">TEAM INDIA</h1>

            <p className="paragraphTeamIndia">
              Squash has been played in India since the pre-war days. To a large
              extent it was introduced by the British forces that built squash
              courts in military cantonments and private clubs as an outlet for
              their sporting interests. In the early days squash had the
              following of a smattering few who were either from the forces,
              members of private clubs or children returning from studies
              abroad. It certainly fitted the name it was given at that time – a
              bourgeois sport. An organised for the promotion of the game was
              non-existent.
            </p>
            <br />

            <div className="teamplaysCARDS">
              <ul class="nav  nav-pills" id="MENUS">
                <li className="active ATAGGS">
                  <a
                    href="#alltab"
                    data-toggle="tab"
                    className="subnav-list Teamplays"
                  >
                    All
                  </a>
                  <span className="borderALL active" id="ALL"></span>
                </li>
                <li className="ATAGGS">
                  <a
                    href="#asianEvent"
                    data-toggle="tab"
                    className="subnav-list Teamplays"
                  >
                    Asian Event
                  </a>
                  <span className="borderALL tabBord" id="ASIAN"></span>
                </li>
                <li className="ATAGGS worldevent">
                  <a
                    href="#worldEvent"
                    data-toggle="tab"
                    className="subnav-list Teamplays"
                  >
                    World Event
                  </a>
                  <span className="borderALL tabBord" id="WORLD"></span>
                </li>
              </ul>

              {/* --------------------TABS------------------------- */}
              <div className="allTab tabsTEAM TAB-Contents  tab-content">
                {/* --------------------------ALL--------------------------- */}

                <div className="tab-pane  allcard  active" id="alltab">
                  <div className="ALLCARDSTEAM">
                  {TEAMALL != [] && TEAMALL != undefined && TEAMALL.map((playersAll, index) => (
                      <div className="Content-Card">
                        <div className="carousel-itemss TEAM " key={index}>
                          <div class="card  cardALl " key={playersAll.id}>
                            <img
                              class="card-img-top"
                              src="https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =srfinew
                              }}
                              alt="Card cap"
                              style={{ width: "120px", marginTop: "1vh" }}
                            />
                            <div class="card-body  text-center">
                              <h5 class="card-title cardtitle text-start">
                                {playersAll.Name}
                              </h5>
                              <p class="card-text cardtitle">
                                {playersAll.eventtype}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* --------------------ASIAN------------------ */}

                <div className="tab-pane allcard" id="asianEvent">
                  <div className="ALLCARDSTEAM">
                    {data.map((playersAE, index) => (
                      <div className="Content-Card">
                        <div className="carousel-itemss TEAM " key={index}>
                          <div class="card  cardALl" key={playersAE.id}>
                            <img
                              class="card-img-top"
                              src="https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =srfinew
                              }}
                              alt="Card cap"
                              style={{ width: "120px", marginTop: "1vh" }}
                            />
                            <div class="card-body text-center">
                              <h5 class="card-title  cardtitle text-start">
                                {playersAE.Name}
                              </h5>
                              <p class="card-text cardtitle">
                                {playersAE.eventtype}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* --------------------WORLD-Event------------------ */}

                <div className="tab-pane " id="worldEvent">
                  <div className="ALLCARDSTEAM">
                    {TEAM_WE.map((playersWE, index) => (
                      <div className="Content-Card">
                        <div className="carousel-itemss TEAM " key={index}>
                          <div class="card  cardALl" key={playersWE.id}>
                            <img
                              class="card-img-top"
                              src="https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =srfinew
                              }}
                              alt="Card cap"
                              style={{ width: "120px", marginTop: "1vh" }}
                            />
                            <div class="card-body text-center">
                              <h5 class="card-title cardtitle text-start">
                                {playersWE.Name}
                              </h5>
                              <p class="card-text cardtitle">
                                {playersWE.eventtype}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* ------------END----CARD----------------------- */}
              </div>
            </div>
          </div>

          {isVisible && (
            <button id="scrollToTopButton" onClick={scrollToTop}>
              <img src={scroll} className="scrollup"></img>
            </button>
          )}
        </div>

        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </>
  );
};

export default TeamIndia;
