import axios from "axios";
import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { sagaActions } from "./Action";
import { useSelector, useDispatch } from "react-redux";

import {
  getBannerSuccessAction,
  getBannerErrorAction,
  getUpcomingMatchSuccessAction,
  getUpcomingMatchErrorAction,
  getUpcomingSearchResponsesucessAction,
  getTopRankSuccessAction,
  getTopRankSearchResponsesucessAction,
  getTopRankErrorAction,
  getResultsSuccessAction,
  getResultsErrorAction,
  getNewsSuccessAction,
  getLatestNewsSearchAction,
  getNewsErrorAction,
  getVideosSuccessAction,
  getVideosErrorAction,
  getMasterProTopRankSuccessAction,
  getSearchResponsesucessAction,
  getMasterProTopRankErrorAction,
  getAffiliationSuccessAction,
  getAffiliationErrorAction,
  getAffiliationSearchAction,
  getFilterStateSuccessAction,
  getFilterStateErrorAction,
  getRegisterPlayerSuccessAction,
  getRegisterPlayerErrorAction,
  getEventsSuccessAction,
  getEventsErrorAction,
  getCalenderYearSuccessAction,
  getCalenderYearErrorAction,
  getCalenderFilterSuccessAction,
  getCalenderFilterSearchAction,
  getCalenderFilterErrorAction,
  getCalenderTourDetailsSuccessAction,
  getCalenderTourDetailsErrorAction,
  getCalTourEntriesSuccessAction,
  getCalTourEntriesErrorAction,
  getCalTourEntriesPAGESuccessAction,
  getCalTourEntriesPAGEErrorAction,
  getNewsTabSuccessAction,
  getNewsSearchAction,
  getNewsTabErrorAction,
  getNewsTabPutsSuccessAction,
  getNewsTabPutsErrorAction,
  postNewsTabsSuccessAction,
  postNewsTabsSearchAction,
  postNewsTabsErrorAction,
  getSquashTimeSuccessAction,
  getSquashTimeSearchAction,
  getSquashTimeErrorAction,

  // --------------TEAM_INDIA----------------
  getALLPlayersSuccess,
  getAEPlayersSuccess,
  getWEPlayersSuccess,
  getALLPlayersError,
  getAEPlayersError,
  getWEPlayersError,
  getALLPlayersSearch,
  getAEPlayersSearch,
  getWEPlayersSearch,
  // -------------Seniors------------------
  getRankingsJuniorSuccess,
  setPagin,
  getSeniorSuccessAction,
  setSENIORPAGE,
  getMasterSuccessAction,
  setTotalPageMASTER,
  getProcoachSuccessAction,
  setPaginPRO,
  getSearchSuccess,
  getSquashSuccessAction,
  getSquashErrorAction,
  // -----------Selection------PDFss--------------
  getSelectionActionSuccess,
  getSelectionErrorAction,

  // election pdf
  getElectionsPdfSuccessAction,
  getElectionsPdfErrorAction,
  // agm pdf
  getAgmPdfSuccessAction,
  getAgmPdfErrorAction,
  // reg of society pdf
  getRosPdfSuccessAction,
  getRosPdfErrorAction,
  // bal sheet
  getBalSheetPdfSuccessAction,
  getBalSheetPdfErrorAction,

  // Social media
  getSocialMediaSuccessAction,
  getSocialMediaErrorAction,
} from "./Slice";

import { store } from "./Store";
import { getLoaderAction } from "./LoaderSlice";
import { ShowErrorAction } from "./AlertSlice";
import { Banner, UpcomingMatch, Affiliation } from "../text/apidata";
import { TopRank, MasterProTopRank } from "../text/apidata";

import { LatestResults, HomepageNews, LatestVideos } from "../text/apidata";

import { FilterState, RegisteredPlayers } from "../text/apidata";
import {
  calenderYear,
  calenderFilter,
  tournamentDetails,
  CalTourEntries,
  CalTourEntriesPagination,
  newsTab,
  newsTabPuts,
  newspostapi,
  squashTime,
  SocialMedia,
} from "../text/apidata";

import {
  // getCalenderTourDetailsAction,
  // getCalenderTourDetailsSuccessAction,
  // getCalenderTourDetailsErrorAction,
  tournamentid,
} from "./Slice";

//URL for team  India
import {
  getAEPlayersURL,
  getALLPlayersURL,
  getWEPlayersURL,
} from "../text/apidata";
//URL for Ranking--JUNIOR
import { JuniorBU19, SeniorsMW, masterAPI, ProCoach } from "../text/apidata";

//indiasqushTV-------------------
import {
  IndiaSqushVideo,
  Elections,
  Agm,
  RegistereofSociety,
  BalanceSheet,
} from "../text/apidata";
import { SelctionPDF } from "../text/apidata";

// banner image or video
function* getBannerData() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: Banner,
    });
    yield put(getBannerSuccessAction(response.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getBannerErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// upcoming matches
function* getUpcomingMatchData() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: UpcomingMatch + "AL/" + new Date().getFullYear(),
    });
    yield put(getUpcomingMatchSuccessAction(response.data));
    yield put(getUpcomingSearchResponsesucessAction(response.data));

    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getUpcomingMatchErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// Top Rank

function* getTopRankData() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: TopRank,
    });
    // debugger
    yield put(getTopRankSuccessAction(response.data));
    yield put(getTopRankSearchResponsesucessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getTopRankErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// Result
function* getResultsData() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: LatestResults,
      //   headers: {
      //     Authorization:
      //       "Bearer " + store.getState().users.UserData.session.token,
      //   },
    });
    // debugger
    yield put(getResultsSuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getResultsErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// latest video
function* getVideosData() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: LatestVideos,
    });
    // debugger
    yield put(getVideosSuccessAction(response.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    // debugger
    yield put(getVideosErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// latest news
function* getNewsData() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: HomepageNews,
    });
    yield put(getNewsSuccessAction(response.data));
    yield put(getLatestNewsSearchAction(response.data));

    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getNewsErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// MasterPro
// function* getMasterProTopRank() {
//   try {
//     yield put(getLoaderAction(true));
//     // debugger
//     const response = yield axios({
//       method: "get",
//       url: MasterProTopRank,
//       //   headers: {
//       //     Authorization:
//       //       "Bearer " + store.getState().users.UserData.session.token,
//       //   },
//     });
//     // debugger
//     yield put(getMasterProTopRankSuccessAction(response.data));
//     yield put(getSearchResponsesucessAction(response.data));

//     yield put(getLoaderAction(false));
//     // debugger
//   } catch (error) {
//     // debugger
//     yield put(getMasterProTopRankErrorAction(error));
//     yield put(getLoaderAction(false));
//     yield put(ShowErrorAction(error.response.data.status));
//   }
// }

// Federation
// India squash
function* getIndiaSquash() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: FilterState,
      //   headers: {
      //     Authorization:
      //       "Bearer " + store.getState().users.UserData.session.token,
      //   },
    });
    // debugger
    yield put(getFilterStateSuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getFilterStateErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// Affiliation
function* getAffiliation() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: Affiliation,
    });
    // debugger
    yield put(getAffiliationSuccessAction(response.data));
    yield put(getAffiliationSearchAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getAffiliationErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

function* getRegisterPlayer(payload) {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: RegisteredPlayers + payload.payload,
      //   headers: {
      //     Authorization:
      //       "Bearer " + store.getState().users.UserData.session.token,
      //   },
    });
    // debugger
    yield put(getRegisterPlayerSuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getRegisterPlayerErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

function* getEvents(payload) {
  try {
    yield put(getLoaderAction(true));
    // debugger
    console.log(payload, "098");
    const response = yield axios({
      method: "get",
      url: RegisteredPlayers + payload.payload,
      //   headers: {
      //     Authorization:
      //       "Bearer " + store.getState().users.UserData.session.token,
      //   },
    });
    // debugger
    yield put(getEventsSuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getEventsErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// events
// calender
function* getCalenderYear() {
  // debugger

  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: calenderYear,
    });
    // debugger
    yield put(getCalenderYearSuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getCalenderYearErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

//calender all data
function* getCalenderFilter(payload) {
  try {
    // console.log(payload.payload.Type, "qw1");
    // if(payload.payload())
    yield put(getLoaderAction(true));
    const response1 = yield axios({
      method: "get",
      url:
        "https://api.indiasquash.com/tournament/tourweb/" +
        payload.payload.Type +
        "/" +
        payload.payload.Year +
        "/" +
        payload.payload.Category +
        "/" +
        payload.payload.Star +
        "",
    });
    // debugger
    yield put(getCalenderFilterSuccessAction(response1.data));
    yield put(getCalenderFilterSearchAction(response1.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getCalenderFilterErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response1.data.status));
  }
}

// caltourdetails
function* getCalenderTourDetails(payload) {
  if (payload.payload != "" && payload.payload != undefined) {
    try {
      yield put(getLoaderAction(true));
      console.log(payload, "bnbn");
      const response = yield axios({
        method: "get",
        url:
          tournamentDetails +
          payload.payload +
          "/" +
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiVklNQTIyMjMiLCJ1c2VybmFtZSI6IlZpbWFsIFB1cnVzaG90aGFtYW4ifQ.qsL9Za6ULBYb3XuFK74eiSxYsAy-stN8XNjghXGkeGA",
        // headers: {
        //   Authorization:
        //     "Bearer " + store.getState().users.UserData.session.token,
        // },
      });
      // debugger;
      yield put(getCalenderTourDetailsSuccessAction(response.data));
      yield put(getLoaderAction(false));
      // debugger;
    } catch (error) {
      // debugger;
      yield put(getCalenderTourDetailsErrorAction(error));
      yield put(getLoaderAction(false));
      yield put(ShowErrorAction(error.response.data.status));
    }
  }
}

// entries table
// function* getCalTourEntries(payload) {
//   console.log(payload, "zxcv");
//   try {
//     yield put(getLoaderAction(true));
//     const response = yield axios({
//       method: "get",
//       url: CalTourEntries + payload.payload,
//       // headers: {
//       //   Authorization:
//       //     "Bearer " + store.getState().users.UserData.session.token,
//       // },
//     });
//     // debugger;
//     yield put(getCalTourEntriesSuccessAction(response.data));
//     yield put(getLoaderAction(false));
//     // debugger;
//   } catch (error) {
//     // debugger;
//     yield put(getCalTourEntriesErrorAction(error));
//     yield put(getLoaderAction(false));
//     yield put(ShowErrorAction(error.response.data.status));
//   }
// }

// entries table pagniation
function* getCalTourEntriesPAGE(payload) {
  console.log(payload, "titi");
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url:
        CalTourEntriesPagination +
        payload.payload.tourid +
        "/" +
        payload.payload.type +
        "/" +
        payload.payload.page +
        "/" +
        "entries",
    });
    // debugger;
    yield put(getCalTourEntriesPAGESuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger;
  } catch (error) {
    // debugger;
    yield put(getCalTourEntriesPAGEErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// news tab
function* getNewsTab() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: newsTab,
    });
    // debugger;
    yield put(getNewsTabSuccessAction(response.data));
    yield put(getNewsSearchAction(response.data));
    yield put(getLoaderAction(false));
    // debugger;
  } catch (error) {
    // debugger;
    yield put(getNewsTabErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

function* getNewsTabPuts(payload) {
  // if (payload.payload != "" && payload.payload != undefined) {
  //   // console.log("qaws", payload);
  //   try {
  //     yield put(getLoaderAction(true));
  //     const response = yield axios({
  //       method: "get",
  //       url: newsTabPuts + payload.payload,
  //       // headers: {
  //       //   Authorization:
  //       //     "Bearer " + store.getState().users.UserData.session.token,
  //       // },
  //     });
  //     // debugger;
  //     yield put(getNewsTabPutsSuccessAction(response.data));
  //     yield put(getLoaderAction(false));
  //     // debugger;
  //   } catch (error) {
  //     // debugger;
  //     yield put(getNewsTabPutsErrorAction(error));
  //     yield put(getLoaderAction(false));
  //     yield put(ShowErrorAction(error.response.data.status));
  //   }
  // }
}

// function* postNewsTabs(payload) {
//   console.log(payload,"post")
//   try {
//     yield put(getLoaderAction(true));
//     const response = yield axios({
//       method: "post",
//       url: newspostapi ,
//       data: payload.payload,
//     });
//     debugger;
//     yield put(postNewsTabsSuccessAction(response.data));
//     yield put(getLoaderAction(false));
//     debugger;
//   } catch (error) {
//     debugger;
//     yield put(postNewsTabsErrorAction(error));
//     yield put(getLoaderAction(false));
//     yield put(ShowErrorAction(error.response.data.status));
//   }
// }

function* postNewsTabs(payload) {
  // console.log(payload, "posting");
  // console.log("formData before", payload.payload.formData);

  try {
    yield put(getLoaderAction(true));
    const formData = new FormData();
    // Append your payload data to the formData object
    for (const key in payload.payload.formData) {
      formData.append(key, payload.payload.formData[key]);
    }

    // Append each type value individually to the formData object
    formData.append("type", payload.payload.type);

    const response = yield axios({
      method: "post",
      url: newspostapi,
      data: formData, // Use the formData object as the data
      headers: {
        "Content-Type": "multipart/form-data", // Set the content type to FormData
      },
    });
    console.log(response.data, "upppp");
    yield put(postNewsTabsSuccessAction(response.data));
    console.log(response.data, "down");
    yield put(postNewsTabsSearchAction(response.data));
    yield put(getLoaderAction(false));
    // debugger;
  } catch (error) {
    // debugger;
    yield put(postNewsTabsErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// squash time
function* getSquashTime() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: squashTime,
    });
    // debugger;
    yield put(getSquashTimeSuccessAction(response.data));
    yield put(getSquashTimeSearchAction(response.data));
    yield put(getLoaderAction(false));
    // debugger;
  } catch (error) {
    // debugger;
    yield put(getSquashTimeErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// sathik
//Teamplayers AE

// //Teamplayers ALL
function* getALLPlayers() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: getALLPlayersURL,
    });
    yield put(getALLPlayersSuccess(response.data.data));
    yield put(getLoaderAction(false));

    yield put(getALLPlayersSearch(response.data.data));
  } catch (error) {
    console.log("errors sdf", error);
    yield put(getALLPlayersError(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

function* getAEPlayers() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: getAEPlayersURL,
    });
    yield put(getAEPlayersSuccess(response.data.data));
    yield put(getLoaderAction(false));

    yield put(getAEPlayersSearch(response.data.data));
  } catch (error) {
    console.log("errors sdf", error);
    yield put(getAEPlayersError(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// //Teamplayers WE
function* getWEPlayers() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: getWEPlayersURL,
    });
    yield put(getWEPlayersSuccess(response.data.data));
    yield put(getLoaderAction(false));

    yield put(getWEPlayersSearch(response.data.data));
  } catch (error) {
    console.log("errors sdf", error);
    yield put(getWEPlayersError(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

//Teamplayers AE
// function* getAEPlayers() {
//   try {
//     yield put(getLoaderAction(true));
//     const response = yield axios({
//       method: "get",
//       url: getAEPlayersURL,
//     });
//     yield put(getLoaderAction(false));
//     yield put(getAEPlayersSuccess(response.data.data));
//     // yield put(getAEPlayersAction(response.data.data));
//   } catch (error) {
//     yield put(getLoaderAction(false));
//     console.log("errors sdf", error);
//   }
// }

//Teamplayers ALL
// function* getALLPlayers() {
//   try {
//     const response = yield axios({
//       method: "get",
//       url: getALLPlayersURL,
//     });
//     yield put(getLoaderAction(false));
//     yield put(getALLPlayersSuccess(response.data.data));
//   } catch (error) {
//     yield put(getLoaderAction(false));
//     console.log("errors sdf", error);
//   }
// }

//Teamplayers WE
// function* getWEPlayers() {
//   try {
//     yield put(getLoaderAction(true));
//     const response = yield axios({
//       method: "get",
//       url: getWEPlayersURL,
//     });
//     yield put(getLoaderAction(false));
//     yield put(getWEPlayersSuccess(response.data.data));
//   } catch (error) {
//     yield put(getLoaderAction(false));
//     console.log("errors sdf", error);
//   }
// }

//Ranking----------------
//Years---------------------------

// -------------Juniors--------------------
function* getjunior(payloads) {
  yield put(getLoaderAction(true));
  try {
    const { Years, Months, Budata, pagings, type, search } = payloads.payload;
    console.log(payloads, "sfs akbk junior sga middlewae");
    const selectedBudata = Budata || "BU19";
    const PGJUN = pagings || "1";
    const year = Years;
    const months = Months;

    const YMB =
      year +
      "/" +
      months +
      "/" +
      selectedBudata +
      "/" +
      PGJUN +
      "/" +
      type +
      "?";
    if (Budata != undefined && year != undefined && months != undefined) {
      const response = yield axios({
        method: "get",
        url: JuniorBU19 + YMB,
        params: {
          word: search,
        },
      });
      // console.log(JuniorBU19+YMB,"url");
      yield put(getRankingsJuniorSuccess(response.data.output));
      yield put(getLoaderAction(false));
      yield put(setPagin(response.data.total_page));
    }
  } catch (error) {
    console.log(error, "from junior get api");
    yield put(getLoaderAction(false));
  }
}
// -------------------Seniors--------------------
function* getSeniorData(payloads) {
  yield put(getLoaderAction(true));
  try {
    const { Years, Months, SeniorMW, pagings, type, search } = payloads.payload;
    const selectedMEN = SeniorMW || "Men";
    const pagingsSE = pagings || "1";
    const year = Years;
    const months = Months;
    const SeniorAPi =
      year +
      "/" +
      months +
      "/" +
      selectedMEN +
      "/" +
      pagingsSE +
      "/" +
      type +
      "?";

    const response = yield axios({
      method: "get",
      url: SeniorsMW + SeniorAPi,
      params: {
        word: search,
      },
    });
    // const d = SeniorsMW+SeniorAPi;
    // console.log(d,"ewfds");
    yield put(getSeniorSuccessAction(response.data.output));
    yield put(getLoaderAction(false));

    yield put(setSENIORPAGE(response.data.total_page));
    //  console.log(response.data,"senior");
  } catch (error) {
    yield put(getLoaderAction(false));
    console.log(error, "error form seniors sides");
  }
}

// ------------------MASTER--------------------
function* getMasterData(payloads) {
  try {
    yield put(getLoaderAction(true));
    const { Years, Months, Masters, pagings, type, search } = payloads.payload;
    const selectedMaster = Masters || "MO35";
    const MasterPage = pagings || "1";
    const MasterAPI =
      Years +
      "/" +
      Months +
      "/" +
      selectedMaster +
      "/" +
      MasterPage +
      "/" +
      type +
      "?";
    // var urlll = masterAPI +MasterAPI;
    const response = yield axios({
      method: "get",
      url: masterAPI + MasterAPI,
      params: {
        word: search,
      },
    });
    //  console.log(urlll ,"master");
    yield put(getMasterSuccessAction(response.data.output));
    yield put(getLoaderAction(false));
    yield put(setTotalPageMASTER(response.data.total_page));
    //  console.log(response.data.total_page,"master page numbers");
  } catch (error) {
    // console.log(urlll ,"master2122");
    console.log(error, "error form Master api");
    yield put(getLoaderAction(false));
  }
}

// ------------------PROCOACH--------------------
function* getProCoach(payloads) {
  yield put(getLoaderAction(true));
  try {
    const { Years, Months, pagings, type, search } = payloads.payload;

    const pageNO = pagings || "1";

    const ProcoachAPi = Years + "/" + Months + "/" + pageNO + "/" + type + "?";
    // var urlss = ProCoach+ProcoachAPi;
    // console.log(urlss,"ProCoach_URLS");
    const response = yield axios({
      method: "get",
      url: ProCoach + ProcoachAPi,
      params: {
        word: search,
      },
    });
    // console.log(urlss,"ProCoach_URLS");
    yield put(getProcoachSuccessAction(response.data.output));
    yield put(getLoaderAction(false));
    yield put(setPaginPRO(response.data.total_page));
    //  console.log(response.data,"pro ");
  } catch (error) {
    yield put(getLoaderAction(false));
    console.log(error, "error form proCoach sides");
  }
}

// Election
function* getElectionsPdf() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: Elections,
    });
    yield put(getElectionsPdfSuccessAction(response.data.data));
    yield put(getLoaderAction(false));
    console.log(response.data.data, "sdss");
  } catch (error) {
    yield put(getElectionsPdfErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// agm
function* getAgmPdf() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: Agm,
    });
    yield put(getAgmPdfSuccessAction(response.data.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getAgmPdfErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// Ros
function* getRosPdf() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: RegistereofSociety,
    });
    yield put(getRosPdfSuccessAction(response.data.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getRosPdfErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// bs
function* getBalSheetPdf() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: BalanceSheet,
    });
    yield put(getBalSheetPdfSuccessAction(response.data.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getBalSheetPdfErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// -------------INDIASQUSHTV--------------------------------
function* getIndiasqaushVd() {
  try {
    yield put(getLoaderAction(true));
    const response = yield axios({
      method: "get",
      url: IndiaSqushVideo,
    });
    yield put(getSquashSuccessAction(response.data.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getSquashErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

function* getSearchDATA(payloads) {
  try {
    yield put(getLoaderAction(true));
    console.log(payloads, "paaaas");
    const { search, typeOF } = payloads.payload;
    console.log(search, "ii");
    //  var uu = "?"+search;
    //  console.log(uu,"uuuuuuuuuu");
    const response = yield axios({
      method: "get",
      url: +"?" + search,
    });
    if (typeOF == "JUNIOR") {
      console.log("esdfcx");
      yield put(getRankingsJuniorSuccess(response.data.data));
    }
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getSquashErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// -----------Selection----PDFSS--------
function* getSelectionpdf() {
  try {
    yield put(getLoaderAction(true));
    const respone = yield axios({
      method: "get",
      url: SelctionPDF,
    });
    yield put(getSelectionActionSuccess(respone.data.data));
    yield put(getLoaderAction(false));
  } catch (error) {
    yield put(getSelectionErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// Social media
function* getSocialMedia() {
  try {
    yield put(getLoaderAction(true));
    // debugger
    const response = yield axios({
      method: "get",
      url: SocialMedia,
    });
    // debugger
    yield put(getSocialMediaSuccessAction(response.data));
    yield put(getLoaderAction(false));
    // debugger
  } catch (error) {
    // debugger
    yield put(getSocialMediaErrorAction(error));
    yield put(getLoaderAction(false));
    yield put(ShowErrorAction(error.response.data.status));
  }
}

// Generator function
function* watchGetUser() {
  yield takeLatest(
    sagaActions.FETCH_BANNER_DATA + "/getBannerAction",
    getBannerData
  );

  yield takeLatest(
    sagaActions.FETCH_UPCOMING_MATCH_DATA + "/getUpcomingMatchAction",
    getUpcomingMatchData
  );

  yield takeLatest(
    sagaActions.FETCH_TOP_RANK_DATA + "/getTopRankAction",
    getTopRankData
  );

  yield takeLatest(
    sagaActions.FETCH_LATEST_RESULTS_DATA + "/getResultsAction",
    getResultsData
  );

  yield takeLatest(
    sagaActions.FETCH_LATEST_NEWS_DATA + "/getNewsAction",
    getNewsData
  );

  yield takeLatest(
    sagaActions.FETCH_LATEST_VIDEOS_DATA + "/getVideosAction",
    getVideosData
  );

  // yield takeLatest(
  //   sagaActions.FETCH_MASTERPRO_TOPRANK_DATA + "/getMasterProTopRankAction",
  //   getMasterProTopRank
  // );

  yield takeLatest(
    sagaActions.FETCH_FILTERSTATE_DATA + "/getFilterStateAction",
    getIndiaSquash
  );
  yield takeLatest(
    sagaActions.FETCH_AFFILIATION_DATA + "/getAffiliationAction",
    getAffiliation
  );

  yield takeLatest(
    sagaActions.FETCH_REGISTER_PLAYER_DATA + "/getRegisterPlayerAction",
    getRegisterPlayer
  );
  yield takeLatest(
    sagaActions.FETCH_EVENTS_DATA + "/getEventsAction",
    getEvents
  );

  yield takeLatest(
    sagaActions.FETCH_CALENDER_YEAR_DATA + "/getCalenderYearAction",
    getCalenderYear
  );
  yield takeLatest(
    sagaActions.FETCH_CALENDER_FILTER_DATA + "/getCalenderFilterAction",
    getCalenderFilter
  );
  yield takeLatest(
    sagaActions.FETCH_CALENDER_TOUR_DETAILS_DATA +
      "/getCalenderTourDetailsAction",
    getCalenderTourDetails
  );

  // yield takeLatest(
  //   sagaActions.FETCH_CAL_TOUR_ENTRIES_DATA + "/getCalTourEntriesAction",
  //   getCalTourEntries
  // );

  yield takeLatest(
    sagaActions.FETCH_CAL_TOUR_ENTRIES_PAGE_DATA +
      "/getCalTourEntriesPAGEAction",
    getCalTourEntriesPAGE
  );

  yield takeLatest(
    sagaActions.FETCH_NEWS_TAB_DATA + "/getNewsTabAction",
    getNewsTab
  );

  yield takeLatest(
    sagaActions.FETCH_NEWS_TABPUTS_DATA + "/getNewsTabPutsAction",
    getNewsTabPuts
  );

  yield takeLatest(
    sagaActions.FETCH_POST_NEWS_TABS_DATA + "/postNewsTabsAction",
    postNewsTabs
  );

  yield takeLatest(
    sagaActions.FETCH_SQUASHTIME + "/getSquashTimeAction",
    getSquashTime
  );

  // PLAYERS

  yield takeLatest(
    sagaActions.FETCH_TEAMINDIA_PLAYERS + "/getAEPlayersAction",
    getAEPlayers
  );
  yield takeLatest(
    sagaActions.FETCH_TEAMINDIA_PLAYERS + "/getALLPlayersAction",
    getALLPlayers
  );
  yield takeLatest(
    sagaActions.FETCH_TEAMINDIA_PLAYERS + "/getWEPlayersAction",
    getWEPlayers
  );
  // ---------Ranking--------------------
  yield takeLatest(
    sagaActions.FETCH_RANKIGN_TOPERS + "/getRankingJuniorAction",
    getjunior
  );
  // -------------Seniors--------------------
  yield takeLatest(
    sagaActions.FETCH_RANKIGN_TOPERS_SENIOR + "/getSeniorRankAction",
    getSeniorData
  );
  yield takeLatest(
    sagaActions.FETCH_RANKING_TOPERS_MASTER + "/getmasterRankAction",
    getMasterData
  );
  yield takeLatest(
    sagaActions.FETCH_RANKING_TOPERS_PRCOACH + "/getProcoachRankAction",
    getProCoach
  );
  // -------INDIASQUASHTV-----------------------------
  yield takeLatest(
    sagaActions.FETCH_INDIASQUASHTV_VIDEO + "/getSquashAction",
    getIndiasqaushVd
  );

  // election
  yield takeLatest(
    sagaActions.FETCH_ELECTION_PDF + "/getElectionsPdfAction",
    getElectionsPdf
  );
  // agm
  yield takeLatest(sagaActions.FETCH_AGM_PDF + "/getAgmPdfAction", getAgmPdf);

  // ros
  yield takeLatest(sagaActions.FETCH_ROS_PDF + "/getRosPdfAction", getRosPdf);

  //  bal sheet
  yield takeLatest(
    sagaActions.FETCH_BALSHEET_PDF + "/getBalSheetPdfAction",
    getBalSheetPdf
  );

  yield takeLatest(
    sagaActions.FETCH_SEARCH_API_RANKING + "/getSearchAction",
    getSearchDATA
  );
  yield takeLatest(
    sagaActions.FETCH_SELECTION_PDFS_PARTS + "/getSelectionAction",
    getSelectionpdf
  );

  yield takeLatest(
    sagaActions.FETCH_SOCIAL_MEDIA_DATA + "/getSocialMediaAction",
    getSocialMedia
  );
}

export default watchGetUser;
