import React, { useState, useEffect } from "react";
import "../../../Stylesheets/MainComponent/SeniorRanking.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getTopRankAction,
  getTopRankSuccessAction,
  getTopRankErrorAction,
} from "../../../Redux/Slice";

import srfinew from "../../../assets/Images/srfinew.jpg";

const SeniorRanking = () => {
  const dispatch = useDispatch();

  const [topRank, setTopRank] = useState([]);
  const [topRank2, setTopRank2] = useState([]);

  const toprankfulldata = useSelector((state) => state.TopRankList);
  console.log(toprankfulldata, "top rank");

  // top rank
  // useEffect(() => {
  //   dispatch(getTopRankAction());
  // }, []);

  useEffect(() => {
    if (toprankfulldata && toprankfulldata.TopRankData.recentone) {
      const menData = toprankfulldata.TopRankData.recentone[0].boys;
      setTopRank(menData);
      console.log(menData, "top rank1 mens");

      const womenData = toprankfulldata.TopRankData.recentone[0].girls;
      setTopRank2(womenData);
      console.log(womenData, "top rank1 womens");
    }
  }, [toprankfulldata]);

  var s3url3 = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  return (
    <div className="senior-ranking-container">
      <div className="ranking-inner-wrapper">
        <button className="senior-btn">SENIOR</button>
        <div className="container-fluid cont-wrap">
          <div className="row">
            <div className="col-md-6 men">
              <div className="btn-wrap">
                <button className="men-btn">MEN</button>
              </div>
              <div className="men-container">
                {/* {topRank &&
                  topRank.map((firstrank, index) => (
                    <div class="card-cont" key={index}>
                      <div className="profile-details">
                        <img
                          src={s3url3 + firstrank.PlayerImage}
                          class="card-img-top"
                          alt="player profile"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://demowhats.s3.ap-south-1.amazonaws.com/media/image/Nh9v68nB8simages.png";
                          }}
                        />

                        <div className="name-venue">
                          <span className="player-name">
                            {firstrank.PlayerName}
                          </span>
                          <span className="player-location">
                            {firstrank.State}
                          </span>
                        </div>
                      </div>

                      <div className="stats-details">
                        <div className="cat-poit">
                          <span className="category">{firstrank.Category}</span>
                          <span className="points">{firstrank.Points}</span>
                        </div>

                        <button className="rank-cont">
                        </button>
                      </div>
                    </div>
                  ))} */}

                {topRank && (
                  <div className="card-cont">
                    <div className="profile-details">
                      <img
                        src={s3url3 + topRank.PlayerImage}
                        className="card-img-top"
                        alt="player profile"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =srfinew
                        }}
                      />
                      <div className="name-venue">
                        <span className="player-name">
                          {topRank.PlayerName}
                        </span>
                        <span className="player-location">{topRank.State}</span>
                      </div>
                    </div>
                    <div className="stats-details">
                      <div className="cat-poit">
                        <span className="category">{topRank.Category}</span>
                        <span className="points">{topRank.Points}
                        &nbsp;<span>PTS</span>
                        </span>
                      </div>
                      <button className="rank-cont">
                        <span className="rank">1<sup>st</sup></span>
                      </button>
                    </div>
                  </div>
                )}

          
              </div>
            </div>

            <div className="col-md-6 women">
              <div className="btn-wrap">
                <button className="women-btn">WOMEN</button>
              </div>
              <div className="women-container">
                {topRank2 && (
                  <div className="card-cont">
                    <div className="profile-details">
                      <img
                        src={s3url3 + topRank2.PlayerImage}
                        className="card-img-top"
                        alt="player profile"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =srfinew
                        }}
                      />
                      <div className="name-venue">
                        <span className="player-name">
                          {topRank2.PlayerName}
                        </span>
                        <span className="player-location">
                          {topRank2.State}
                        </span>
                      </div>
                    </div>
                    <div className="stats-details">
                      <div className="cat-poit">
                        <span className="category">{topRank2.Category}</span>
                        <span className="points">
                          {topRank2.Points}
                          &nbsp;<span>PTS</span>
                        </span>
                      </div>
                      <button className="rank-cont">
                        <span className="rank">
                          1<sup>st</sup>
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeniorRanking;
