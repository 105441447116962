import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "../../../Stylesheets/MainComponent/Federations/Affiliation.scss";
import pdfimg from "../../../assets/Images/pdfimg.png";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

import { getAffiliationAction, } from "../../../Redux/Slice";
import scroll from "../../../assets/Images/scrollup.png";


const Affiliation = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  
 
  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("tab1");

  const [government, setGovernment] = useState([]);
  const [world, setWorld] = useState([]);
  const [asian, setAsian] = useState([]);

  const affiliationfulldata = useSelector((state) => state.AffiliationList.AffiliationData);
  // console.log(affiliationfulldata, "afff")

  useEffect(() => {
    dispatch(getAffiliationAction());
  }, []);

  useEffect(() => {
    // Check if the data exists and if "Government" key exists in the data
    if (affiliationfulldata && affiliationfulldata.data && affiliationfulldata.data[0].Government) {
      setGovernment(affiliationfulldata.data[0].Government);
      // console.log(affiliationfulldata.data[0].Government, "govt");
    }

    if (affiliationfulldata && affiliationfulldata.data && affiliationfulldata.data[0].asf) {
      setAsian(affiliationfulldata.data[0].asf);
      // console.log(affiliationfulldata.data[0].asf, "asf");
    }

    if (affiliationfulldata && affiliationfulldata.data && affiliationfulldata.data[0].wsf) {
      setWorld(affiliationfulldata.data[0].wsf);
      // console.log(affiliationfulldata.data[0].wsf, "wsf");
    }

  }, [affiliationfulldata]);


  const handleTabClick = (tabname) => {
    setActiveTab(tabname);
  };

  // function myFunction() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/srfi_affliction_menu/buttonpdf/info.pdf"
  //   );
  // }

  return (
    <div className="affiliation-main-wrapper">
      {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
            <img src={scroll} className="scrollup"></img>
          </button>
        )}
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
      
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers-fluid affiliation-inner">
          <div class="title shimmer">
            <h4>
              <b>AFFILIATION</b>
            </h4>
          </div>

          <div className="documnets-container">
            {/* <div class="consolidate-wrapper">
            <button class="consolidate-btn" onClick={myFunction}>
              Click to view consolidated details of federation as per Government
              of India
            </button>
          </div> */}

            <div className="inner-tabs">
              <div className="subnavbar">
                <div
                  className={`tab ${
                    activeTab === "tab1" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={() => handleTabClick("tab1")}
                  >
                    Government of India
                  </button>
                </div>
                <div
                  className={`tab ${
                    activeTab === "tab2" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={() => handleTabClick("tab2")}
                  >
                    World Squash Federation
                  </button>
                </div>
                <div
                  className={`tab ${
                    activeTab === "tab3" ? "active-tab-line" : ""
                  }`}
                >
                  <button
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={() => handleTabClick("tab3")}
                  >
                    Asian Squash Federation
                  </button>
                </div>
              </div>

              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div class="row govt-wrap">
                    {government.map((items) => {
                      return (
                        <>
                          <div className="col-sm-6 col-md-6 col-lg-3 card-container-aff">
                            <a
                              href={"https://demowhats.s3.ap-south-1.amazonaws.com/"  + items.filefield}
                              target="_blank"
                              className="navigate-image"
                              rel="noreferrer"
                            >
                              <div class="card">
                                <img
                                  src={pdfimg}
                                  alt="pdf-front"
                                  className="pdf-image"
                                />

                                <div class="card-body">
                                  <h6 class="card-text">{items.title}</h6>
                                </div>
                              </div>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div class="row govt-wrap">
                    {world.map((items) => {
                      return (
                        <>
                          <div className="col-sm-6 col-md-6 col-lg-3 card-container-aff">
                            <a
                              href={"https://demowhats.s3.ap-south-1.amazonaws.com/"  + items.filefield}
                              target="_blank"
                              className="navigate-image"
                              rel="noreferrer"
                            >
                              <div class="card">
                                <img
                                  src={pdfimg}
                                  alt="pdf-front"
                                  className="pdf-image"
                                />
                                <div class="card-body">
                                  <h6 class="card-text">{items.title}</h6>
                                </div>
                              </div>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div class="row govt-wrap">
                    {asian.map((items) => {
                      return (
                        <>
                          <div className="col-sm-6 col-md-6 col-lg-3 card-container-aff">
                            <a
                              href={"https://demowhats.s3.ap-south-1.amazonaws.com/"  + items.filefield}
                              target="_blank"
                              className="navigate-image"
                              rel="noreferrer"
                            >
                              <div class="card">
                                <img
                                  src={pdfimg}
                                  alt="pdf-front"
                                  className="pdf-image"
                                />

                                <div class="card-body">
                                  <h6 class="card-text">{items.title}</h6>
                                </div>
                              </div>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

<SocialMedia />

<Footer />
    </div>
  );
};

export default Affiliation;
