import React from "react";
import "../../Stylesheets/AppComponents/PowerBanner.scss";
import srfilogo from "../../assets/Images/SRFI-WEB-LOGO.png";
import playstore from "../../assets/Images/play-store.png";
import appstore from "../../assets/Images/app-store.png";
import codoidlogo from "../../assets/Images/codoid-logo.png";

const PowerBanner = () => {
  return (
    <div className="srfi-official-banner-wrap">
      <div className="container-fluid inner-wrap">
        <div className="row">
          <div className="col-md-6 col-12 srfi-box">
            <div className="srfi-cont">
              <img src={srfilogo} className="srfifull-logo"></img>
              <h5 className="srfi-official">THE SRFI OFFICIAL APP</h5>
            </div>
          </div>

          <div className="col-md-6 col-12 codoid-box">
            <div className="codoid-cont">
              <div className="app-play-store">
                <a
                  href="https://play.google.com/store/search?q=srfi+app&c=apps&hl=en-IN"
                  target="_blank"
                >
                  <button className="google-btn">
                  <img src={playstore} className="dowloadapp-logo"></img>
                  </button>
                </a>

                <a
                  href="https://apps.apple.com/in/app/srfi/id1575674629"
                  target="_blank"
                >
                  <button className="app-btn">
                  <img src={appstore} className="dowloadapp-logo"></img>
                  </button>
                </a>
              </div>

              <div className="power-codoid">
                <h4 className="powerdby">POWERED BY</h4>
                <img src={codoidlogo} className="codoid-logo"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerBanner;
