import React, { useEffect, useMemo, useState } from "react";
import "../../../Stylesheets/MainComponent/Federations/OfficeBeares.scss";
import office1 from "../../../assets/Images/Office/AnilWadhwa.jpg";
import office2 from "../../../assets/Images/Office/HARISH_KUMAR.jpg";
import office3 from "../../../assets/Images/Office/CC_20210504_151228.png";
import email from "../../../assets/Images/Office/email.png";
import phone from "../../../assets/Images/Office/phone.png";

import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import scroll from "../../../assets/Images/scrollup.png";

const OfficeBeares = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  
 
  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  

  return (
    <div className="office-beares-main-wrapper">
      {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
            <img src={scroll} className="scrollup"></img>
          </button>
        )}
       <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

      <div className="office-beares-inner">
        <div class="title shimmer">
          <h4>
            <b>OFFICE BEARERS</b>
          </h4>
        </div>

        <div className="ggg">
          <div className="container container-flu">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 cardwrap">
                <div className="card card-one">
                  <div className="blur-img-div"></div>
                  <img src={office1} className="rounded-image" alt="roundpic" />
                  <div className="card-body">
                    <div className="bcaption">
                      <h5>ANIL WADHWA</h5>
                      <p>INDIAN FOREIGN SERVICE (Retd)</p>
                      <p className="preistdent">President</p>
                      {/* <div className="officetag">
                        <div className="tournament-category">
                          <img
                            src={email}
                            className="category-img"
                            alt="Email"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            squashsecretariate@gmail.com
                          </span>
                        </div>
                        <div className="tournament-category">
                          <img
                            src={phone}
                            className="category-img"
                            alt="Phone"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            9841039253
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="card-footer">
                  <div className="officetag">
                        <div className="tournament-category">
                          <img
                            src={email}
                            className="category-img"
                            alt="Email"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            squashsecretariate@gmail.com
                          </span>
                        </div>
                        <div className="tournament-category">
                          <img
                            src={phone}
                            className="category-img"
                            alt="Phone"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            9841039253
                          </span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-4 cardwrap">
                <div className="card card-one">
                  <div className="blur-img-div"></div>
                  <img src={office3} className="rounded-image" alt="roundpic" />
                  <div className="card-body">
                    <div className="bcaption">
                      <h5>CYRUS PONCHA</h5>
                      {/* <p>INDIAN FOREIGN SERVICE (Retd)</p> */}
                      <p className="preistdent">Secretary General</p>
                      <br />
                      {/* <div className="officetag">
                        <div className="tournament-category">
                          <img
                            src={email}
                            className="category-img"
                            alt="Email"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            cponcha@indiasquash.com
                          </span>
                        </div>
                        <div className="tournament-category">
                          <img
                            src={phone}
                            className="category-img"
                            alt="Phone"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            9940050510
                          </span>
                        </div>
                      </div> */}
                      
                    </div>
                  </div>
                  <div className="card-footer">
                      <div className="officetag">
                        <div className="tournament-category">
                          <img
                            src={email}
                            className="category-img"
                            alt="Email"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            cponcha@indiasquash.com
                          </span>
                        </div>
                        <div className="tournament-category">
                          <img
                            src={phone}
                            className="category-img"
                            alt="Phone"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            9940050510
                          </span>
                        </div>
                      </div>
                      </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-4 cardwrap">
                <div className="card card-one">
                  <div className="blur-img-div"></div>
                  <img src={office2} className="rounded-image" alt="roundpic" />
                  <div className="card-body">
                    <div className="bcaption">
                      <h5>K. HARISH KUMAR</h5>
                      {/* <p>INDIAN FOREIGN SERVICE (Retd)</p> */}
                      <p className="preistdent">Treasurer</p>
                      <br />

                      {/* <div className="officetag">
                        <div className="tournament-category">
                          <img
                            src={email}
                            className="category-img"
                            alt="Email"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            srkharish@gmail.com
                          </span>
                        </div>
                        <div className="tournament-category">
                          <img
                            src={phone}
                            className="category-img"
                            alt="Phone"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            9965475596
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="card-footer">
                  <div className="officetag">
                        <div className="tournament-category">
                          <img
                            src={email}
                            className="category-img"
                            alt="Email"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            srkharish@gmail.com
                          </span>
                        </div>
                        <div className="tournament-category">
                          <img
                            src={phone}
                            className="category-img"
                            alt="Phone"
                          />
                          <span className="card-subtitle2 mb-2 text-muted">
                            9965475596
                          </span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div className="more-details">
                <div className="additional-content">
                  <h6>VICE PRESIDENT</h6>
                  <ul>
                    <li>Mrs. Anjali Maya Eashwar Narayanaswamy</li>
                    <li>Ms. Surbhi Misra</li>
                  </ul>
                </div>

                <div className="additional-content">
                  <h6>JOINT SECRETARY</h6>
                  <ul>
                    <li>Ms.Bhuvaneshwari Kumari</li>
                    <li>Rahul Pravindra</li>
                  </ul>
                </div>

                <div className="additional-content">
                  <h6>EXECUTIVE COMMITTEE MEMBERS</h6>
                  <ul>
                    <li>Amit Dabas</li>
                    <li>Shakti Shrivatsava</li>
                    <li>Ankur Dalal</li>
                    <li>Ms.Meenu Bajpai</li>
                    <li>Chetan Dayanand Amin</li>
                    <li>Varun Reddy</li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default OfficeBeares;
