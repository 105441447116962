import React, { useEffect, useState } from "react";
import "../../../Stylesheets/MainComponent/Federations/BalanceSheets.scss";
import scroll from "../../../assets/Images/scrollup.png";

import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

import { getBalSheetPdfAction } from "../../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";

const BalanceSheet = () => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [bal, setbal] = useState([]);

  const balsheetpdffulldata = useSelector(
    (state) => state.BalSheetPdfList.BalSheetPdf
  );
  // console.log(balsheetpdffulldata, "bsbs");

  useEffect(() => {
    dispatch(getBalSheetPdfAction());
  }, []);

  useEffect(() => {
    if (balsheetpdffulldata) {
      setbal(balsheetpdffulldata); // Check if balsheetpdffulldata is defined and access 'RosPdf'
      // console.log(balsheetpdffulldata, "bababa");
    }
  }, [balsheetpdffulldata]);

  const openPdf = (url) => {
    // console.log("url:", url); // Check the value of url
    window.open(url);
  };

  return (
    <div className="balance-main-wrapper">
      {isVisible && (
        <button id="scrollToTopButton" onClick={scrollToTop}>
          <img src={scroll} className="scrollup"></img>
        </button>
      )}
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers balance-inner">
          <div class="title shimmer">
            <h4>
              <b>BALANCE SHEET</b>
            </h4>
          </div>

          <div className="balance-btns-wrapper">
            <div className="accordion" id="accordionExample">
              {bal.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <button
                        className="inner-btn-amg"
                        onClick={() =>
                          openPdf(
                            "https://demowhats.s3.ap-south-1.amazonaws.com/" +
                              item.filefield
                          )
                        } // Use item.filefield
                      >
                        {item.title}.pdf
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default BalanceSheet;
