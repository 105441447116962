const baseurl="https://api.indiasquash.com/";
// const baseurl = "http://205.147.97.85:8000/";

// banner
export const Banner =  baseurl + "api/bannerimage/";

// Upcoming Matches
export const UpcomingMatch = baseurl + "tournament/tour/";

// TOP Rank jun-sen
export const TopRank = baseurl + "rank/toprank/";

// TOP Rank mas-pro
export const MasterProTopRank = baseurl + "rank/toprank/";


// Results
export const LatestResults = baseurl + "api/result/";

// Homepage News
export const HomepageNews = baseurl + "resource/homepagenews/";

// Latest Video
export const LatestVideos = baseurl + "squashtvadminget/3";


// Federation
// Affiliation
export const Affiliation = baseurl + "srfi/getpdfaffliction/";

// state units
export const FilterState = baseurl + "tournament/filterdstate/";
export const RegisteredPlayers = baseurl + "tournament/stateunits/";




// election
export const Elections = baseurl + "pdfuploadss/elections/";
// agm
export const Agm = baseurl + "pdfuploadss/Agm/";
// register of society
export const RegistereofSociety = baseurl + "pdfuploadss/Registrar/"
// balance sheet
export const BalanceSheet = baseurl + "pdfuploadss/Balsheet/";


// Calender
export const calenderYear = baseurl + "tournament/year/";
// export const calenderFilter = baseurl + "tournament/tourweb/AL/2023/AL/AL";

export const tournamentDetails = baseurl + "tournament/details/"


// CALENDER ENTRIES
// export const CalTourEntries = baseurl + "tournament/players/571/AL?_=1694464827980"

// pagination
export const CalTourEntriesPagination = baseurl + "tournament/playerpaginate/";


// all news
export const allNews = baseurl + "resource/homepagenews/";
export const newsTab = baseurl + "newsupload/";
// export const newsTabPuts = baseurl + "newsuploadputs/";
export const newspostapi = baseurl + "newsuploadtype/";


// squash time
export const squashTime = baseurl + "resource/squashtime/";


// PLAYERS
//TEAM-INDIA

export const getAEPlayersURL = baseurl + "about/teamindiaapi/AE";

//TEAM-INDIA-ALL
export const getALLPlayersURL = baseurl + "about/teamindiaapi/All";

//TEAM-INDIA-AWE
export const getWEPlayersURL = baseurl + "about/teamindiaapi/WE";



//Ranking-list----------------
// ---------------------------Juniors--------------------
export const JuniorBU19 = baseurl + "rank/juniorrankpaginate/";
// -------------------------Seniors------------------------------------
export const SeniorsMW= baseurl + "rank/seniorrankpaginate/"

export const masterAPI=baseurl + "rank/masterrankpaginate/";

export const ProCoach =baseurl + "rank/prorankpaginate/";

export const IndiaSqushVideo = baseurl + "squashtvadminget/3";


// Committees
export const Committees = baseurl + "srfi/Comittess"

export const SelctionPDF=  baseurl + "pdfuploadss/Selection/";



// social media
export const SocialMedia = baseurl + "tournament/socialmedia/";