import React, { useEffect, useState } from "react";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { getSelectionAction } from "../../../Redux/Slice";
import "../../../Stylesheets/MainComponent/Players/Selection.scss";
const SelectionCriteria = () => {
  const dispatch = useDispatch();

  const [selectionCriteria, setSelectionCriteria] = useState([]);

  console.log(selectionCriteria, "1q1q");
  const [normalPDFs, setNormalPDFs] = useState([]);

  const selectionPDFSc = useSelector(
    (state) => state.SelectionDATAs.SelectionDATA
  );
  console.log(selectionPDFSc, "dfs");

  
  useEffect(() => {
    dispatch(getSelectionAction());
  }, []);

  useEffect(() => {
    if (selectionPDFSc != undefined) {
      const pdfWithTitles = selectionPDFSc.filter((item) => item.title != null);
      const pdfsWithoutTotle = selectionPDFSc.filter(
        (item) => item.title === null
      );
      setSelectionCriteria(pdfWithTitles);
      setNormalPDFs(pdfsWithoutTotle);
    }
  }, [selectionPDFSc]);

  const openPdf = (url) => {
    console.log("url:", url);
    window.open(url);
  };

  return (
    <>
      <div className="container-fluid SELECTION-PART1">
        <Navbar />
        <div className="container-fluid SELECTION_inner_wrapper ">
          <div className="navbar2-container-1">
            <Navbar2 />
          </div>

          <div className="text-justify content-SELECTION1  ">
            <h4 className="HeadingTAB my-4">
              <b>SELECTION CRITERIA</b>
            </h4>

            <div id="Selection-ContentACCORDION">
              {/* <div> */}

              <div className="accordion" id="accordionExample">
                {selectionCriteria.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <button
                          className="inner-btn-amg"
                          onClick={() =>
                            openPdf(
                              "https://demowhats.s3.ap-south-1.amazonaws.com/" +
                                item.filefield
                            )
                          }
                        >
                          {item.title}.pdf
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* normal pdf */}
              <div className="normalpdfs">
                {normalPDFs.map((item, index) => (
                  <div
                    className="card HOVERCARDS normalpdf"
                    key={index}
                    id="BATCH-BASED"
                    onClick={() =>
                      openPdf(
                        "https://demowhats.s3.ap-south-1.amazonaws.com/" +
                          item.filefield
                      )
                    }
                  >
                    <div class="card-body NORMAL-CONTENT-PDF">
                      <div className="card-tittle fontSTYLES">
                        {item.subtitle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* </div> */}
            </div>
          </div>

          <PowerBanner />

          <SocialMedia />

          <Footer />
        </div>
      </div>
    </>
  );
};

export default SelectionCriteria;
