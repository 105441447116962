import React, { useEffect, useState } from "react";
import "../../Stylesheets/MainComponent/SocialMedia.scss";
import btnborder from "../../assets/Images/btn-design.png";
import facebook from "../../assets/Images/facebook.png";
import instagram from "../../assets/Images/instagram.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useSelector, useDispatch } from "react-redux";
// import Twitter from "../../assets/Images/x-twitter.png";
import srfinew from "../../assets/Images/srfinew.jpg";

import {
  getSocialMediaAction,
  getSocialMediaSuccessAction,
  getSocialMediaErrorAction,
} from "../../Redux/Slice";

const SocialMedia = () => {
  const dispatch = useDispatch();

  const [fbpost, setfbpost] = useState([]);

  const socialmedifulldata = useSelector(
    (state) => state.SocialMediaList.SocialMediaData
  );
  console.log(socialmedifulldata, "fbfbfb");

  useEffect(() => {
    dispatch(getSocialMediaAction());
  }, []);

  useEffect(() => {
    if (socialmedifulldata.data && socialmedifulldata.data != undefined) {
      setfbpost(socialmedifulldata.data);
      console.log(socialmedifulldata.data, "sosos");
    }
  });

  return (
    <div className="socialmedia-mainwrapper">
      <div className="constainer socialmedia-inner">
        <div className="col-md-9 post-box">
          <div className="social-media-post-btn">
            <button>SOCIAL MEDIA POSTS</button>
            <img src={btnborder} className="btn-border"></img>
          </div>

          {/* IMAGE DESIGN */}
          {/* <div className="container-fluid">
            <div className="row">
              {fbpost.length > 0 &&
                fbpost.map((items) => {
                  return (
                    <>
                      <div className="col-sm-6 col-md-6 col-lg-4 social-img-outer">
                        <div className="card cont1">
                          <div className="card-body">
                            <img
                              src={items.filefield}
                              alt={items.title}
                              className="social-img"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = srfinew;
                              }}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div> */}

          {/* IFRAME DESIGN */}
          <div className="container-fluid" style={{ height: "100%" }}>
            <div className="row">
              <div className="col social-img-outer">
                <div className="card cont1">
                  <div className="card-body">
                    <iframe
                      // src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Findiasquash%2Fposts%2Fpfbid02i1jUFDHoNb4xBrginDh9EH4sFsv1nDnfm16i3QshDoJ22hXUtCWXs7sjnw2tDKGEl&width=700&show_text=false&height=498&appId"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Findiasquash%2Fposts%2Fpfbid0X2AYuG3gyrFrDUmEujZQT1WNWFA5dQp9wxgYih1MXYwPdQzCwtuVnDAka9RkWoPbl&show_text=false&width=500"
                      width="700"
                      height="498"
                      // style="border:none;overflow:hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                </div>

                <div className="card cont1">
                  <div className="card-body">
                    <iframe
                      // src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Findiasquash%2Fposts%2Fpfbid0eAHm4YMyX9KWLxiPFahBcoFUp5aJsRD1U9X1dSKiDgcZUQ8jF9WcWgZbekf27CNZl&show_text=false&width=500&height=450"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Findiasquash%2Fposts%2Fpfbid02PQdMKR7cK2ca5ADK5kkq8Midq3iQiyH5hxtaoAe9iBsBCUhi4MMANMm2pQhsXhbql&show_text=false&width=500"
                      // style="border:none;overflow:hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      height={"450px"}
                      className="social-img"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                </div>

                <div className="card cont1">
                  <div className="card-body">
                    <iframe
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Findiasquash%2Fposts%2Fpfbid02i1jUFDHoNb4xBrginDh9EH4sFsv1nDnfm16i3QshDoJ22hXUtCWXs7sjnw2tDKGEl&show_text=false&width=500" // src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Findiasquash%2Fposts%2Fpfbid0eAHm4YMyX9KWLxiPFahBcoFUp5aJsRD1U9X1dSKiDgcZUQ8jF9WcWgZbekf27CNZl&show_text=false&width=500&height=450"
                      // style="border:none;overflow:hidden"
                      scrolling="no"
                      frameborder="0"
                      height={"450px"}
                      allowfullscreen="true"
                      className="social-img"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 follow-box">
          <div className="followus-btn">
            <button>FOLLOW US</button>
            <img src={btnborder} className="btn-border"></img>
          </div>

          <div className="followus-inner">
            <div className="face-insta">
              <button className="facebook-btn">
                <a
                  href="https://www.facebook.com/indiasquash"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} className="facebook-img"></img>
                  <span className="facebook-name">FACEBOOK</span>
                </a>
              </button>

              <br></br>

              <button className="facebook-btn">
                <a
                  href="https://www.instagram.com/indiansquash/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} className="facebook-img"></img>
                  <span className="facebook-name">INSTAGRAM</span>
                </a>
              </button>

              <br></br>

              {/* <button className="facebook-btn">
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2Findiasquash"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span className="facebook-name">TWITTER</span>
                </a>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
