import React from "react";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import "../../../Stylesheets/MainComponent/Players/ISpin.scss";
const ISpin = () => {
  return (
    <>
      <div className="container-fluid ISPIN-PART1">
        <Navbar />
        <div className="container-fluid  ISPIN_inner_wrapper ">
          <div className=" navbar2-container-ISPIN ">
            <Navbar2 />
          </div>
          <div className=" text-justify content-ISPIN ">
            <h4 className="my-4  HeadingTAB">
              <b>I-SPIN</b>
            </h4>

            <p className="paragraphIspin">
              If you are interested in playing competitive squash or wish to be
              an official or list your brand / product with SRFI you would need
              to first register with the federation through the mobile app for
              an I-SPIN – (India – Squash Personal Identification Number)
            </p>
            <br />

            <h5 className="Head-Content ">THERE ARE 2 TYPES OF I-SPIN:</h5>
            <ol>
              <li>
                <p className="paragraphI-Spin">
                  I-SPIN Basic account which is free of charge for official,
                  followers, parents and players who wish to participate in Non
                  ranking, One star and State closed events.
                </p>
              </li>
              <li>
                <p className="paragraphI-Spin">
                  I-SPIN Pro account for which a fee is to be paid (as given
                  below), for players who wish to participate in 2 star and
                  above status Championships and compete at professional level
                  of squash.
                </p>
              </li>
            </ol>
            <br />

            <p className="paragraphIspin">
              All you would need to do is to have 2 valid IDs with date of birth
              clearly mentioned ready for uploading, once you complete the
              registration form before making the required registration fees
              payment.
            </p>
            <br />
            <p className="paragraphIspin">
              I-SPIN Pro account - Registration fee as per table 1
            </p>
            <br />

            <div className="table-responsive  table-RESPONSIVES">
              <table className=" table ISPIN-TABLE ">
                {/* tableDATA  tableTAB tabelwidth FontSzInallMenus */}
                <tbody>
                  <tr className="FontSzInallMenu">
                    <th className="thTAb "></th>
                    <th className="thtab totfontcolor FontSzInallMenu ">
                      <b>Basic</b>
                    </th>

                    <th className="thtab totfontcolor FontSzInallMenu">
                      <b>ISPIN PRO - Fee Type</b>
                    </th>

                    <th className="thtab totfontcolor FontSzInallMenu">
                      <b>Charges</b>
                    </th>

                    <th className="thtab totfontcolor FontSzInallMenu">
                      <b>validity</b>
                    </th>
                  </tr>
                  <tr className="totfontcolor">
                    <th
                      className="thtab totofontcolor  text-center  FontSzInallMenu  rowtabs  THTABS"
                      rowSpan={"3"}
                      style={{ fontWeight: "400" }}
                    >
                      Junior and Senior
                    </th>

                    <th
                      className="thtab  text-center totofontcolor FontSzInallMenu rowtabs THTABS"
                      rowSpan={"3"}
                      style={{ fontWeight: "400" }}
                    >
                      Free
                    </th>

                    <td className="thTAb normal totfontcolor fontSZInallMenu">
                      Annual fee
                    </td>
                    <td className="thTAb normal totfontcolor fontSZInallMenu">
                      INR 1,999 + Online convenience charges
                    </td>
                    <td className="thTAb normal totfontcolor fontSZInallMenu">
                      From 1<sup>st</sup> January to 31<sup>st</sup> December
                    </td>
                  </tr>

                  <tr className="totfontcolor">
                    <td className="tdtab normal FontSzInallMenus">
                      Half Yearly fee for 1st Time PRO Membership
                    </td>
                    <td className="tdtab normal FontSzInallMenus">
                      INR 999 + Online convenience charges
                    </td>
                    <td className="tdtab normal FontSzInallMenus">
                      From 1<sup>st</sup> July to 31<sup>st</sup> December
                    </td>
                  </tr>

                  <tr className="totfontcolor">
                    <td className="tdtab normal FontSzInallMenus">
                      Renewal fee
                    </td>
                    <td className="tdtab normal FontSzInallMenus">
                      INR 1,999 + Online convenience charges
                    </td>
                    <td className="tdtab normal FontSzInallMenus">
                      Upto to 31<sup>st</sup> December
                    </td>
                  </tr>

                  <tr className="totofontcolor">
                    <td className="tdtab  FontSZInallMenus">Masters</td>
                    <td className="tdtab  normal FontSZInallMenus">free</td>

                    <td className="tdtab  normal FontSZInallMenus">
                      Annual fee
                    </td>

                    <td className="tdtab  normal FontSZInallMenus">
                      INR 999 + Online convenience charges
                    </td>

                    <td className="tdtab  normal FontSZInallMenus">
                      From 1<sup>st</sup> January to 31<sup>st</sup> December
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </>
  );
};

export default ISpin;
