import React, { useEffect, useState } from "react";
import "../../../Stylesheets/MainComponent/Federations/Elections.scss";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

import {
  getElectionsPdfAction,

} from "../../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";

const Elections = () => {
  const dispatch = useDispatch();

  const [electionpdf, setElectionpdf] = useState([]);

  const electionpdffulldata = useSelector((state) => state.ElectionPdfList.ElectionPdf);

  // console.log(electionpdffulldata, "elpdf")

  useEffect(() => {
    dispatch(getElectionsPdfAction());
   }, []);

   useEffect(() => {
    setElectionpdf(electionpdffulldata);
    // console.log(electionpdffulldata, "eleele")
   }, [electionpdffulldata]);

   const openPdf = (url) => {
    // console.log("url:", url);
    window.open(url);
  };

  // function one() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/SRFIElectoralCollege.pdf"
  //   );
  // }
  // function two() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/ListofNominatedCandidates.jpg"
  //   );
  // }
  // function three() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/ListofContestantsForm-4.pdf"
  //   );
  // }
  // function four() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/FinalListofcontestingcandidatesForm-6.pdf"
  //   );
  // }
  // function five() {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/image/Form15SRFIElection.pdf"
  //   );
  // }


  return (
    <div className="election-main-wrapper">
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="container-fluid election-inner">
          <div class="title shimmer">
            <h4>
              <b>ELECTIONS 2023 - 2027</b>
            </h4>
          </div>

          <div className="election-btns-wrapper">
            <ul className="ruleslist">
              {electionpdf.map((item, index) => (
                 <li className="pt-2" key={index}>
                 <button className="electrol-btn" onClick={() =>
                              openPdf(
                                "https://demowhats.s3.ap-south-1.amazonaws.com/" +
                                  item.filefield
                              )
                            }>
                   {item.title}
                 </button>
               </li>
              ))}

              {/* <li className="pt-2">
                <button className="electrol-btn" onClick={one}>
                  Electrol College-Form 1
                </button>
              </li>
              <li className="pt-2">
                <button className="electrol-btn" onClick={two}>
                  List of Nominated Candidates - Form 3
                </button>
              </li>
              <li className="pt-2">
                <button className="electrol-btn" onClick={three}>
                  List of Contestants - Form 4
                </button>
              </li>
              <li className="pt-2">
                <button className="electrol-btn" onClick={four}>
                  Final List of Contesting Candidates - Form 6
                </button>
              </li>
              <li className="pt-2">
                <button className="electrol-btn" onClick={five}>
                  Declaration of Results - Form15
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default Elections;
