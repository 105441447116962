import React from 'react'
import Navbar2 from '../NavBar/Navbar2';
import PowerBanner from '../../Appcomponents/PowerBanner';
import SocialMedia from '../SocialMedia';
import Footer from '../../Appcomponents/Footer';
import Navbar from '../NavBar/Navbar1';
import "../../../Stylesheets/MainComponent/Official/Pathway.scss";
const Coaching = () => {
  return (
    <>
      <div className='container-fluid PATHWAY-PART1' >
        <Navbar />
        <div className='container-fluid  PATHWAY_inner_wrapper' id="">
          <div className="navbar2-container-PATH ">
            <Navbar2 />

          </div>
          <div className='  TABOFF my-4 text-justify content-PATHWAY1 '>
            <div className="title shimmer">
              <h1 className='HeadingOFF  my-4'>COACHING COURSE</h1>
              </div>
           
              <p className='paragraphOFF'>
                Currently, we have more than 150 Level 1, Level 2 and 3 Level certified coaches.  However, there is always a great demand for qualified squash coaches across the country for grooming budding squash players. Why not make a career of it? If you have played the game and have the enthusiasm, patience and desire to coach others, you could follow our coaching pathway which will help guide you on your career as a coach. All you will have to do is to enrol with SRFI for I-SPIN and apply for an upcoming coaching course.
              </p>
              <p className='paragraphOFF'>
                SRFI organises WSF certification program from time to time at selected locations. With the SRFI’s vision of broad-basing squash, the coach certification courses are conducted in India under the aegis of the Ministry of Youth Affairs & Sports (MYAS).
              </p>
              <p className='paragraphOFF'>Register for I-SPIN and submit this application form, we will get in touch with you before the course.

              </p>
            </div>
       
        </div>
        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>

    </>
  )
}

export default Coaching
