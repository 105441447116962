import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";
import SocialMedia from "../SocialMedia";
import { usePagination } from "react-table";
import "../../../Stylesheets/MainComponent/Players/Ranking.scss";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import {
  getRankingJuniorAction,
  getSeniorRankAction,
  getmasterRankAction,
  getProcoachRankAction,
  setPagin,
  setPaginPRO,
  setTotalPageMASTER,
  setSENIORPAGE,
  getCalenderYearAction,
} from "../../../Redux/Slice";
import Table from "./Table";
import scroll from "../../../assets/Images/scrollup.png";

const Ranking = () => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

   // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  let [selects, setselected] = useState("1");
  let [RankingtableData, setRankingtabaleData] = useState([]);
  let [paginations, setpaginations] = useState();
  let [pagesenior, setpagesenior] = useState();
  let [pageMaster, setmasterpage] = useState();
  let [pageProCoach, setProcoach] = useState();

  const handleChanges = (e) => {
    setselected(e.target.value);
  };

  // ---------------------year----Months-----Junior,Senior,Master---JSON----------
  const setRanking = [
    {
      id: 1,
      RDATA: "JUNIOR",
    },
    {
      id: 2,
      RDATA: "SENIOR",
    },
    {
      id: 3,
      RDATA: "MASTER",
    },
    {
      id: 4,
      RDATA: "PROCOACH",
    },
  ];

  const months = [
    {
      id: 1,
      months: "JANUARY",
    },
    {
      id: 2,
      months: "FEBRUARY",
    },
    {
      id: 3,
      months: "MARCH",
    },
    {
      id: 4,
      months: "APRIL",
    },
    {
      id: 5,
      months: "MAY",
    },
    {
      id: 6,
      months: "JUNE",
    },
    {
      id: 7,
      months: "JULY",
    },
    {
      id: 8,
      months: "AUGUST",
    },
    {
      id: 9,
      months: "SEPTEMBER",
    },
    {
      id: 10,
      months: "OCTOBER",
    },
    {
      id: 11,
      months: "NOVEMBER",
    },
    {
      id: 12,
      months: "DECEMBER",
    },
  ];

  const BDATA = [
    {
      id: 1,
      JUNIOR: "BU19",
    },
    {
      id: 2,
      JUNIOR: "BU17",
    },
    {
      id: 3,
      JUNIOR: "BU15",
    },
    {
      id: 4,
      JUNIOR: "BU13",
    },
    {
      id: 5,
      JUNIOR: "BU11",
    },
    {
      id: 6,
      JUNIOR: "GU19",
    },
    {
      id: 7,
      JUNIOR: "GU17",
    },
    {
      id: 8,
      JUNIOR: "GU15",
    },
    {
      id: 9,
      JUNIOR: "GU13",
    },
    {
      id: 10,
      JUNIOR: "GU11",
    },
  ];

  const SDATA = [
    {
      id: 1,
      SENIOR: "MEN",
    },
    {
      id: 2,
      SENIOR: "WOMEN",
    },
  ];

  const MDATA = [
    {
      id: 1,
      MASTER: "MO35",
    },
    {
      id: 2,
      MASTER: "MO40",
    },
    {
      id: 3,
      MASTER: "MO45",
    },
    {
      id: 4,
      MASTER: "MO50",
    },
    {
      id: 5,
      MASTER: "MO55",
    },
    {
      id: 6,
      MASTER: "MO60",
    },
    {
      id: 7,
      MASTER: "MO65",
    },
  ];

  // <----------React-Table----data-------->

  // -------------------Juniors----------------------
  const juniorstate = useSelector((state) => state.Rankinglist);
  const junipages = useSelector((state) => state.Rankinglist);

  // -------------------Seniors----------------------
  const Seniors = useSelector((state) => state.SeniorRankList);
  const SeniorsPAGNI = useSelector((state) => state.SeniorRankList);

  // -------------------Master----------------------
  const MasterState = useSelector((state) => state.MasterRankList);
  const MasterPages = useSelector((state) => state.MasterRankList);

  // -------------------Procoach----------------------
  const proCoach = useSelector((state) => state.ProcoachList);


  useEffect(() => {
    if (selects === "1") {
      setRankingtabaleData(juniorstate.RankJunior);
      setpaginations(junipages.paginRank);
      // console.log(juniorstate.RankJunior, "1234")
    }
    if (selects === "2") {
      setRankingtabaleData(Seniors.SeniorRankdatas);
      setpagesenior(SeniorsPAGNI.SeniorPage);
      // console.log(paginations, "for the seniors pagination numbers");
    }
    if (selects === "3") {
      setRankingtabaleData(MasterState.MasterRankdatas);
      setmasterpage(MasterPages.MastertotalPage);
    }
    if (selects === "4") {
      setRankingtabaleData(proCoach.Procoachdata);
      setProcoach(proCoach.paginRankSe);
    }
  }, [juniorstate, selects, paginations, Seniors, MasterState, proCoach]);



  // -----------yearsss----------------
  const DATA_YEAR = new Date();
  const CURRENTYEARS = DATA_YEAR.getFullYear();
  // console.log(CURRENTYEARS,"yyy");

  const [Years, setYear] = useState(CURRENTYEARS);
  const handleYearSelect = (YEAR) => {
    setYear(YEAR);
    console.log(YEAR, "year");
  };
  const [filteredYear, setFilteredYear] = useState([]);
  const filteredyearfulldata = useSelector((state) => state.CalenderYearList);
  // console.log(filtercalyearfulldata, "cal year");

  useEffect(() => {
    // console.log(filtercalyearfulldata.CalenderYearData, "YEAR123");
    setFilteredYear(filteredyearfulldata.CalenderYearData);
  }, [filteredyearfulldata]);

  useEffect(() => {
    // setYears(yearsList.CalenderYearData,"yyyyy");
    dispatch(getCalenderYearAction());
  }, []);

  // -------------------get---changed----data---------

  const [Budata, setBuData] = useState("BU19");

  const [Months, setMonths] = useState();
  const [SeniorMW, setSeniorMW] = useState("MEN");
  const [Masters, setmasters] = useState();
  const [type, setTYpe] = useState();
  // -----------Junior-----------------------
  const handleJuniorB = (e) => {
    setBuData(e.target.value);
  };

  // -------Months---------------
  const handleMonths = (event) => {
    setMonths(event.target.value);
  };
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    setMonths(currentMonth.toString());
  }, []);
  // ----------------Seniors------------------
  const handleSeniors = (event) => {
    var MW = event.target.value;
    setSeniorMW(MW);
  };
  // -----------Master-----------
  const handleMaster = (e) => {
    setmasters(e.target.value);
  };

  // ------------REACT-TABLE-----------------------
  let [pagings, setpaging] = useState(1);

  //  -------------------Juniors----------------
  useEffect(() => {
    if (selects === "1") {
      setTYpe("JUNIOR");
      console.log(type, "type ");
      const payload = {
        Budata,
        Years,
        Months,
        pagings,
        type,
      };
      if (Budata || Years || Months || pagings || type) {
        dispatch(getRankingJuniorAction(payload));
        dispatch(setPagin());
      }
    }

    // console.log(Budata, "budata ");
  }, [Budata, Years, Months, pagings, selects]);

  //  -------------------Seniors----------------
  useEffect(() => {
    console.log("dgv");
    if (selects === "2") {
      setTYpe("SENIOR");
      setTimeout(() => {
        console.log(type, "seni");
      }, 500);

      const payload = {
        SeniorMW,
        Years,
        Months,
        pagings,
        type,
      };
      if (SeniorMW || Years || Months || pagings || type) {
        dispatch(getSeniorRankAction(payload));
        dispatch(setSENIORPAGE());
      }
    }
  }, [SeniorMW, Years, Months, pagings, selects]);

  // --------------------Master----------
  useEffect(() => {
    if (selects === "3") {
      setTYpe("MASTER");
      console.log(type, "MASt");
      const payload = {
        Masters,
        Years,
        Months,
        pagings,
        type,
      };
      if (Masters || Years || Months || pagings || type) {
        dispatch(getmasterRankAction(payload));
        dispatch(setTotalPageMASTER());
      }
    }
  }, [Masters, Years, Months, pagings, selects]);

  // --------------------Procoach----------
  useEffect(() => {
    if (selects === "4") {
      setTYpe("PROCOACH");
      const payload = {
        Years,
        Months,
        pagings,
        type,
      };
      setRankingtabaleData(proCoach.Procoachdata);
      // console.log(selects, "sadf aRRAFW");
      dispatch(getProcoachRankAction(payload));
      dispatch(setPaginPRO());
      setProcoach(proCoach.paginRankSe);
      // console.log(paginations, "for the Procoach pagination numbers");
    }
  }, [selects, pagings, Years, Months, type]);


  // ----------------------REACT----TABLE---------------------
  const columns = React.useMemo(
    () => [
      {
        Header: "RANKING",
        accessor: "Rank",
      },
      {
        Header: "PLAYER NAME",
        accessor: "PlayerName",
      },
      {
        Header: "DOB",
        accessor: "Dob",
      },
      {
        Header: "TOTAL POINTS",
        accessor: "Points",
      },
      {
        Header: "AVG.POINTS",
        accessor: "Avegpoints",
      },
      {
        Header: "CATEGORY",
        accessor: "Category",
      },
    ],
    []
  );

  return (
    <>
      <div className="container-fluid RANKING-PART1">
        {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
            <img src={scroll} className="scrollup"></img>
          </button>
        )}
        <Navbar />
        <div className="container-fluid RANKING_inner_wrapper ">
          <div className="  navbar2-container-RANKING">
            <Navbar2 />
          </div>

          <div className=" TAB4  content-RANKING  ">
            <div className="title rankingHeading  shimmer">
              <h4 className="HeadingTAB">
                <b>RANKING</b>{" "}
              </h4>
            </div>
            {/* -------------------filter-dropDown-------------------- */}
            <div className="fliters">
              {/* -----------------drop----------------------- */}
              <div className="card cardRANK">
                {/* <div className='card'> */}
                <select
                  class="form-select  dropmenusContent "
                  onChange={handleChanges}
                >
                  {setRanking.map((RDATA) => (
                    <option
                      value={RDATA.id}
                      key={RDATA.id}
                      className="playersOption"
                    >
                      {RDATA.RDATA}
                    </option>
                  ))}
                </select>
                {/* </div> */}
              </div>

              {/* ---------------END--drop----------------------- */}

              {/* -----------------junior------------------------------ */}
              <div className="JSMPART">
                <div className="JSMPART1">
                  {selects === "1" && (
                    <>
                      <div className="card JSMPCRD cardRANK">
                        <select
                          class="form-select JUFORM  "
                          aria-label=".form-select-sm example"
                          value={Budata}
                          onChange={handleJuniorB}
                        >
                          {/* ---------------------------------GU19--------------------------- */}
                          {BDATA.map((JUNIOR) => (
                            <option value={JUNIOR.JUNIOR} key={JUNIOR.id}>
                              {JUNIOR.JUNIOR}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                  {/* --------------------senior---------------- */}
                  {selects === "2" && (
                    <div className="card cardRANK">
                      <div className="tab-pane " id="Seniors">
                        <select
                          class="form-select JUFORM "
                          value={SeniorMW}
                          aria-label=".form-select-sm example"
                          onChange={handleSeniors}
                        >
                          {SDATA.map((SENIOR) => (
                            <option value={SENIOR.SENIOR} key={SENIOR.id}>
                              {SENIOR.SENIOR}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {/* --------------------------Master------------ */}
                  {selects === "3" && (
                    <div className="card cardRANK">
                      <select
                        class="form-select JUFORM  "
                        value={Masters}
                        aria-label=".form-select-sm example"
                        onChange={handleMaster}
                      >
                        {MDATA.map((MASTER) => (
                          <option value={MASTER.MASTER} key={MASTER.id}>
                            {MASTER.MASTER}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {/* ---------------------------PRO-COACH--------------------------- */}
                  {selects === "4" && (
                    // <option className='PROCOACH' selected>PROCOACH</option>
                    <></>
                  )}
                </div>
              </div>
              <div className="months-content">
                <div className="monthsVALIDATE my-4">
                  {/* ----------------MONTHS------------------ */}
                  <div className="card cardRANK">
                    <select
                      class="Filter"
                      id="Months"
                      onChange={handleMonths}
                      value={Months}
                    >
                      {months.map((months) => (
                        <option
                          value={months.id}
                          key={months.id}
                          className="FiltersHOVERS"
                        >
                          {months.months}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* ----------------------YEARS------------------- */}
                  <div className="card cardRANK ">
                    <select
                      value={Years}
                      class="Filter"
                      id="Years"
                      onChange={(e) => handleYearSelect(e.currentTarget.value)}
                    >
                      {filteredYear.map((year, index) => (
                        <option key={index} value={year.toString()}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------------- End---filter-dropDown-------------------- */}
            {/* ------------REACT-TABLE------------------ */}
            <div className="Rankign-table">
              {/* <--------------Table----------------> */}
              <div className="table table-striped Table-Content ">
                <div className="register-player-mainwrapper">
                  {/* ----------------Junior --------------------- */}
                  {RankingtableData != undefined &&
                    selects === "1" &&
                    paginations != undefined && (
                      <>
                        <Table
                          columns={columns}
                          Years={Years}
                          Months={Months}
                          Budata={Budata}
                          setdata={setRankingtabaleData}
                          pagesize={paginations}
                          pageindex={pagings}
                          data={RankingtableData}
                          type={type}
                          setTYpe={setTYpe}
                          setPagin={setPagin}
                          selects={selects}
                        />
                      </>
                    )}

                  {RankingtableData != undefined &&
                    selects === "2" &&
                    pagesenior != undefined && (
                      <>
                        <Table
                          columns={columns}
                          selects={selects}
                          SeniorMW={SeniorMW}
                          Years={Years}
                          Months={Months}
                          data={RankingtableData}
                          pagesize={pagesenior}
                          type={type}
                          pageindex={pagings}
                        />
                      </>
                    )}

                  {RankingtableData != undefined &&
                    selects === "3" &&
                    pageMaster != undefined && (
                      <>
                        <Table
                          columns={columns}
                          selects={selects}
                          data={RankingtableData}
                          pagesize={pageMaster}
                          pageindex={pagings}
                          Years={Years}
                          type={type}
                          Months={Months}
                          Masters={Masters}
                        />
                      </>
                    )}

                  {RankingtableData != undefined &&
                    selects === "4" &&
                    pageProCoach != undefined && (
                      <>
                        <Table
                          columns={columns}
                          data={RankingtableData}
                          pagesize={pageProCoach}
                          Years={Years}
                          type={type}
                          Months={Months}
                          selects={selects}
                          pageindex={pagings}
                        />
                      </>
                    )}
                </div>

                {/* -----------------REACT----TAble--------------------- */}
              </div>
            </div>
          </div>
        </div>
        <PowerBanner />

        <SocialMedia />

        <Footer />
      </div>
    </>
  );
};

export default Ranking;
