import one from "../../assets/Images/MinBanner/1.png";
import two from "../../assets/Images/MinBanner/2.png";
import three from "../../assets/Images/MinBanner/3.png";
import four from "../../assets/Images/MinBanner/4.png";
import five from "../../assets/Images/MinBanner/5.png";
import six from "../../assets/Images/MinBanner/6.png";
import seven from "../../assets/Images/MinBanner/7.png";
import eight from "../../assets/Images/MinBanner/8.png";


export const MiniBannerData = [
  {
    id: 1,
    image: one,
    content: "one",
  },
  {
    id: 2,
    image: two,
    content: "two",
  },
  {
    id: 3,
    image: three,
    content: "three",
  },
  {
    id: 4,
    image: four,
    content: "four",
  },
  {
    id: 5,
    image: five,
    content: "five",
  },
  {
    id: 6,
    image: six,
    content: "six",
  },
  {
    id: 7,
    image: seven,
    content: "seven",
  },
  {
    id: 8,
    image: eight,
    content: "eight",
  },
];
