import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import scroll from "../../../assets/Images/scrollup.png";

import Navbar from "../NavBar/Navbar1";
import Navbar2 from "../NavBar/Navbar2";
import SocialMedia from "../../MainComponets/SocialMedia";
import Footer from "../../Appcomponents/Footer";
import PowerBanner from "../../Appcomponents/PowerBanner";

import "../../../Stylesheets/MainComponent/Events/SquashTime.scss";

import { getSquashTimeAction, } from "../../../Redux/Slice";
import srfinew from "../../../assets/Images/srfinew.jpg"


const SquashTime = () => {

  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll event and update button visibility
  const handleScroll = () => {
    if (window.scrollY > 200) {
      // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  
 
  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch = useDispatch();
  const [squashTime, setSquashTime] = useState([]);

  var s3url = "https://demowhats.s3.ap-south-1.amazonaws.com/media/";

  const squashtimefulldata = useSelector((state) => state.SquashTimeList);
  console.log("stst", squashtimefulldata);

  useEffect(() => {
    dispatch(getSquashTimeAction());
  }, []);

  useEffect(() => {
    setSquashTime(squashtimefulldata.SquashTimeData.data);
    console.log(squashtimefulldata.SquashTimeData.data, "sqti")
  },[squashtimefulldata])
  
  return (
    <div className="squashtime-main-wrapper">
      {isVisible && (
          <button id="scrollToTopButton" onClick={scrollToTop}>
            <img src={scroll} className="scrollup"></img>
          </button>
        )}
      <Navbar />
      <div className="container-fluid indiasquash_inner_wrapper">
        <div className="navbar2-container">
          <Navbar2 />
        </div>

        <div className="containers squashtime-wrapper">
          <div class="title shimmer">
            <h4>
              <b>SQUASH TIME</b>
            </h4>
          </div>

          <div className="squashtime-inner-wrapper">
            <div className="row">
              {squashTime && squashTime.map((items, index) => {
                return (
                  <>
                    <div className="col-sm-12 col-md-6 col-lg-4 card-container">
                      <a
                        href={s3url + items.Filefield}
                        target="_blank"
                        className="navigate-image"
                        rel="noreferrer"
                      >
                        <div class="card card-wrap">
                          <img
                            class="card-img-top"
                            src={s3url + items.Image}
                            alt="squashTime"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =srfinew
                            }}
                          />
                          <div class="card-body cb">
                            <h6 class="card-text">{items.Title}</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <PowerBanner />

      <SocialMedia />

      <Footer />
    </div>
  );
};

export default SquashTime;
