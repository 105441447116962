import { createSlice } from "@reduxjs/toolkit";

const BannerSlice = createSlice({
  name: "BannerList",
  initialState: {
    BannerData: [],
    error: "",
  },
  reducers: {
    getBannerAction: (state, action) => {
      state.error = "";
      state.BannerData = [];
    },
    getBannerSuccessAction: (state, action) => {
      state.BannerData = action.payload;
    },
    getBannerErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const UpcomingMatchSlice = createSlice({
  name: "UpcomingMatchsList",
  initialState: {
    UpcomingMatchData: [],
    searchUpcomingData: [],
    error: "",
  },
  reducers: {
    getUpcomingMatchAction: (state, action) => {
      // console.log(state, action);
      state.error = "";
      state.UpcomingMatchData = [];
    },
    getUpcomingMatchSuccessAction: (state, action) => {
      //   console.log(state, action.payload, "coming");
      //   debugger;
      state.UpcomingMatchData = action.payload;
    },
    getUpcomingSearchResponsesucessAction: (state, action) => {
      state.searchUpcomingData = action.payload;
    },
    getUpcomingMatchErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const TopRankSlice = createSlice({
  name: "TopRankList",
  initialState: {
    TopRankData: [],
    searchRankTopRankDataj: [],
    searchRankTopRankDatam: [],
    error: "",
  },
  reducers: {
    getTopRankAction: (state, action) => {
      state.error = "";
      state.TopRankData = [];
    },
    getTopRankSuccessAction: (state, action) => {
      state.TopRankData = action.payload;
    },
    getTopRankSearchResponsesucessAction: (state, action) => {
      state.searchRankTopRankDataj = action.payload;
    },
    getSearchResponsesucessAction: (state, action) => {
      state.searchRankTopRankDatam = action.payload;
    },
    getTopRankErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const LatestResultsSlice = createSlice({
  name: "LatestResultsList",
  initialState: {
    LatestResultData: [],
    error: "",
  },
  reducers: {
    getResultsAction: (state, action) => {
      // console.log(state, action, "re1");
      state.error = "";
      state.LatestResultData = [];
    },
    getResultsSuccessAction: (state, action) => {
      // console.log(state, action.payload, "re2");
      state.LatestResultData = action.payload;
    },
    getResultsErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const LatestVideosSlice = createSlice({
  name: "LatestVideosList",
  initialState: {
    LatestVideosData: [],
    error: "",
  },
  reducers: {
    getVideosAction: (state, action) => {
      // console.log(state, action, "re1");
      state.error = "";
      state.LatestVideosData = [];
    },
    getVideosSuccessAction: (state, action) => {
      // console.log(state, action.payload, "re2");
      state.LatestVideosData = action.payload;
    },
    getVideosErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const LatestNewsSlice = createSlice({
  name: "LatestNewsList",
  initialState: {
    LatestNewsData: [],
    searchLatNewsData: [],
    error: "",
  },
  reducers: {
    getNewsAction: (state, action) => {
      state.error = "";
      state.LatestNewsData = [];
    },
    getNewsSuccessAction: (state, action) => {
      state.LatestNewsData = action.payload;
    },
    getLatestNewsSearchAction: (state, action) => {
      state.searchLatNewsData = action.payload;
    },
    getNewsErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// const MasterProTopRankSlice = createSlice({
//   name: "MasterProTopRankList",
//   initialState: {
//     MasterRankTopRankData: [],
//     searchRankTopRankData: [],
//     error: "",
//   },
//   reducers: {
//     getMasterProTopRankAction: (state, action) => {
//       state.error = "";
//       state.MasterRankTopRankData = [];
//     },
//     getMasterProTopRankSuccessAction: (state, action) => {
//       state.MasterRankTopRankData = action.payload;
//     },
//     getSearchResponsesucessAction: (state, action) => {
//       state.searchRankTopRankData = action.payload;
//     },
//     getMasterProTopRankErrorAction: (state, action) => {
//       state.error = action.payload;
//     },
//   },
// });

const FilterStateSlice = createSlice({
  name: "FilterStateList",
  initialState: {
    FilterStateData: [],
    error: "",
  },
  reducers: {
    getFilterStateAction: (state, action) => {
      // console.log(state, action, "ju123");
      state.error = "";
      state.FilterStateData = [];
    },
    getFilterStateSuccessAction: (state, action) => {
      // console.log(state, action.payload, "juju");
      state.FilterStateData = action.payload;
    },
    getFilterStateErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// affiliation
const AffiliationSlice = createSlice({
  name: "AffiliationList",
  initialState: {
    AffiliationData: [],
    searchAffiliationData: [],
    error: "",
  },
  reducers: {
    getAffiliationAction: (state, action) => {
      state.error = "";
      state.AffiliationData = [];
    },
    getAffiliationSuccessAction: (state, action) => {
      state.AffiliationData = action.payload;
    },
    getAffiliationSearchAction: (state, action) => {
      state.searchAffiliationData = action.payload;
    },
    getAffiliationErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const RegisterPlayerSlice = createSlice({
  name: "RegisterPlayerList",
  initialState: {
    RegisterPlayerData: [],
    error: "",
  },
  reducers: {
    getRegisterPlayerAction: (state, action) => {
      // console.log(state, action, "ju123");
      state.error = "";
      state.RegisterPlayerData = [];
    },
    getRegisterPlayerSuccessAction: (state, action) => {
      // console.log(state, action.payload, "juju");
      state.RegisterPlayerData = action.payload;
    },
    getRegisterPlayerErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const EventsSlice = createSlice({
  name: "EventsList",
  initialState: {
    EventsData: [],
    error: "",
  },
  reducers: {
    getEventsAction: (state, action) => {
      // console.log(state, action, "ju123");
      state.error = "";
      state.EventsData = [];
    },
    getEventsSuccessAction: (state, action) => {
      // console.log(state, action.payload, "juju");
      state.EventsData = action.payload;
    },
    getEventsErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const CalenderYearSlice = createSlice({
  name: "CalenderYearList",
  initialState: {
    CalenderYearData: [],
    error: "",
  },
  reducers: {
    getCalenderYearAction: (state, action) => {
      // console.log(state, action, "ju123");
      // debugger

      state.error = "";
      state.CalenderYearData = [];
    },
    getCalenderYearSuccessAction: (state, action) => {
      state.CalenderYearData = action.payload;
    },
    getCalenderYearErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const CalenderFilterSlice = createSlice({
  name: "CalenderFilterList",
  initialState: {
    CalenderFilterData: [],
    CalenderFilterSearchData: [],
    error: "",
  },
  reducers: {
    getCalenderFilterAction: (state, action) => {
      // console.log(state, action, "ju1234");
      state.error = "";
      state.CalenderFilterData = [];
    },
    getCalenderFilterSuccessAction: (state, action) => {
      // console.log(state, action.payload, "juju");
      state.CalenderFilterData = action.payload;
    },
    getCalenderFilterSearchAction: (state, action) => {
      state.CalenderFilterSearchData = action.payload;
    },
    getCalenderFilterErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const CalenderTourDetailsSlice = createSlice({
  name: "CalenderTourDetailsList",
  initialState: {
    CalenderTourDetailsData: [],
    error: "",
  },
  reducers: {
    getCalenderTourDetailsAction: (state, action) => {
      // console.log(state, action, "sep11");
      state.error = "";
      state.CalenderTourDetailsData = [];
    },
    getCalenderTourDetailsSuccessAction: (state, action) => {
      // console.log(state, action.payload, "sep12");
      state.CalenderTourDetailsData = action.payload;
    },
    getCalenderTourDetailsErrorAction: (state, action) => {
      state.error = action.payload;
    },
    getCalenderTourDetailsEmptyAction: (state, action) => {
      state.CalenderTourDetailsData = action.payload;
    },
  },
});

// const CalTourEntriesSlice = createSlice({
//   name: "CalTourEntriesList",
//   initialState: {
//     CalTourEntriesData: [],
//     // CalTourEntriesPage: [],
//     error: "",
//   },
//   reducers: {
//     getCalTourEntriesAction: (state, action) => {
//       // console.log(state, action, "sep11");
//       state.error = "";
//       state.CalTourEntriesData = [];
//     },
//     getCalTourEntriesSuccessAction: (state, action) => {
//       // console.log(state, action.payload, "sep12");
//       state.CalTourEntriesData = action.payload;
//     },
//     // setTotalPageENTRIES: (state, action) => {
//     //   state.CalTourEntriesPage = action.payload;
//     // },

//     getCalTourEntriesErrorAction: (state, action) => {
//       state.error = action.payload;
//     },
//   },
// });

const CalTourEntriesPAGESlice = createSlice({
  name: "CalTourEntriesPAGEList",
  initialState: {
    CalTourEntriesPAGEData: [],
    PageIndex:1,
    error: "",
  },
  reducers: {
    getCalTourEntriesPAGEAction: (state, action) => {
      // console.log(state, action, "sep11");
      state.error = "";
      state.PageIndex = action.payload.page
      state.CalTourEntriesPAGEData = [];
    },
    getCalTourEntriesPAGESuccessAction: (state, action) => {
      // console.log(state, action.payload, "sep12");
      state.CalTourEntriesPAGEData = action.payload;
    },
    // setTotalPageENTRIES: (state, action) => {
    //   state.CalTourEntriesPage = action.payload;
    // },

    getCalTourEntriesPAGEErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const NewsTabSlice = createSlice({
  name: "NewsTabList",
  initialState: {
    NewsTabData: [],
    searchNewsData: [],
    error: "",
  },
  reducers: {
    getNewsTabAction: (state, action) => {
      // console.log(state, action, "sep11");
      state.error = "";
      state.NewsTabData = [];
    },
    getNewsTabSuccessAction: (state, action) => {
      // console.log(state, action.payload, "sep12");
      state.NewsTabData = action.payload;
    },
    getNewsSearchAction: (state, action) => {
      state.searchNewsData = action.payload;
    },
    getNewsTabErrorAction: (state, action) => {
      state.error = action.payload;
    },
    getNewsTabEmptyAction: (state, action) => {
      state.NewsTabData = action.payload;
    },
  },
});

const NewsTabPutsSlice = createSlice({
  name: "NewsTabPutsList",
  initialState: {
    NewsTabPutsData: [],
    error: "",
  },
  reducers: {
    getNewsTabPutsAction: (state, action) => {
      // console.log(state, action, "sep11");
      state.error = "";
      state.NewsTabPutsData = [];
    },
    getNewsTabPutsSuccessAction: (state, action) => {
      // console.log(state, action.payload, "sep12");
      state.NewsTabPutsData = action.payload;
    },
    getNewsTabPutsErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const PostNewsTabsSlice = createSlice({
  name: "PostNewsTabsList",
  initialState: {
    PostNewsTabsData: [],
    searchPostNewsTabsData: [],
    error: "",
  },
  reducers: {
    postNewsTabsAction: (state, action) => {
      console.log(state, action, "sep18");
      state.error = "";
      state.PostNewsTabsData = [];
    },
    postNewsTabsSuccessAction: (state, action) => {
      console.log(state, action.payload, "sep19");
      state.PostNewsTabsData = action.payload;
    },
    postNewsTabsSearchAction: (state, action) => {
      state.searchPostNewsTabsData = action.payload;
    },
    postNewsTabsErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const SquashTimeSlice = createSlice({
  name: "SquashTimeList",
  initialState: {
    SquashTimeData: [],
    searchSquashTimeData: [],
    error: "",
  },
  reducers: {
    getSquashTimeAction: (state, action) => {
      // console.log(state, action, "sep11");
      state.error = "";
      state.SquashTimeData = [];
    },
    getSquashTimeSuccessAction: (state, action) => {
      // console.log(state, action.payload, "sep12");
      state.SquashTimeData = action.payload;
    },
    getSquashTimeSearchAction: (state, action) => {
      state.searchSquashTimeData = action.payload;
    },
    getSquashTimeErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// ------TEAM--INDIA------------------------

// sathik
const TeamIndiaSlice = createSlice({
  name: "PlayersList",
  initialState: {
    ALL: [],
    AE: [],
    WE: [],
    ALLSEARCH: [],
    AESEARCH: [],
    WESEARCH: [],
  },
  reducers: {
    getALLPlayersAction: (state, action) => {
      state.ALL = action.payload;
    },
    getALLPlayersSuccess: (state, action) => {
      state.ALL = action.payload;
    },
    getALLPlayersSearch: (state, action) => {
      state.ALLSEARCH = action.payload;
    },
    getALLPlayersError: (state, action) => {
      state.error = action.payload;
    },

    getAEPlayersAction: (state, action) => {
      state.AE = [];
    },
    getAEPlayersSuccess: (state, action) => {
      state.AE = action.payload;
    },
    getAEPlayersSearch: (state, action) => {
      state.AESEARCH = action.payload;
    },
    getAEPlayersError: (state, action) => {
      state.error = action.payload;
    },

    getWEPlayersAction: (state, action) => {
      state.WE = [];
    },
    getWEPlayersSuccess: (state, action) => {
      state.WE = action.payload;
    },
    getWEPlayersSearch: (state, action) => {
      state.WESEARCH = action.payload;
    },
    getWEPlayersError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// ---------------------Ranking---------------

// -----------------Junior-----------------------

// const TeamIndiaSlice = createSlice({
//   name: "PlayersList",
//   initialState: {
//     ALL: [],
//     AE: [],
//     WE: [],
//   },
//   reducers: {
//     getALLPlayersAction: (state, action) => {
//       state.ALL = action.payload;
//     },
//     getALLPlayersSuccess: (state, action) => {
//       state.ALL = action.payload;
//     },
//     getAEPlayersAction: (state, action) => {
//       state.AE = [];
//     },
//     getAEPlayersSuccess: (state, action) => {
//       state.AE = action.payload;
//     },
//     getWEPlayersAction: (state, action) => {
//       state.WE = [];
//     },
//     getWEPlayersSuccess: (state, action) => {
//       state.WE = action.payload;
//     },
//   },
// });

const RankingSlice = createSlice({
  name: "RankingJunior",
  initialState: {
    RankJunior: [],
    paginRank: 0,
  },
  reducers: {
    getRankingJuniorAction: (state, action) => {
      state.RankJunior = [];
    },
    getRankingsJuniorSuccess: (state, action) => {
      state.RankJunior = action.payload;
    },
    setPagin: (state, action) => {
      state.paginRank = action.payload;
    },
  },
});

// --------------Seniors-------------------------
const SeniorRankSlice = createSlice({
  name: "SeniorRankList",
  initialState: {
    SeniorRankdatas: [],
    SeniorPage: "",
    error: "",
  },
  reducers: {
    getSeniorRankAction: (state, action) => {
      state.error = "";
      state.SeniorRankdatas = [];
    },
    getSeniorSuccessAction: (state, action) => {
      state.SeniorRankdatas = action.payload;
    },
    setSENIORPAGE: (state, action) => {
      state.SeniorPage = action.payload;
    },
    getSeniorRankErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// --------------Master-------------------------
const MasterRankSlice = createSlice({
  name: "MasterRankList",
  initialState: {
    MasterRankdatas: [],
    MastertotalPage: "",
    error: "",
  },
  reducers: {
    getmasterRankAction: (state, action) => {
      state.error = "";
      state.MasterRankdatas = [];
    },
    getMasterSuccessAction: (state, action) => {
      state.MasterRankdatas = action.payload;
    },
    setTotalPageMASTER: (state, action) => {
      state.MastertotalPage = action.payload;
    },
    getMasterRankErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// --------------Pro-Coach-------------------------
const ProCoachSlice = createSlice({
  name: "ProCoachlist",
  initialState: {
    Procoachdata: [],
    paginRankSe: 0,
    error: "",
  },
  reducers: {
    getProcoachRankAction: (state, action) => {
      state.error = "";
      state.Procoachdata = [];
    },
    getProcoachSuccessAction: (state, action) => {
      state.Procoachdata = action.payload;
    },
    setPaginPRO: (state, action) => {
      state.paginRankSe = action.payload;
    },

    getProcoachRankErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// ------IndiaSquashTV------------------------
const indiaSquashtvSlice = createSlice({
  name: "indiaSquahLIST",
  initialState: {
    SquashVideo: [],
    error: "",
  },
  reducers: {
    getSquashAction: (state, action) => {
      state.SquashVideo = [];
      state.error = "";
    },
    getSquashSuccessAction: (state, action) => {
      state.SquashVideo = action.payload;
    },
    getSquashErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const electionspdfSlice = createSlice({
  name: "ElectionPdfList",
  initialState: {
    ElectionPdf: [],
    error: "",
  },
  reducers: {
    getElectionsPdfAction: (state, action) => {
      state.ElectionPdf = [];
      state.error = "";
    },
    getElectionsPdfSuccessAction: (state, action) => {
      state.ElectionPdf = action.payload;
    },
    getElectionsPdfErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const agmpdfSlice = createSlice({
  name: "AgmPdfList",
  initialState: {
    AgmPdf: [],
    error: "",
  },
  reducers: {
    getAgmPdfAction: (state, action) => {
      state.AgmPdf = [];
      state.error = "";
    },
    getAgmPdfSuccessAction: (state, action) => {
      state.AgmPdf = action.payload;
    },
    getAgmPdfErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const rospdfSlice = createSlice({
  name: "RosPdfList",
  initialState: {
    RosPdf: [],
    error: "",
  },
  reducers: {
    getRosPdfAction: (state, action) => {
      state.RosPdf = [];
      state.error = "";
    },
    getRosPdfSuccessAction: (state, action) => {
      state.RosPdf = action.payload;
    },
    getRosPdfErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const balsheetpdfSlice = createSlice({
  name: "BalSheetPdfList",
  initialState: {
    BalSheetPdf: [],
    error: "",
  },
  reducers: {
    getBalSheetPdfAction: (state, action) => {
      state.BalSheetPdf = [];
      state.error = "";
    },
    getBalSheetPdfSuccessAction: (state, action) => {
      state.BalSheetPdf = action.payload;
    },
    getBalSheetPdfErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

//-------------Search--------------
const SearchSlice = createSlice({
  name: "SearchDATA",
  initialState: {
    SearchList: { searchval: "" },
  },
  reducers: {
    getSearchAction: (state, action) => {
      state.SearchList = { searchval: "" };
    },
    // getSearchSuccess:(state , action)=>{
    //   state.SearchList = action.payload;
    // }
  },
});

// --------------SELECTION-PARTS----------------
const selectionPDFSlice = createSlice({
  name: "SelectionPDF",
  initialState: {
    SelectionDATA: [],
    error: "",
  },
  reducers: {
    getSelectionAction: (state, action) => {
      state.SelectionDATA = [];
      state.error = "";
    },
    getSelectionActionSuccess: (state, action) => {
      state.SelectionDATA = action.payload;
    },
    getSelectionErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

// --------------------NAVBAR2--------------------
const Navbar2Slice = createSlice({
  name: "NAVBARSTATES",
  initialState: {
    Navbarss: "",
  },
  reducers: {
    getNavbarsActions: (state, action) => {
      state.Navbarss = action.payload;
      console.log(action.payload, "check this");
    },
    // getNavbarsSuccess:(state , action)=>{
    //   state.Navbarss = action.payload ;
    // }
  },
});

///-----------------SUB-TITLES---NAVBARS------------
const SubNavsSlice = createSlice({
  name: "subtitles",
  initialState: {
    Navs_Sub: "",
  },
  reducers: {
    getFederationActins: (state, action) => {
      state.Navs_Sub = action.payload;
      console.log(action.payload, "in the subtitles");
    },
  },
});

// Social Media
const SocialMediaSlice = createSlice({
  name: "SocialMediaList",
  initialState: {
    SocialMediaData: [],
    error: "",
  },
  reducers: {
    getSocialMediaAction: (state, action) => {
      state.error = "";
      state.SocialMediaData = [];
    },
    getSocialMediaSuccessAction: (state, action) => {
      state.SocialMediaData = action.payload;
    },
    getSocialMediaErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

const Stateid = createSlice({
  name: "IdData",
  initialState: {
    stateid: "2",
    updatetourtype: "",
    tournamentid: "",
    newsid: "",
    type: "",
    latestnewsid: "",
    pagelength: [1,2,3,4,5],

  },
  reducers: {
    stateid: (state, action) => {
      state.stateid = action.payload;
      // console.log(action, "111")
    },
    updatetourtype: (state, action) => {
      state.updatetourtype = action.payload;
      // console.log(state.updatetourtype, "toty");
    },
    tournamentid: (state, action) => {
      state.tournamentid = action.payload;
      // console.log(action, "tiii");
    },
    newsid: (state, action) => {
      state.newsid = action.payload;
      // console.log(action, "niii");
    },
    type: (state, action) => {
      state.type = action.payload;
      // console.log(action, "tyty");
    },
    latestnewsid: (state, action) => {
      state.latestnewsid = action.payload;
      // console.log(action, "lnid");
    },

    pagelength: (state, action) => {
      state.pagelength = action.payload;
      // console.log(action, "ppplll")
    },
  },
});

// -------------NAVBARSS20---------------------
export const { getNavbarsActions } = Navbar2Slice.actions;

export const { getFederationActins } = SubNavsSlice.actions;
//------------Selection---padfss--------
export const { getSelectionAction, getSelectionActionSuccess, getSelectionErrorAction } =
  selectionPDFSlice.actions;

// ----Search----------------
export const { getSearchAction, getSearchSuccess } = SearchSlice.actions;

export const { getSquashAction, getSquashSuccessAction, getSquashErrorAction } =
  indiaSquashtvSlice.actions;

  export const { getElectionsPdfAction, getElectionsPdfSuccessAction, getElectionsPdfErrorAction } =
  electionspdfSlice.actions;

  export const { getAgmPdfAction, getAgmPdfSuccessAction, getAgmPdfErrorAction } =
  agmpdfSlice.actions;

  export const { getRosPdfAction, getRosPdfSuccessAction, getRosPdfErrorAction } =
  rospdfSlice.actions;

  export const { getBalSheetPdfAction, getBalSheetPdfSuccessAction, getBalSheetPdfErrorAction } =
  balsheetpdfSlice.actions;

export const { getBannerAction, getBannerSuccessAction, getBannerErrorAction } =
  BannerSlice.actions;

export const {
  getUpcomingMatchAction,
  getUpcomingMatchSuccessAction,
  getUpcomingSearchResponsesucessAction,

  getUpcomingMatchErrorAction,
} = UpcomingMatchSlice.actions;

export const {
  getTopRankAction,
  getTopRankSuccessAction,
  getTopRankSearchResponsesucessAction,
  getSearchResponsesucessAction,

  getTopRankErrorAction,
} = TopRankSlice.actions;

export const {
  getResultsAction,
  getResultsSuccessAction,
  getResultsErrorAction,
} = LatestResultsSlice.actions;

export const {
  getNewsAction,
  getNewsSuccessAction,
  getLatestNewsSearchAction,

  getNewsErrorAction,
} = LatestNewsSlice.actions;

export const { getVideosAction, getVideosSuccessAction, getVideosErrorAction } =
  LatestVideosSlice.actions;

// export const {
//   getMasterProTopRankAction,
//   getMasterProTopRankSuccessAction,
//   getSearchResponsesucessAction,
//   getMasterProTopRankErrorAction,
// } = MasterProTopRankSlice.actions;

export const {
  getFilterStateAction,
  getFilterStateSuccessAction,
  getFilterStateErrorAction,
} = FilterStateSlice.actions;

export const {
  getAffiliationAction,
  getAffiliationSuccessAction,
  getAffiliationErrorAction,
  getAffiliationSearchAction,
} = AffiliationSlice.actions;

export const {
  getRegisterPlayerAction,
  getRegisterPlayerSuccessAction,
  getRegisterPlayerErrorAction,
} = RegisterPlayerSlice.actions;

export const { getEventsAction, getEventsSuccessAction, getEventsErrorAction } =
  EventsSlice.actions;

export const {
  getCalenderYearAction,
  getCalenderYearSuccessAction,
  getCalenderYearErrorAction,
} = CalenderYearSlice.actions;

export const {
  getCalenderFilterAction,
  getCalenderFilterSuccessAction,
  getCalenderFilterSearchAction,

  getCalenderFilterErrorAction,
} = CalenderFilterSlice.actions;

export const {
  getCalenderTourDetailsAction,
  getCalenderTourDetailsSuccessAction,
  getCalenderTourDetailsErrorAction,
  getCalenderTourDetailsEmptyAction,

} = CalenderTourDetailsSlice.actions;

// export const {
//   getCalTourEntriesAction,
//   getCalTourEntriesSuccessAction,
//   getCalTourEntriesErrorAction,
//   setTotalPageENTRIES,

// } = CalTourEntriesSlice.actions;

export const {
  getCalTourEntriesPAGEAction,
  getCalTourEntriesPAGESuccessAction,
  getCalTourEntriesPAGEErrorAction,
} = CalTourEntriesPAGESlice.actions;

export const {
  getNewsTabAction,
  getNewsTabSuccessAction,
  getNewsTabErrorAction,
  getNewsSearchAction,
  getNewsTabEmptyAction,
  
} = NewsTabSlice.actions;

export const {
  getNewsTabPutsAction,
  getNewsTabPutsSuccessAction,
  getNewsTabPutsErrorAction,
} = NewsTabPutsSlice.actions;

export const {
  postNewsTabsAction,
  postNewsTabsSuccessAction,
  postNewsTabsErrorAction,
  postNewsTabsSearchAction,
} = PostNewsTabsSlice.actions;

export const {
  getSquashTimeAction,
  getSquashTimeSuccessAction,
  getSquashTimeErrorAction,
  getSquashTimeSearchAction,
} = SquashTimeSlice.actions;

//  ------------------Master Export --------------
export const {
  getProcoachRankAction,
  getProcoachSuccessAction,
  getProcoachRankErrorAction,
  setPaginPRO,
} = ProCoachSlice.actions;

export const {
  getmasterRankAction,
  getMasterSuccessAction,
  getMasterRankErrorAction,
  setTotalPageMASTER,
} = MasterRankSlice.actions;

export const {
  getSeniorRankAction,
  getSeniorRankErrorAction,
  getSeniorSuccessAction,
  setSENIORPAGE,
} = SeniorRankSlice.actions;

export const { getRankingJuniorAction, getRankingsJuniorSuccess, setPagin } =
  RankingSlice.actions;

export const {
  getALLPlayersAction,
  getAEPlayersAction,
  getWEPlayersAction,
  getALLPlayersSuccess,
  getAEPlayersSuccess,
  getWEPlayersSuccess,
  getALLPlayersError,
  getAEPlayersError,
  getWEPlayersError,
  getALLPlayersSearch,
  getAEPlayersSearch,
  getWEPlayersSearch,
} = TeamIndiaSlice.actions;

export const {
  getSocialMediaAction,
  getSocialMediaSuccessAction,
  getSocialMediaErrorAction,
} = SocialMediaSlice.actions;

export const {
  stateid,
  updatetourtype,
  tournamentid,
  newsid,
  type,
  latestnewsid,
  pagelength,
} = Stateid.actions;

export default {
  IdReducer: Stateid.reducer,
  BannerReducer: BannerSlice.reducer,
  UpcomingMatchReducer: UpcomingMatchSlice.reducer,
  TopRankReducer: TopRankSlice.reducer,
  LatestResult: LatestResultsSlice.reducer,
  LatestNews: LatestNewsSlice.reducer,
  LatestVideos: LatestVideosSlice.reducer,
  // MasterProTopRankReducer: MasterProTopRankSlice.reducer,
  FilterStateReducer: FilterStateSlice.reducer,
  AffiliationReducer: AffiliationSlice.reducer,
  RegisterPlayerReducer: RegisterPlayerSlice.reducer,
  EventsSliceReducer: EventsSlice.reducer,
  CalenderYearSliceReducer: CalenderYearSlice.reducer,
  CalenderFilterSliceReducer: CalenderFilterSlice.reducer,
  CalenderTourDetailsSliceReducer: CalenderTourDetailsSlice.reducer,
  // CalTourEntriesSliceReducer: CalTourEntriesSlice.reducer,
  CalTourEntriesPAGESliceReducer: CalTourEntriesPAGESlice.reducer,

  NewsTabSliceReducer: NewsTabSlice.reducer,
  NewsTabPutsSliceReducer: NewsTabPutsSlice.reducer,
  PostNewsTabsSliceReducer: PostNewsTabsSlice.reducer,
  SquashTimeSliceReducer: SquashTimeSlice.reducer,

  // ------------TEAM-INDIA----REducers-------------------
  TeamIndiaReducer: TeamIndiaSlice.reducer,

  //-----------------Yearss----------------

  // -----------------Ranking-------------
  RankingReducer: RankingSlice.reducer,
  RankingSeniorReducer: SeniorRankSlice.reducer,
  RankingMasterReducer: MasterRankSlice.reducer,
  RankingProCoach: ProCoachSlice.reducer,

  indiaSquashVD: indiaSquashtvSlice.reducer,

  ElectionsPdf: electionspdfSlice.reducer,

  AgmsPdf: agmpdfSlice.reducer,
  RossPdf: rospdfSlice.reducer,
  BalSheetsPdf: balsheetpdfSlice.reducer,
  
  Search_DATA: SearchSlice.reducer,

  SelecctionPDFS: selectionPDFSlice.reducer,

  NAVBAR_STATES: Navbar2Slice.reducer,
  Inner_SubTitle: SubNavsSlice.reducer,
  SocialMediaReducer: SocialMediaSlice.reducer,
};
