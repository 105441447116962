import React, { useEffect, useState } from "react";
import "../../../Stylesheets/MainComponent/NavBar2.scss";
// import srfiloo from "../../../assets/Images/SRFI-Web-Logo.png";
import srfiloo from "../../../assets/Images/SRFI-WEB-LOGO.png";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { getNavbarsActions } from "../../../Redux/Slice";
import { getFederationActins } from "../../../Redux/Slice";

import axios from "axios";

const Navbar2 = ({
  Activepart,
  onMenuclickActivepart,
  ActiveOffical,
  onMenuActiver,
}) => {

  const dispatch = useDispatch();
  const NavsCheck = useSelector((state) => state.Test_NAVS);
  console.log(NavsCheck, "NNNNN");

  const [makeActive, setACtive] = useState();

  useEffect(() => {
    setTimeout(() => {
      const value = window.location.href.split("/");
      console.log(
        value[value.length - 1],
        value[value.length - 2],
        value.length,
        value,
        "likilkiklki"
      );
      if (value.length > 4) {
        dispatch(getNavbarsActions(value[value.length - 2]));
        dispatch(getFederationActins(value[value.length - 1]));
      } else {
        dispatch(getNavbarsActions("Home"));
        dispatch(getFederationActins(""));
      }
      console.log();
    }, 300);
  }, []);

  useEffect(() => {
    setACtive(NavsCheck.Navbarss);
  }, [NavsCheck]);
  //-----------------------Home-----------------
  const Home = (e) => {
    dispatch(getNavbarsActions(e));
    console.log(e, "cs");
    console.log(NavsCheck, "ho1");
  };
  // -----------federations----------------------
  const federation = (e) => {
    dispatch(getNavbarsActions(e));
    console.log(NavsCheck, "fe1");
  };
  // ----------------players-------------------------
  const players = (e) => {
    dispatch(getNavbarsActions(e));
    console.log(NavsCheck, "pl1");
  };
  // --------------------events------------------------
  const Events = (e) => {
    dispatch(getNavbarsActions(e));
    console.log(NavsCheck, "ev1");
  };
  // -------------Official--------------------------
  const Official = (e) => {
    dispatch(getNavbarsActions(e));
    console.log(makeActive, "of1");
  };
  // --------------------INDIASQUASHTV------------------
  const IndiaSqushTV = (e) => {
    dispatch(getNavbarsActions(e));
    console.log(makeActive, "in1");
  };

  // ------------------Sub----Filess----------------
  const InnerSubtile = useSelector((state) => state.Inner_Federations);
  console.log(InnerSubtile, "innerss");

  const [innerSubs, setInnerSubs] = useState();

  useEffect(() => {
    setInnerSubs(InnerSubtile.Navs_Sub);
  }, [InnerSubtile.Navs_Sub]);

  useEffect(() => {
    if (innerSubs !== undefined && innerSubs === "IndiaSquash") {
      console.log(innerSubs, "done ");
    }
    console.log(innerSubs, "sdf");
  }, [innerSubs]);

  // -----------------------federation---sub -files--------------------
  const innerSub = (e) => {
    dispatch(getFederationActins(e));
    // setInnerSubs(e); // Set the active submenu here
  };

  // ------------------PlayersActive----------------------------
  // const hanldeMeniClick = (tabI) => {
  //   onMenuclickActivepart(tabI);
  // };
  // const getNavItems = (tabname) => {
  //   return Activepart === tabname ? "active" : "";
  // };
  // --------------------Offical_ACtive----------------
  // const HandleOfficalActive = (tabIds) => {
  //   onMenuActiver(tabIds);
  // };
  // const getNavItemsOff = (tabNAMES) => {
  //   return ActiveOffical === tabNAMES ? "active" : "";
  // };

  // function ConstitutionClick(props) {
  //   window.open(
  //     "https://demowhats.s3.ap-south-1.amazonaws.com/media/constitution/SRFI_MOA_2022_For%20Print.pdf"
  //   );
  // }

  function ConstitutionClick() {
    axios({
      method: "get",
      url: "https://api.indiasquash.com/pdfuploadss/Constution/",
    })
      .then((response) => {
        const pdfData = response.data.data[0];
        console.log(response.data.data, "123");
        const pdfUrl = `https://demowhats.s3.ap-south-1.amazonaws.com/${pdfData.filefield}`;
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }



  function ACTCClick() {
    axios({
      method: "get",
      url: "https://api.indiasquash.com/pdfuploadss/ACTC/",
    })
      .then((response) => {
        const pdfData = response.data.data[0];
        console.log(response.data.data, "123");
        const pdfUrl = `https://demowhats.s3.ap-south-1.amazonaws.com/${pdfData.filefield}`;
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }

  function CommittiesClick() {
    axios({
      method: "get",
      url: "https://api.indiasquash.com/pdfuploadss/Committees/",
    })
      .then((response) => {
        const pdfData = response.data.data[0];
        console.log(response.data.data, "123333");
        const pdfUrl = `https://demowhats.s3.ap-south-1.amazonaws.com/${pdfData.filefield}`;
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }

  return (
    <div className="NavBar_Main">
      <div className="">
        <header className="header">
          <a
            href="/"
            className={`nav-link logo ${
              makeActive != undefined && makeActive === "Home" ? "active" : ""
            }`}
            onClick={() => {
              Home("Home");
              innerSub();
            }}
          >
            <img src={srfiloo} className="srfi-logo"></img>
          </a>

          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon-icon">
              <MenuIcon />
            </span>
          </label>

          <ul className="menu">
            <li className="dropdown">
              <a
                href="/"
                className={`nav-link ${
                  makeActive != undefined && makeActive === "Home"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  Home("Home");
                  innerSub();
                }}
              >
                HOME
              </a>
            </li>

            <li className="dropdown">
              <span
                // to="/federation/indiasquash"
                className={`nav-link ${
                  makeActive != undefined && makeActive === "federation"
                    ? "active"
                    : ""
                }`}
                onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
              >
                FEDERATION
              </span>
              {/* <div className="dropdown-caret"></div> */}
              <div className="dropdown-caret2"></div>

              <div className="dropdown-content">
                <ul className="dropdown-inner">
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "indiasquash"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/indiasquash"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("indiasquash");
                      }}
                    >
                      India Squash
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "affiliation"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/affiliation"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("affiliation");
                      }}
                    >
                      Affiliation
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a className="dropdown-option" onClick={ConstitutionClick}>
                      Constitution
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "stateunits"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/stateunits"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("stateunits");
                      }}
                    >
                      State Units
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "elections"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/elections"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("elections");
                      }}
                    >
                      Elections
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "agm"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/agm"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("agm");
                      }}
                    >
                      AGM
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "officebearers"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/officebearers"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("officebearers");
                      }}
                    >
                      Office Bearers
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a className="dropdown-option" onClick={CommittiesClick}>
                      Committees
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "exemption"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/exemption"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("exemption");
                      }}
                    >
                      80G Exemption
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "contactus"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/contactus"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("contactus");
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined &&
                        innerSubs === "registeresociety"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/registeresociety"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("registeresociety");
                      }}
                    >
                      Registrar of Societies
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a className="dropdown-option" onClick={ACTCClick}>
                      ACTC
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "balancesheet"
                          ? "active"
                          : ""
                      }`}
                      href="/federation/balancesheet"
                      onClick={(e) => {
                        federation("federation");
                        innerSub("balancesheet");
                      }}
                    >
                      Balance Sheet
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="dropdown">
              <span
                // to="/events/calender"
                className={`nav-link ${
                  makeActive != undefined && makeActive === "events"
                    ? "active"
                    : ""
                }`}
                onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
              >
                EVENTS
              </span>
              {/* <div className="dropdown-caret"></div> */}
              <div className="dropdown-caret2"></div>
              <div className="dropdown-content">
                <ul className="dropdown-inner">
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "calender"
                          ? "active"
                          : ""
                      }`}
                      href="/events/calender"
                      onClick={(e) => {
                        Events("events");
                        innerSub("calender");
                      }}
                    >
                      Calendar
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "srfiguidelines"
                          ? "active"
                          : ""
                      }`}
                      href="/events/srfiguidelines"
                      onClick={(e) => {
                        Events("events");
                        innerSub("srfiguidelines");
                      }}
                    >
                      SRFI Guidelines
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "squashrule"
                          ? "active"
                          : ""
                      }`}
                      href="/events/squashrule"
                      onClick={(e) => {
                        Events("events");
                        innerSub("squashrule");
                      }}
                    >
                      Squash Rules
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "conductevent"
                          ? "active"
                          : ""
                      }`}
                      href="/events/conductevent"
                      onClick={(e) => {
                        Events("events");
                        innerSub("conductevent");
                      }}
                    >
                      Conduct an Event
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "majorgames"
                          ? "active"
                          : ""
                      }`}
                      href="/events/majorgames"
                      onClick={(e) => {
                        Events("events");
                        innerSub("majorgames");
                      }}
                    >
                      Major Games
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "news"
                          ? "active"
                          : ""
                      }`}
                      href="/events/news"
                      onClick={(e) => {
                        Events("events");
                        innerSub("news");
                      }}
                    >
                      News
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "squashtime"
                          ? "active"
                          : ""
                      }`}
                      href="/events/squashtime"
                      onClick={(e) => {
                        Events("events");
                        innerSub("squashtime");
                      }}
                    >
                      Squash Time
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "importantinfo"
                          ? "active"
                          : ""
                      }`}
                      href="/events/importantinfo"
                      onClick={(e) => {
                        Events("events");
                        innerSub("importantinfo");
                      }}
                    >
                      Important Info
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="dropdown">
              <span
                // to="/players/playSquash"
                className={`nav-link ${
                  makeActive != undefined && makeActive === "players"
                    ? "active"
                    : ""
                }`}
                onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
              >
                PLAYERS
              </span>

              {/* <div className="dropdown-caret"></div> */}
              <div className="dropdown-caret2"></div>
              <div className="dropdown-content">
                <ul className="dropdown-inner">
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "playsquash"
                          ? "active"
                          : ""
                      }`}
                      href="/players/playsquash"
                      onClick={(e) => {
                        players("players");
                        innerSub("playsquash");
                      }}
                    >
                      Play Squash
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "ispin"
                          ? "active"
                          : ""
                      }`}
                      href="/players/ispin"
                      onClick={(e) => {
                        players("players");
                        innerSub("ispin");
                      }}
                    >
                      I-SPIN
                    </a>
                  </li>
                  {/* <li className="dropdown-list">
                    <a
                      href="https://demowhats.s3.ap-south-1.amazonaws.com/media/website_media/playersA%20to%20Z/SRFI%20-%20REGISTERED%20PLAYERS.pdf"
                      target="blank"
                    >
                      Players A to Z
                    </a>
                  </li> */}
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "hcl"
                          ? "active"
                          : ""
                      }`}
                      href="/players/hcl"
                      onClick={(e) => {
                        players("players");
                        innerSub("hcl");
                      }}
                    >
                      HCL SPP
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "ranking"
                          ? "active"
                          : ""
                      }`}
                      href="/players/ranking"
                      onClick={(e) => {
                        players("players");
                        innerSub("ranking");
                      }}
                    >
                      Ranking
                    </a>
                  </li>
                  <li className="dropdown-list selectionLI">
                    <a
                      className={`dropdown-option drop-part selectionA ${
                        innerSubs != undefined &&
                        innerSubs === "selectioncriteria"
                          ? "active"
                          : ""
                      }`}
                      href="/players/selectioncriteria"
                      onClick={(e) => {
                        players("players");
                        innerSub("selectioncriteria");
                      }}
                    >
                      Selection Criteria
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "teamindia"
                          ? "active"
                          : ""
                      }`}
                      href="/players/teamindia"
                      onClick={(e) => {
                        players("players");
                        innerSub("teamindia");
                      }}
                    >
                      Team India
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className="dropdown-option"
                      href="https://demowhats.s3.ap-south-1.amazonaws.com/media/Antidoping/2022list_final_en.pdf"
                      target="blank"
                    >
                      Anti Doping
                    </a>
                  </li>
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "agefraud"
                          ? "active"
                          : ""
                      }`}
                      href="/players/agefraud"
                      onClick={(e) => {
                        players("players");
                        innerSub("agefraud");
                      }}
                    >
                      Age Fraud
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="dropdown">
              <span
                // to="/official/Pathway"
                className={`nav-link ${
                  makeActive != undefined && makeActive === "official"
                    ? "active"
                    : ""
                }`}
                onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
              >
                OFFICIALS
              </span>
              {/* <div className="dropdown-caret"></div> */}
              <div className="dropdown-caret2"></div>
              <div className="dropdown-content">
                <ul className="dropdown-inner">
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "pathway"
                          ? "active"
                          : ""
                      }`}
                      href="/official/pathway"
                      onClick={(e) => {
                        Official("official");
                        innerSub("pathway");
                      }}
                    >
                      Path Way
                    </a>
                  </li>

                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "refereepanel"
                          ? "active"
                          : ""
                      }`}
                      href="/official/refereepanel"
                      onClick={(e) => {
                        Official("official");
                        innerSub("refereepanel");
                      }}
                    >
                      Referee Panel
                    </a>
                  </li>

                  {/* <li className={`dropdown-list ${getNavItemsOff("RefereePanel")}`}>
                    <a href="#TAB2REfree"
                      data-toggle="tab"
                      className="  dropdown-option subnav-list"
                      onClick={() => HandleOfficalActive("RefereePanel")}>
                    Referee Panel


                    </a>
                  </li> */}

                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined &&
                        innerSubs === "certifiedcoaches"
                          ? "active"
                          : ""
                      }`}
                      href="/official/certifiedcoaches"
                      onClick={(e) => {
                        Official("official");
                        innerSub("certifiedcoaches");
                      }}
                    >
                      Certified Coaches
                    </a>
                  </li>
                  {/* <li className={`dropdown-list ${getNavItemsOff("CERTIFIEDCOACHE")}`}>
                    <a href="#TAB2Certified"
                      data-toggle="tab"
                      className="  dropdown-option subnav-list"
                      onClick={() => HandleOfficalActive("CERTIFIEDCOACHE")}>
                  
                  Certified Coaches

                    </a>
                  </li> */}

                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined &&
                        innerSubs === "refereeworkshop"
                          ? "active"
                          : ""
                      }`}
                      href="/official/refereeworkshop"
                      onClick={(e) => {
                        Official("official");
                        innerSub("refereeworkshop");
                      }}
                    >
                      Referee Workshop
                    </a>
                  </li>

                  {/* <li className={`dropdown-list ${getNavItemsOff("REFEREEPANEL")}`}>
                    <a href="#TAB2Refereepanel"
                      data-toggle="tab"
                      className="  dropdown-option subnav-list"
                      onClick={() => HandleOfficalActive("REFEREEPANEL")}>
                      Referee Workshop
                

                    </a>
                  </li> */}

                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "coachingcourse"
                          ? "active"
                          : ""
                      }`}
                      href="/official/coachingcourse"
                      onClick={(e) => {
                        Official("official");
                        innerSub("coachingcourse");
                      }}
                    >
                      Coaching Course
                    </a>
                  </li>
                  {/* <li className={`dropdown-list ${getNavItemsOff("COACHINGCOURSE")}`}>
                    <a href="#TAB2CoachingCourse"
                      data-toggle="tab"
                      className="  dropdown-option subnav-list"
                      onClick={() => HandleOfficalActive("COACHINGCOURSE")}>
                  
                  Coaching Course

                    </a>
                  </li> */}

                  <li className="dropdown-list">
                    <a
                      className="dropdown-option pdfCurse"
                      href="https://demowhats.s3.ap-south-1.amazonaws.com/media/Code_of_conduct/SRFI_Code%20_of_Conduct.pdf"
                      target="blank"
                    >
                      Code of Conduct
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="dropdown">
              {/* <span
                // to="/indiasquashtv/videos"

                className={`nav-link ${
                  makeActive != undefined && makeActive === "indiasqushatv"
                    ? "active"
                    : ""
                }`}
                onClick={() => IndiaSqushTV("indiasqushatv")}
              >
                INDIA SQUASH TV
              </span> */}

              <a
                className={`nav-link ${
                  makeActive != undefined && makeActive === "indiasqushatv"
                    ? "active"
                    : ""
                }`}
                href="/indiasqushatv/videos"
                onClick={(e) => {
                  IndiaSqushTV("indiasqushatv");
                  innerSub("videos");
                }}
              >
                INDIA SQUASH TV
              </a>

              {/* <div className="dropdown-caret"></div> */}
              {/* <div className="dropdown-caret2"></div> */}
              {/* <div className="dropdown-content">
                <ul className="dropdown-inner">
                  <li className="dropdown-list">
                    <a
                      className={`dropdown-option drop-part ${
                        innerSubs != undefined && innerSubs === "Videos"
                          ? "active"
                          : ""
                      }`}
                      href="/indiasquashtv/videos"
                      onClick={(e) => {
                        IndiaSqushTV("indiasqushatv");
                        innerSub("Videos");
                      }}
                    >
                      Videos
                    </a>
                  </li>
                </ul>
              </div> */}
            </li>
          </ul>
        </header>
      </div>
    </div>
  );
};

export default Navbar2;
